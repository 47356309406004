import { Level, Source, SplNotification } from '@common/co/core/models/splNotification.model';
import * as uuid from 'uuid';

export function getSplNotification(
  level: Level,
  message: string,
  duration?: number | '',
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  relatedObject?: any,
  source?: Source
): SplNotification {
  const myId = uuid.v4();
  const date = new Date();
  return new SplNotification(
    level,
    message,
    myId,
    date,
    duration,
    relatedObject,
    source
  );
}