export class UserEntity {
  email: string;
  email_verified: boolean;
  name: string;
  preferred_username: string;
  profiles: string;
  sub: string;
  tenants: string;
  isInTakePassed: boolean;
  dateOfBirth?: Date;
  firstName: string;
  lastName: string;
  zip: string;
  currentProfileId: string;
  mobilePhone: string;
  constructor() {
    this.isInTakePassed = false;
  }
}
