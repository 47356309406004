import { Injectable } from '@angular/core';
import * as _ from 'lodash';

import { Subject } from 'rxjs';
import {
  IChangeModel,
  IGroupChangeModel,
} from '@common/shared/models/change-model';

import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ConfirmChangesDialogComponent } from '@common/shared/components/confirm-changes-dialog/confirm-changes-dialog.component';

export interface INotifyChanged {
  componentId: string;
  changes: IChangeModel;
}

export interface INotifyClear {
  groupId?: string;
  controlId?: string;
}

@Injectable({ providedIn: 'root' })
export class ChangeManagementService {
  public changesEvent: Subject<INotifyChanged>;
  public clickClearChanges: Subject<void>;
  public clearChanges$: Subject<INotifyClear>;

  private changes: StringMap<IChangeModel>;
  private componentId: string;
  constructor(private _matDialog: MatDialog) {
    this.changes = {};
    this.changesEvent = new Subject();
    this.clickClearChanges = new Subject();
    this.clearChanges$ = new Subject();
    window.addEventListener('beforeunload', (e) => {
      const canMove: boolean = this.isPristine(this.componentId);
      if (canMove) {
        return;
      }
      console.log('Can Move:' + canMove);
      e.preventDefault();
      e.returnValue = true; // Gecko, Trident, Chrome 34+
      return true; // Gecko, WebKit, Chrome <34
    });
  }

  public setCurrentComponentId(componentId: string): void {
    this.componentId = componentId;
  }
  public clearCurrentComponentId(): void {
    this.componentId = undefined;
  }

  private getGroupId(group?: string): string {
    if (group) {
      return group;
    }
    return 'default';
  }
  public changeNotify(group?: string, controlId?: string): void {
    if (!this.changes[this.componentId]) {
      this.changes[this.componentId] = { id: this.componentId, groups: {} };
    }
    const groupId = this.getGroupId(group);
    if (!this.changes[this.componentId].groups[groupId]) {
      this.changes[this.componentId].groups[groupId] = {
        id: groupId,
        controls: {},
      };
    }
    if (controlId) {
      this.changes[this.componentId].groups[groupId].controls[controlId] = true;
    }
    this.onChanges(this.componentId);
  }

  public clearChanges(group?: string, controlId?: string): void {
    this.clearChanges$.next({ groupId: group, controlId: controlId });
    if (!group && !controlId) {
      this.changes[this.componentId] = undefined;
      this.onChanges(this.componentId);
      return;
    }
    if (!this.changes[this.componentId]) {
      this.onChanges(this.componentId);
      return;
    }
    if (!this.changes[this.componentId].groups) {
      this.changes[this.componentId] = undefined;
      this.onChanges(this.componentId);
      return;
    }
    const groupId = this.getGroupId(group);
    if (!controlId) {
      this.changes[this.componentId].groups[groupId] = undefined;
      this.clearGroupsIfEmpty();
      this.onChanges(this.componentId);
      return;
    }
    if (!this.changes[this.componentId].groups[groupId]) {
      this.onChanges(this.componentId);
      return;
    }
    this.changes[this.componentId].groups[groupId].controls[controlId] =
      undefined;
    const k: string = _.findKey(
      this.changes[this.componentId].groups[groupId].controls,
      (control: boolean) => control !== undefined,
    );
    if (!k) {
      this.changes[this.componentId].groups[groupId] = undefined;
    }
    this.clearGroupsIfEmpty();
    this.onChanges(this.componentId);
    return;
  }

  public async canMoveForward(
    componentId?: string,
    group?: string,
    controlsNames?: string[],
  ): Promise<boolean> {
    const canMove: boolean = this.isPristine(
      componentId ? componentId : this.componentId,
      group,
      controlsNames,
    );
    if (canMove) {
      return Promise.resolve(true);
    }
    const result = await this.showConfirmChangesDialog();
    if (result) {
      this.changes = {};
    }
    return result;
  }

  public objectToString(obj: unknown): string {
    return JSON.stringify(obj, (k, v) => {
      return v;
    });
  }

  public isPristine(
    componentId?: string,
    group?: string,
    controlsNames?: string[],
  ): boolean {
    if (componentId) {
      if (!this.changes[componentId]) {
        return true;
      }
      if (!group) {
        return false;
      }
      const groupId = this.getGroupId(group);
      if (!this.changes[this.componentId].groups[groupId]) {
        return true;
      } else if (controlsNames?.length > 0) {
        let isPristine: boolean = true;
        Object.keys(
          this.changes[this.componentId].groups[groupId].controls,
        ).forEach((key) => {
          const foundControlName = controlsNames.find(
            (controlName) => controlName === key,
          );
          if (
            foundControlName &&
            this.changes[this.componentId].groups[groupId].controls[
              foundControlName
            ]
          ) {
            isPristine = false;
          }
        });
        return isPristine;
      }
      return false;
    } else {
      const firstDirtyKey: string = _.findKey(
        this.changes,
        (p: IChangeModel) => {
          return p !== undefined;
        },
      );
      return !firstDirtyKey;
    }
  }

  private onChanges(componentId: string): void {
    this.changesEvent.next({
      componentId: componentId,
      changes: this.changes[this.componentId],
    });
  }

  private async showConfirmChangesDialog(): Promise<boolean> {
    return new Promise((resolve) => {
      const confirmDialogRef = this._matDialog.open(
        ConfirmChangesDialogComponent,
        {
          disableClose: true,
          data: {
            message: `Do you want to leave this page? <br><br> The changes you made will not be saved.`,
          },
        },
      );
      confirmDialogRef.afterClosed().subscribe((result) => {
        resolve(result);
      });
    });
  }

  private clearGroupsIfEmpty(): void {
    const k: string = _.findKey(
      this.changes[this.componentId].groups,
      (gr: IGroupChangeModel) => gr !== undefined,
    );
    if (!k) {
      this.changes[this.componentId] = undefined;
    }
  }
}
