<div id="error-401" fxLayout="column" fxLayoutAlign="center center">
  <div class="content" fxLayout="column" fxLayoutAlign="center center">
    <div class="error-code">401</div>

    <div class="message">
      Sorry but you are not authorized to look into this page
    </div>

    <a class="back-link mt-16" (click)="action()"
      >{{ error401Config.buttonTitle }}</a
    >
  </div>
</div>
