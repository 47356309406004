import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';

import { TenantService } from './tenant.service';
import { PermissionsGuardBase } from '@common/shared/services/permission.guard';
import { PermissionsService } from './permissions.service';
import { navigationConfig } from 'app/navigation/navigation';

@Injectable({
  providedIn: 'root',
})
export class PermissionsGuard extends PermissionsGuardBase {
  constructor(
    private tenantService: TenantService,
    private permissionsService: PermissionsService,
    router: Router,
  ) {
    super(router);
  }

  protected async handlePermission(
    _next: ActivatedRouteSnapshot,
  ): Promise<boolean> {
    if ((await this.tenantService.checkAccessToTenants()) === false) return;
    const tokens = _next.data.tokens;
    const contextLevel = await this.tenantService.getCurrentContextLevel();
    const permContexts =
      this.tenantService.getPermissionContextsFromContextLevel(contextLevel);
    const res = await this.permissionsService.checkUserPermissions(
      tokens,
      contextLevel?.tenantInd,
      undefined,
      permContexts,
    );
    if (!res) {
      await this.router.navigateByUrl(navigationConfig.notAuthorized.url, {
        replaceUrl: true,
      });
    }
    return res;
  }
}
