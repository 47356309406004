import { NgModule } from '@angular/core';
import { TrimOnBlurDirective } from '@common/shared/directives/trim-on-blur.directive';
import { FilterValuesPipe } from '@common/shared/directives/filter-values.pipe';
import { MinMaxDirective } from '@common/shared/directives/min-max-number-value.directive';
import { DisallowSpacesDirective } from '@common/shared/directives/disallow-spaces.directive';
import { NumbersOnlyDirective } from '@common/shared/directives/numbers-only.directive';
import { Ipv4Directive } from '@common/shared/directives/ipv4.directive';
import { DisplayServiceIsUnavailableMessageButtonDirective } from '@common/shared/directives/display-service-is-unavailable-message-button.directive';
import { TimerMaskValidationDirective } from '@common/shared/directives/timer-mask-validation.directive';

@NgModule({
  declarations: [
    TrimOnBlurDirective,
    FilterValuesPipe,
    MinMaxDirective,
    DisallowSpacesDirective,
    NumbersOnlyDirective,
    Ipv4Directive,
    DisplayServiceIsUnavailableMessageButtonDirective,
    TimerMaskValidationDirective,
  ],
  exports: [
    TrimOnBlurDirective,
    FilterValuesPipe,
    MinMaxDirective,
    DisallowSpacesDirective,
    NumbersOnlyDirective,
    Ipv4Directive,
    DisplayServiceIsUnavailableMessageButtonDirective,
    TimerMaskValidationDirective,
  ],
})
export class DirectivesModule {}
