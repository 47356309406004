import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ConfirmChangesDialogComponent } from './confirm-changes-dialog.component';
import { MaterialModule } from '@common/co/core/material.module';
import { DirectivesModule } from '@common/shared/directives/directives.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, MaterialModule, DirectivesModule, TranslateModule],
  declarations: [ConfirmChangesDialogComponent],
  exports: [ConfirmChangesDialogComponent],
})
export class ConfirmChangesModule {}
