import { Directive, ElementRef, OnDestroy, OnInit, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/internal/operators';

@Directive({
  selector: '[trimOnBlur]'
})
export class TrimOnBlurDirective implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<void>;

  constructor(private elementRef: ElementRef,
              @Self() private ngControl: NgControl) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    fromEvent(this.elementRef.nativeElement, 'blur').pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(() => {
      if (this.ngControl.value === null || this.ngControl.value === undefined) return;
      const currentValue: string = this.ngControl.value.toString();
      const whitespace: string = ' ';
      if (currentValue.startsWith(whitespace) || currentValue.endsWith(whitespace)) {
        this.ngControl.control.patchValue(currentValue.trim());
      }
    });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}