<div class="code-entry-wrapper">
  <div class="logo">
    <img src="assets/images/logos/icon.png" alt="logo" />
  </div>
  
  <span *ngIf="lastSendAttemptAt">
    Code sent to your {{ sentTo }} at {{ lastSendAttemptAt | date:'h:mm:ss a' }}
  </span>
  
  <div class="code-wrapper">
    <mat-form-field appearance="outline" [ngClass]="{'disabled': isConfirmDisable || process}">
      <mat-label>Code</mat-label>
      <input matInput [formControl]="codeControl" numbersOnly [minlength]="minCodeLength" [maxlength]="maxCodeLength" 
        (change)="codeChanged.emit(codeControl.value)" (input)="codeValidChanged.emit(codeControl.valid)" />
      <mat-error *ngIf="codeControl.hasError('required')">
        Code is required
      </mat-error>
      <mat-error *ngIf="codeControl.hasError('wrongCode')">
        {{ codeControl.getError('wrongCode') }}
      </mat-error>
    </mat-form-field>
    
    <mat-icon class="keyboard-icon" (click)="displayKeyboard = !displayKeyboard">keyboard</mat-icon>
  </div>
  
  <div class="keyboard-wrapper" [ngClass]="{'disabled': isConfirmDisable || process}">
    <div class="keyboard" *ngIf="displayKeyboard">
      <button mat-fab class="keyboard-item" (click)="enter(1)" aria-label="one">1</button>
      <button mat-fab class="keyboard-item" (click)="enter(2)" aria-label="two">2</button>
      <button mat-fab class="keyboard-item" (click)="enter(3)" aria-label="three">3</button>
      <br>
      <button mat-fab class="keyboard-item" (click)="enter(4)" aria-label="four">4</button>
      <button mat-fab class="keyboard-item" (click)="enter(5)" aria-label="five">5</button>
      <button mat-fab class="keyboard-item" (click)="enter(6)" aria-label="six">6</button>
      <br>
      <button mat-fab class="keyboard-item" (click)="enter(7)" aria-label="seven">7</button>
      <button mat-fab class="keyboard-item" (click)="enter(8)" aria-label="eight">8</button>
      <button mat-fab class="keyboard-item" (click)="enter(9)" aria-label="nine">9</button>
      <br>
      <button mat-icon-button class="keyboard-item" (click)="clear()" aria-label="clear">
        <mat-icon aria-hidden="false">clear</mat-icon>
      </button>
      <button mat-fab class="keyboard-item" (click)="enter(0)" aria-label="zero">0</button>
      <button mat-icon-button class="keyboard-item" (click)="delete()" aria-label="delete">
        <mat-icon aria-hidden="false">backspace</mat-icon>
      </button>
    </div>
  </div>

  <div class="common-errors" *ngIf="codeControl.hasError('commonError')">
    <mat-error>{{ codeControl.getError('commonError') }}</mat-error>
  </div>

  <span class="new-code-button cursor-pointer mt-16" [ngClass]="{'disabled': process}"
    *ngIf="isSendAvailable" (click)="codeRequested.emit()">
    Request new code
  </span>

  <span class="mt-16" *ngIf="!isSendAvailable && countdownTime">
    Next request in <span class="timer">{{ countdownTime.minutes | fullDateFormatPipe }}:{{ countdownTime.seconds | fullDateFormatPipe }}</span>
  </span>

</div>
