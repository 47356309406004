import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fullDateFormatPipe',
  pure: false,
})
export class FullDateFormatPipe implements PipeTransform {
  transform(value: number): string {
    return value > 9 ? value.toString() : '0' + value.toString();
  }
}
