export class EnumDescription {
  public value: number | string;
  public description: string;
}

export class EnumDescriptionSet<
  TEnum extends Record<number, string>,
  TEnumDescription extends Record<string, string>,
> {
  private itemsMap = new Map<number | string, string>();
  private itemsArray: Array<EnumDescription>;

  constructor(enumType: TEnum, enumDescriptionType: TEnumDescription) {
    Object.keys(enumDescriptionType).forEach((enumKey) => {
      const enumValue = enumType[enumKey];
      const enumDescription = enumDescriptionType[enumKey];
      this.itemsMap.set(enumValue, enumDescription);
    });
    this.itemsArray = this.getEnumDescriptions();
  }

  get enumDescriptions(): EnumDescription[] {
    return this.itemsArray;
  }

  public getEnumDescriptions(): EnumDescription[] {
    return Array.from(this.itemsMap.entries()).map((e) => {
      return { value: e[0], description: e[1] };
    });
  }

  public getEnumDescription(enumValue: number | string): EnumDescription {
    return { value: enumValue, description: this.itemsMap.get(enumValue) };
  }

  public getDescription(enumValue: number | string): string {
    return this.itemsMap.get(enumValue);
  }
}
