import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NavigationTree } from './navigation/navigation';
import { PermissionsGuard } from './auth/permissions.guard';
import { PermissionToken } from '@common/shared/models/permission-token';
import { AuthorizeGuard } from 'app/auth/guards/authorize.guard';
import { MasterGuard } from '@common/co/auth/guards/master-guard';
export const appGuards = [AuthorizeGuard, PermissionsGuard];

const appRoutes: Routes = [
  {
    path: 'assessments',
    loadChildren: () =>
      import('./assessments/assessments.module').then(
        (m) => m.AssessmentsModule,
      ),
    canActivate: [MasterGuard],
    data: {
      tokens: [PermissionToken.AdminAccess],
      guards: appGuards,
    },
  },
  {
    path: 'assessment-results',
    loadChildren: () =>
      import('./assessment-results/assessment-results.module').then(
        (m) => m.AssessmentResultsModule,
      ),
    canActivate: [MasterGuard],
    data: {
      tokens: [PermissionToken.AdminAccess],
      guards: appGuards,
    },
  },
  {
    path: 'personal-record-assessments',
    loadChildren: () =>
      import(
        './personal-record-assessments/personal-record-assessments.module'
      ).then((m) => m.PersonalRecordAssessmentsModule),
    canActivate: [MasterGuard],
    data: {
      tokens: [PermissionToken.AdminAccess],
      guards: appGuards,
    },
  },
  {
    path: 'personal-records',
    loadChildren: () =>
      import('./personal-records/personal-records.module').then(
        (m) => m.PersonalRecordsModule,
      ),
    canActivate: [MasterGuard],
    data: {
      tokens: [PermissionToken.AdminAccess],
      guards: appGuards,
    },
  },
  {
    path: 'in-studio-trainings',
    loadChildren: () =>
      import('./in-studio-trainings/in-studio-trainings.module').then(
        (m) => m.InStudioTrainingsModule,
      ),
    canActivate: [MasterGuard],
    data: {
      tokens: [PermissionToken.AdminAccess],
      guards: appGuards,
    },
  },
  {
    path: 'out-of-studio-trainings',
    loadChildren: () =>
      import('./out-of-studio-trainings/out-of-studio-trainings.module').then(
        (m) => m.OutOfStudioTrainingsModule,
      ),
    canActivate: [MasterGuard],
    data: {
      tokens: [PermissionToken.AdminAccess],
      guards: appGuards,
    },
  },
  {
    path: 'training-instructors',
    loadChildren: () =>
      import('./training-instructors/training-instructors.module').then(
        (m) => m.TrainingInstructorsModule,
      ),
    canActivate: [MasterGuard],
    data: {
      tokens: [PermissionToken.AdminAccess],
      guards: appGuards,
    },
  },
  {
    path: 'exercise-programs',
    loadChildren: () =>
      import('./exercise-programs/exercise-programs.module').then(
        (m) => m.ExerciseProgramsModule,
      ),
    canActivate: [MasterGuard],
    data: {
      tokens: [PermissionToken.AdminAccess],
      guards: appGuards,
    },
  },
  {
    path: 'roles',
    loadChildren: () =>
      import('./roles/roles.module').then((m) => m.RolesModule),
    canActivate: [MasterGuard],
    data: {
      tokens: [PermissionToken.EditRoles, PermissionToken.AdminAccess],
      guards: appGuards,
    },
  },
  {
    path: 'users',
    loadChildren: () =>
      import('./users/users.module').then((m) => m.UsersModule),
    canActivate: [MasterGuard],
    data: {
      tokens: [PermissionToken.EditRoles, PermissionToken.AdminAccess],
      guards: appGuards,
    },
  },
  {
    path: 'preferences',
    loadChildren: () =>
      import('./preferences/preferences.module').then(
        (m) => m.PreferencesModule,
      ),
    canActivate: [MasterGuard],
    data: { tokens: [PermissionToken.AdminAccess], guards: appGuards },
  },
  {
    path: 'ip-restrictions',
    loadChildren: () =>
      import('./ip-restriction/ip-restriction.module').then(
        (m) => m.IpRestrictionModule,
      ),
    canActivate: [MasterGuard],
    data: {
      tokens: [PermissionToken.EditRoles, PermissionToken.AdminEditAccess],
      guards: appGuards,
    },
  },
  {
    path: 'training-exercises',
    loadChildren: () =>
      import('./training-exercises/training-exercises.module').then(
        (m) => m.TrainingExercisesModule,
      ),
    canActivate: [MasterGuard],
    data: { tokens: [PermissionToken.AdminAccess], guards: appGuards },
  },
  {
    path: 'medical-providers',
    loadChildren: () =>
      import('./medical-providers/medical-providers.module').then(
        (m) => m.MedicalProvidersModule,
      ),
    canActivate: [MasterGuard],
    data: { tokens: [PermissionToken.AdminAccess], guards: appGuards },
  },
  {
    path: 'dashboards',
    loadChildren: () =>
      import('./dashboards/dashboards.module').then((m) => m.DashboardsModule),
    canActivate: [MasterGuard],
    data: {
      tokens: [PermissionToken.AdminAccess],
      guards: appGuards,
    },
  },
  {
    path: '**',
    redirectTo: NavigationTree.dashboards.url,
    pathMatch: 'full',
  },
];

export const AppRoutes: ModuleWithProviders<RouterModule> =
  RouterModule.forRoot(appRoutes, {
    useHash: false,
  });
