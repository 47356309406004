import {
  AfterViewInit,
  Directive,
  Input,
  Renderer2,
  OnDestroy,
  Host,
} from '@angular/core';
import { PermissionsInfo } from '@common/shared/models/permission-info';
import { PermissionToken } from '@common/shared/models/permission-token';
import { PermissionsService } from '../permissions.service';
import { AppBusService } from 'app/core/services/app-bus.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/internal/operators';
import { ColumnBase } from '@progress/kendo-angular-grid';
@Directive({
  selector: '[hideKendoColumnByPermissions]',
})
export class HideKendoColumnByPermissionsDirective
  implements AfterViewInit, OnDestroy
{
  @Input() hideKendoColumnByPermissions: PermissionToken[] | PermissionsInfo;

  private permissionsInfo: PermissionsInfo;
  private _unsubscribeAll: Subject<any>;
  constructor(
    @Host() private column: ColumnBase,
    private _renderer: Renderer2,
    private appBusService: AppBusService,
    private permissionsService: PermissionsService,
  ) {
    this._unsubscribeAll = new Subject();
    this.appBusService.changePermissions$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this.updatePermissions();
      });
  }

  async ngAfterViewInit(): Promise<void> {
    await this.updatePermissions();
  }
  private async updatePermissions(): Promise<any> {
    if (!this.hideKendoColumnByPermissions) return;
    if (Array.isArray(this.hideKendoColumnByPermissions)) {
      this.permissionsInfo = new PermissionsInfo(
        this.hideKendoColumnByPermissions,
      );
    } else {
      this.permissionsInfo = this.hideKendoColumnByPermissions;
    }
    const access = await this.permissionsService.getPermissionAccess(
      this.permissionsInfo,
    );
    this.column.hidden = !access;
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
