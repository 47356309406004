import { Inject, Injectable } from '@angular/core';
import { interval, Subject } from 'rxjs';
import { ConfirmChangesDialogComponent } from '@common/shared/components/confirm-changes-dialog/confirm-changes-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {
  ITechnicalClientService,
  TECHNICAL_CLIENT,
} from '@common/shared/services/itechnical-client-service';
import { ChangeManagementService } from '@common/shared/services/change-management.service';

@Injectable({ providedIn: 'root' })
export class ServiceIsUnavailableService {
  private readonly defaultMessage: string = 'Service is available again.';
  private readonly lostDataMessage: string = `${this.defaultMessage} Please make sure that the data you entered is saved correctly.`;

  private _repeatCheckServiceAvailableTime: number = 10000;
  private _messageIsDisplayed: boolean = false;
  private _countDownIntervalSubject: Subject<void>;
  private _countDownInterval = undefined;
  private _displayMessage: boolean;
  private _technicalRequestPromise: Promise<string | void>;
  constructor(
    @Inject(TECHNICAL_CLIENT)
    private _technicalClient: ITechnicalClientService,
    private dialog: MatDialog,
    private changeManagementService: ChangeManagementService,
  ) {}

  public showMessage(): void {
    if (this.messageIsDisplayed === true) return;
    this.messageIsDisplayed = true;
    this.startCheckServiceAvailability();
  }

  private startCheckServiceAvailability(): void {
    this.stopCountdown();

    this._countDownInterval = interval(this._repeatCheckServiceAvailableTime);

    this._countDownIntervalSubject = this._countDownInterval.subscribe(() => {
      this.sendTechnicalRequest();
    });
  }

  private sendTechnicalRequest(): void {
    if (this._technicalRequestPromise) return;
    this._technicalRequestPromise = this._technicalClient
      .index()
      .toPromise()
      .then((res) => {
        if (res === 'OK' && this.messageIsDisplayed) {
          this.hideMessage();
        }
      })
      .finally(() => {
        this._technicalRequestPromise = null;
      });
  }

  public hideMessage(isRestored: boolean = true): void {
    this.stopCountdown();
    this.messageIsDisplayed = false;
    this.displayMessage = false;
    if (!isRestored) return;
    const isPristine = this.changeManagementService.isPristine();
    this.dialog.open(ConfirmChangesDialogComponent, {
      disableClose: false,
      width: '350px',
      data: {
        continueButtonText: 'OK',
        message: isPristine ? this.defaultMessage : this.lostDataMessage,
        hideCancelButton: true,
        title: 'Information',
      },
    });
  }

  private stopCountdown(): void {
    this._countDownInterval = undefined;
    if (this._countDownIntervalSubject) {
      this._countDownIntervalSubject.unsubscribe();
    }
  }

  public set displayMessage(value: boolean) {
    this._displayMessage = value;
  }

  public get displayMessage(): boolean {
    return this._displayMessage;
  }

  public set messageIsDisplayed(value: boolean) {
    this._messageIsDisplayed = value;
  }

  public get messageIsDisplayed(): boolean {
    return this._messageIsDisplayed;
  }
}
