import _ from 'lodash';
import { Injectable, Inject, Optional } from '@angular/core';
import {
  HttpHeaders,
  HttpClient,
  HttpResponseBase,
  HttpResponse,
  HttpParams,
} from '@angular/common/http';
import {
  Observable,
  BehaviorSubject,
  ReplaySubject,
  throwError as _observableThrow,
  of as _observableOf,
} from 'rxjs';
import {
  map,
  mergeMap as _observableMergeMap,
  catchError as _observableCatch,
} from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { PlatformService } from '@common/co/core/services/platform.service';
import { AngularRequestor } from './utils/angular-requestor';
import {
  OidcConfigurationClient,
  API_BASE_URL,
  TokenEndpointConstants,
  TwoFactorConstants,
  ContextLevelType,
  OidcAuthErrorConstants,
} from '@common/services/bo-api-client';
import { IAccessToken, OAuth2AuthenticateOptions } from '@common/models/index';
import { UserEntity } from './model/user-entity.model';
import { AppBusService } from 'app/core/services/app-bus.service';
import { StorageService } from '@common/services/storage.service';
import { Router } from '@angular/router';
import { ApplicationPaths, storeKeys } from 'app/core/app.constants';
import { TenantService } from 'app/auth/tenant.service';
import { ChangeManagementService } from '@common/shared/services/change-management.service';
import { NavigationTree } from 'app/navigation/navigation';
import { IAuthService } from '@common/services/iauth-service';
import { SplExceptionModel } from '@common/models/spl-exception.model';
import { ServiceIsUnavailableService } from '@common/shared/services/service-is-unavailable.service';

@Injectable()
export class AuthorizeService implements IAuthService {
  private baseUrl: string;
  private http: HttpClient;

  public user$: Observable<UserEntity>;
  public initialized$: Observable<boolean>;
  private userSubject = new BehaviorSubject<UserEntity>(null);
  private initializedSubject = new ReplaySubject<boolean>();
  private refsreshTokenForcedPromise: Promise<{
    access_token: string;
    refresh_token: string;
  }> = null;

  public idToken: string;
  private accessToken: string;
  private refreshToken: string;
  public expiresIn: number;
  public issuedAt: number;

  private jwtHelperService: JwtHelperService;

  public oidcConfig: OAuth2AuthenticateOptions = undefined;
  constructor(
    private platformService: PlatformService,
    private angularRequestor: AngularRequestor,
    private _oidcConfig: OidcConfigurationClient,
    private _appBusService: AppBusService,
    private _router: Router,
    private _storage: StorageService,
    private _tenantService: TenantService,
    private changeManagementService: ChangeManagementService,
    private _serviceIsUnavailableService: ServiceIsUnavailableService,
    @Inject(HttpClient) http: HttpClient,
    @Optional() @Inject(API_BASE_URL) baseUrl?: string,
  ) {
    this.user$ = this.userSubject.asObservable();
    this.initialized$ = this.initializedSubject.asObservable();
    this.jwtHelperService = new JwtHelperService();
    this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : '';
    this.http = http;
  }

  public async initialize(): Promise<boolean> {
    console.debug('AuthorizeService => setup => started');

    await this.getOidcConfigSafe();

    console.debug('AuthorizeService => setup => finished');
    this.initializedSubject.next(true);
    return Promise.resolve(true);
  }

  private async getOidcConfigSafe(): Promise<OAuth2AuthenticateOptions> {
    await this.repopulateFromStorage();

    if (!this.oidcConfig) {
      this.oidcConfig = await this.platformService.getOidcConfiguration();
      if (!this.oidcConfig) return;
    }

    const optionsSerialized = JSON.stringify(this.oidcConfig);
    await this._storage.set({
      key: storeKeys.STORAGE_OIDC_CONFIG,
      value: optionsSerialized,
    });

    return this.oidcConfig;
  }

  public isAuthenticated(): Observable<boolean> {
    return this.user$.pipe(map((u) => !!u));
  }

  public async isAuthorizationExpired(): Promise<boolean> {
    const token = await this.getAccessToken();
    return !token.accessToken;
  }

  public getTokenAccessWithoutEnsure(): string {
    return this.accessToken;
  }

  public async getAccessToken(): Promise<IAccessToken> {
    const now = Math.round(new Date().getTime() / 1000) + 30; //need offset 30s to avoid expire at last moment 5970
    await this.ensureTokensActual();
    if (this.accessToken) {
      const expirationDate = await this.jwtHelperService.getTokenExpirationDate(
        this.accessToken,
      );

      const expTime = Math.round(expirationDate.getTime() / 1000);
      if (expTime >= now) {
        console.debug('AuthorizeService => isAccessTokenExpired => false');
        return Promise.resolve({
          accessToken: this.accessToken,
          status: 0,
        });
      }
    }

    if (
      !this.refsreshTokenForcedPromise &&
      (!this.refreshToken ||
        this.refreshToken.trim() === 'undefined' ||
        this.refreshToken.trim() === '')
    ) {
      console.debug('AuthorizeService => isAccessTokenExpired => true');
      return Promise.resolve({
        accessToken: null,
        status: 0,
      });
    }
    const token = await this.refreshTokenForced();
    return Promise.resolve(token);
  }

  public async authenticateUser(
    userName: string,
    password: string,
  ): Promise<boolean> {
    console.debug('authenticateUser => started');
    await this._storage.clear();
    const oidcConfig = await this.getOidcConfigSafe();
    if (!oidcConfig) return;
    try {
      const response = await this.requestAccessToken(
        userName,
        password,
        oidcConfig,
      ).toPromise();
      if (response.requires_two_factor_token) {
        await this._router.navigateByUrl(NavigationTree.twoFactorCode.url);
        await this._storage.set({
          key: storeKeys.STORAGE_TWO_FACTOR_AUTH_ID_TOKEN,
          value: response.id_token,
        });
        return false;
      }

      await this.processRequestAccessResponse(response);

      return true;
    } catch (reason) {
      this.setUserSubject(null);

      throw this.parseTokenResponseException(reason);
    }
  }

  public async twoFactorAuthenticateUser(
    code: string,
    idToken: string,
  ): Promise<void> {
    console.debug('two factor authenticateUser => started');
    const oidcConfig = await this.getOidcConfigSafe();
    try {
      const response = await this.requestAccessTokenByCode(
        code,
        idToken,
        oidcConfig,
      ).toPromise();
      await this.processRequestAccessResponse(response);
    } catch (reason) {
      this.setUserSubject(null);

      throw this.parseTokenResponseException(reason);
    }
  }

  private async processRequestAccessResponse(response: any): Promise<void> {
    this.accessToken = response['access_token'];
    this.refreshToken = response['refresh_token'];

    this.validateAccessToken(this.accessToken);

    await this._storage.set({
      key: storeKeys.STORAGE_ACCESS_TOKEN,
      value: this.accessToken,
    });

    await this._storage.set({
      key: storeKeys.STORAGE_REFRESH_TOKEN,
      value: this.refreshToken,
    });

    const userInfo = await this.fetchUserInfo();
    if (userInfo) {
      const response = await this._tenantService.getMappedContextData();
      const tenants = _.filter(
        response.flat,
        (c) => c.type === ContextLevelType.Tenant,
      );
      if (tenants?.length > 0) {
        const selectedTenant = tenants[0];
        const c = selectedTenant.getContextLevel();
        await this._tenantService.setContextLevel(c);
      }
    }
    this.setUserSubject(userInfo);
  }

  public async getTwoFactorIdToken(): Promise<string> {
    const idToken = await this._storage.get({
      key: storeKeys.STORAGE_TWO_FACTOR_AUTH_ID_TOKEN,
    });
    return idToken?.value;
  }

  public async twoFactorAuthTokenWasSent(): Promise<boolean> {
    const value = await this._storage.get({
      key: storeKeys.STORAGE_TWO_FACTOR_TOKEN_WAS_SENT,
    });
    return value?.value ? JSON.parse(value.value) : false;
  }

  public async setTwoFactorAuthTokenAsSent(value: boolean): Promise<void> {
    await this._storage.set({
      key: storeKeys.STORAGE_TWO_FACTOR_TOKEN_WAS_SENT,
      value: JSON.stringify(value),
    });
  }

  private setUserSubject(userEntity: UserEntity): void {
    this.userSubject.next(userEntity);
    if (!userEntity) {
      return;
    }
    this._appBusService.loginData({
      userId: userEntity.sub,
      userName: userEntity.name,
      currentTenantId: undefined,
      tenants: userEntity.tenants,
      profile: userEntity.currentProfileId,
    });
  }

  private async ensureTokensActual(): Promise<void> {
    const accessToken = await this._storage.get({
      key: storeKeys.STORAGE_ACCESS_TOKEN,
    });
    if (accessToken) {
      this.accessToken = accessToken.value;
    } else {
      this.accessToken = undefined;
    }
    const refreshToken = await this._storage.get({
      key: storeKeys.STORAGE_REFRESH_TOKEN,
    });
    if (refreshToken) {
      this.refreshToken = refreshToken.value;
    } else {
      this.refreshToken = undefined;
    }
  }

  private parseTokenResponseException(exception: any): SplExceptionModel {
    if (SplExceptionModel.isSplException(exception)) return exception;

    let responseBody: any;

    try {
      responseBody = exception?.response
        ? JSON.parse(exception.response)
        : { error: 'unknown', error_description: 'unknown' };
    } catch (e) {
      responseBody = {
        error: exception.response,
        error_description: 'parse_response_error',
      };
    }

    return new SplExceptionModel(
      this.getExceptionMessageByErrorDescription(
        responseBody.error_description,
        responseBody.error,
        responseBody.user_locked,
      ),
      exception.status,
      responseBody.error,
      responseBody.error_description,
      exception.headers,
    );
  }

  private getExceptionMessageByErrorDescription(
    errorDescription: string,
    error: string,
    user_locked: boolean,
  ): string {
    const oidcConstants = new OidcAuthErrorConstants();
    if (error === oidcConstants.accessDenied) {
      return errorDescription;
    }

    const twoFactorConstants = new TwoFactorConstants();
    switch (error) {
      case twoFactorConstants.tokenValidationError:
      case twoFactorConstants.manyFailedTokenUsagesError:
        return errorDescription;
    }
    switch (errorDescription) {
      case 'invalid_username_or_password':
        if (user_locked) {
          return 'The user is locked out. Please reach out to the system administrator.';
        }
        return 'Username or password is not valid';
      case 'parse_response_error':
        return error;
      default:
        return 'Authentication exception occured';
    }
  }

  public async refreshTokenForced(): Promise<IAccessToken> {
    console.debug('tokenRefresh => started');
    let promiseInitiator = false;
    const refreshToken = this.refreshToken;
    try {
      if (!this.refsreshTokenForcedPromise) {
        if (!this.refreshToken) return null;
        promiseInitiator = true;
        this.refsreshTokenForcedPromise = this._storage
          .remove({
            key: storeKeys.STORAGE_REFRESH_TOKEN,
          })
          .then(async () => {
            this.refreshToken = undefined;
            const oidcConfig = await this.getOidcConfigSafe();
            return this.refreshAccessToken(
              refreshToken,
              oidcConfig,
            ).toPromise();
          });
      }
      const response = await this.refsreshTokenForcedPromise;
      const accessToken = response['access_token'];
      if (promiseInitiator) {
        this.accessToken = accessToken;
        this.refreshToken = response['refresh_token'];
        this.validateAccessToken(this.accessToken);
        await this._storage.set({
          key: storeKeys.STORAGE_ACCESS_TOKEN,
          value: this.accessToken,
        });

        await this._storage.set({
          key: storeKeys.STORAGE_REFRESH_TOKEN,
          value: this.refreshToken,
        });
      }
      this.refsreshTokenForcedPromise = null;
      return Promise.resolve({
        accessToken: accessToken,
        status: 200,
      });
    } catch (e) {
      this.refsreshTokenForcedPromise = null;
      console.error('OAuth refresh rejected', e);
      if (!this._serviceIsUnavailableService.displayMessage) {
        this.setUserSubject(null);
      } else {
        this.refreshToken = refreshToken;
        await this._storage.set({
          key: storeKeys.STORAGE_REFRESH_TOKEN,
          value: this.refreshToken,
        });
      }
      return Promise.resolve({
        accessToken: null,
        status: e.status,
      });
    }
  }

  public async logout(): Promise<void> {
    const noChanges = await this.changeManagementService.canMoveForward();
    if (!noChanges) {
      return;
    }
    // Logout/revoking tokens currently doesn't seem to be implemented in @openid/appauth
    // See: https://github.com/openid/AppAuth-JS/issues/52
    // So maybe just clear everything for now?
    //WebViewCache.clearCache();

    this.oidcConfig = undefined;
    this.deleteAllCookies();
    await this._storage.clear();

    this.accessToken = null;
    this.idToken = null;
    this.refreshToken = null;
    this.expiresIn = null;
    this.issuedAt = null;

    this.setUserSubject(null);
    this._appBusService.inTakePassed(null);

    this._appBusService.logout();

    await this._router.navigate(ApplicationPaths.LoginPathComponents);

    this._appBusService.checkedPermissions(false);

    await Promise.resolve();
  }

  public async fetchUserInfo(): Promise<UserEntity> {
    console.debug('AuthorizeService => fetchUserInfo');
    const userInfo = await this.angularRequestor.xhr<any>({
      url: this.oidcConfig.resourceUrl,
      dataType: 'application/json',
      headers: new HttpHeaders({
        Authorization: this.getAuthorizationHeader(),
      }),
    });

    const userInfoObject: UserEntity = this.mapUserEntity(JSON.parse(userInfo));

    await this._storage.set({
      key: storeKeys.STORAGE_USER_INFO,
      value: JSON.stringify(userInfoObject),
    });

    return Promise.resolve(userInfoObject);
  }

  public getAuthorizationHeader(): string {
    console.debug('AuthorizeService => getAuthorizationHeader');
    // TODO: maybe validate the access token is set and valid
    return `Bearer ${this.accessToken}`;
  }

  public getBaseUrl(): string {
    return this.baseUrl;
  }

  private mapUserEntity(userObject: any): UserEntity {
    const userEntity: UserEntity = new UserEntity();
    userEntity.name = userObject.name;
    userEntity.sub = userObject.sub;
    userEntity.email = userObject.email;
    userEntity.email_verified = userObject.email_verified;
    userEntity.preferred_username = userObject.preferred_username;
    userEntity.profiles = userObject.profiles;
    userEntity.tenants = userObject.tenants;
    userEntity.isInTakePassed = userObject.isInTakePassed;
    userEntity.dateOfBirth = userObject.dateOfBirth;
    userEntity.firstName = userObject.firstName;
    userEntity.lastName = userObject.lastName;
    userEntity.phone = userObject.mobilePhone;
    userEntity.phoneConfirmed = userObject.phoneNumberConfirmed;
    userEntity.currentProfileId = userObject.currentProfileId;
    userEntity.twoFactorAuthFlow = userObject.twoFactorAuthFlow;
    userEntity.useTwoFactorAuth = userObject.useTwoFactorAuth;
    return userEntity;
  }

  private validateAccessToken(accessToken: string): void {
    if (!accessToken || accessToken.trim() === '') {
      throw new Error('Failed to validate access token.');
    }

    const tokenData = this.jwtHelperService.decodeToken(accessToken);
    if (!tokenData) {
      throw new Error('Failed to validate access token.');
    }
  }

  private deleteAllCookies(): void {
    // console.log(document.cookie);
    const cookies = document.cookie.split(';');

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    }
  }

  private async repopulateFromStorage(): Promise<void> {
    console.debug('AuthorizeService => repopulateFromStorage');

    const serializedUserInfo = await this._storage.get({
      key: storeKeys.STORAGE_USER_INFO,
    });
    const serializedOidcConfig = await this._storage.get({
      key: storeKeys.STORAGE_OIDC_CONFIG,
    });
    const accessToken = await this._storage.get({
      key: storeKeys.STORAGE_ACCESS_TOKEN,
    });
    const refreshToken = await this._storage.get({
      key: storeKeys.STORAGE_REFRESH_TOKEN,
    });
    const idToken = await this._storage.get({
      key: storeKeys.STORAGE_ID_TOKEN,
    });
    const expiresIn = await this._storage.get({
      key: storeKeys.STORAGE_EXPIRES_IN,
    });
    const issuedAt = await this._storage.get({
      key: storeKeys.STORAGE_ISSUED_AT,
    });

    if (serializedOidcConfig.value) {
      console.debug(
        'AuthorizeService => repopulateFromStorage => oidc config',
        accessToken.value,
      );
      this.oidcConfig = JSON.parse(serializedOidcConfig.value);
    }

    if (serializedUserInfo.value) {
      console.debug(
        'AuthorizeService => repopulateFromStorage => oidc config',
        accessToken.value,
      );
      const user: UserEntity = JSON.parse(serializedUserInfo.value);
      this.setUserSubject(user);
      this._appBusService.loginData({
        userId: user.sub,
        userName: user.name,
        currentTenantId: null,
        tenants: user.tenants,
        profile: user.currentProfileId,
      });
    } else {
      this.setUserSubject(null);
    }

    if (accessToken.value) {
      console.debug(
        'AuthorizeService => repopulateFromStorage => access token',
        accessToken.value,
      );
      this.accessToken = accessToken.value;
    }

    if (refreshToken.value) {
      console.debug(
        'AuthorizeService => repopulateFromStorage => refresh token',
        refreshToken.value,
      );
      this.refreshToken = refreshToken.value;
    }

    if (idToken.value) {
      console.debug(
        'AuthorizeService => repopulateFromStorage => id token',
        idToken.value,
      );
      this.idToken = idToken.value;
    }

    if (expiresIn.value) {
      console.debug(
        'AuthorizeService => repopulateFromStorage => expires in',
        expiresIn.value,
      );
      this.expiresIn = parseInt(expiresIn.value, 10);
    }

    if (issuedAt.value) {
      console.debug(
        'AuthorizeService => repopulateFromStorage => issued at',
        issuedAt.value,
      );
      this.issuedAt = parseInt(issuedAt.value, 10);
    }
    return Promise.resolve();
  }

  // private generateRefreshOptions(
  //   oidcOptions: OAuth2AuthenticateOptions,
  // ): OAuth2RefreshTokenOptions {
  //   return {
  //     appId: oidcOptions.appId,
  //     accessTokenEndpoint: oidcOptions.accessTokenEndpoint,
  //     refreshToken: this.refreshToken,
  //     scope: oidcOptions.scope,
  //   };
  // }

  private requestAccessToken(
    username: string,
    password: string,
    options: OAuth2AuthenticateOptions,
  ): Observable<any> {
    let url_ = this.baseUrl + '/connect/token';
    url_ = url_.replace(/[?&]$/, '');

    const body = new HttpParams()
      .set('username', username)
      .set('password', password)
      .set('scope', options.scope)
      .set('client_id', options.appId)
      .set('grant_type', 'password');

    const options_: any = {
      body: body.toString(),
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        'content-type': 'application/x-www-form-urlencoded',
      }),
    };

    return this.http
      .request('post', url_, options_)
      .pipe(
        _observableMergeMap((response_: any) => {
          return this.processRequestToken(response_);
        }),
      )
      .pipe(
        _observableCatch((response_: any) => {
          if (response_ instanceof HttpResponseBase) {
            try {
              return this.processRequestToken(<any>response_);
            } catch (e) {
              return <Observable<any>>(<any>_observableThrow(e));
            }
          } else return <Observable<any>>(<any>_observableThrow(response_));
        }),
      );
  }

  private requestAccessTokenByCode(
    code: string,
    idToken: string,
    options: OAuth2AuthenticateOptions,
  ): Observable<any> {
    let url_ = this.baseUrl + '/connect/token';
    url_ = url_.replace(/[?&]$/, '');

    const tokenEndpointConstants = new TokenEndpointConstants();

    const body = new HttpParams()
      .set('scope', options.scope)
      .set('client_id', options.appId)
      .set('grant_type', tokenEndpointConstants.tokenExchangeGrantType)
      .set('subject_token', idToken)
      .set(
        'subject_token_type',
        tokenEndpointConstants.identityTokenSubjectTokenType,
      )
      .set('two_factor_token', code);

    const options_: any = {
      body: body.toString(),
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        'content-type': 'application/x-www-form-urlencoded',
      }),
    };

    return this.http
      .request('post', url_, options_)
      .pipe(
        _observableMergeMap((response_: any) => {
          return this.processRequestToken(response_);
        }),
      )
      .pipe(
        _observableCatch((response_: any) => {
          if (response_ instanceof HttpResponseBase) {
            try {
              return this.processRequestToken(<any>response_);
            } catch (e) {
              return <Observable<any>>(<any>_observableThrow(e));
            }
          } else return <Observable<any>>(<any>_observableThrow(response_));
        }),
      );
  }

  private refreshAccessToken(
    refreshToken: string,
    options: OAuth2AuthenticateOptions,
  ): Observable<any> {
    let url_ = this.baseUrl + '/connect/token';
    url_ = url_.replace(/[?&]$/, '');

    const body = new HttpParams()
      .set('refresh_token', refreshToken)
      .set('scope', options.scope)
      .set('client_id', options.appId)
      .set('grant_type', 'refresh_token');

    const options_: any = {
      body: body.toString(),
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        'content-type': 'application/x-www-form-urlencoded',
      }),
    };

    return this.http
      .request('post', url_, options_)
      .pipe(
        _observableMergeMap((response_: any) => {
          return this.processRequestToken(response_);
        }),
      )
      .pipe(
        _observableCatch((response_: any) => {
          if (response_ instanceof HttpResponseBase) {
            try {
              return this.processRequestToken(<any>response_);
            } catch (e) {
              return <Observable<any>>(<any>_observableThrow(e));
            }
          } else return <Observable<any>>(<any>_observableThrow(response_));
        }),
      );
  }

  protected processRequestToken(response: HttpResponseBase): Observable<any> {
    const status = response.status;
    const responseBlob =
      response instanceof HttpResponse
        ? response.body
        : (<any>response).error instanceof Blob
        ? (<any>response).error
        : undefined;

    const _headers: any = {};
    if (response.headers) {
      for (const key of response.headers.keys()) {
        _headers[key] = response.headers.get(key);
      }
    }
    if (status === 200) {
      return this.blobToText(responseBlob).pipe(
        _observableMergeMap((_responseText) => {
          return _observableOf<any>(JSON.parse(_responseText));
        }),
      );
    } else if (status !== 200 && status !== 204) {
      return this.blobToText(responseBlob).pipe(
        _observableMergeMap((_responseText) => {
          return this.throwException(
            'An unexpected server error occurred.',
            status,
            _responseText,
            _headers,
          );
        }),
      );
    }
    return _observableOf<any>(<any>null);
  }

  private throwException(
    message: string,
    status: number,
    response: string,
    headers: { [key: string]: any },
    result?: any,
  ): Observable<any> {
    if (result !== null && result !== undefined)
      return _observableThrow(result);
    else return _observableThrow({ message, status, response, headers });
  }

  private blobToText(blob: any): Observable<string> {
    return new Observable<string>((observer: any) => {
      if (!blob) {
        observer.next('');
        observer.complete();
      } else {
        const reader = new FileReader();
        reader.onload = (event): void => {
          observer.next((<any>event.target).result);
          observer.complete();
        };
        reader.readAsText(blob);
      }
    });
  }
}
