<div class="dialog-content-wrapper">
  <div class="version-wrapper">
    <div class="versions">
      <h4><span>Back Office App version: {{ appConfig.version }}</span></h4>
      <h4>
        <div class="api-version-wrapper">
          <span class="mr-4">Back Office API version:</span>
          <span *ngIf="APIVersion">{{ APIVersion }}</span>
          <mat-spinner
            *ngIf="!APIVersion"
            diameter="20"
            color="accent"
            class="ml-8"
          ></mat-spinner>
          <mat-icon class="copy-versions" *ngIf="APIVersion" (click)="copyVersionsToClipboard()">file_copy</mat-icon>
        </div>
      </h4>
    </div>
  </div>
  <button mat-button [mat-dialog-close]="true" class="spl-button center mt-12">OK</button>
</div>