import {
  ContextLevelDto,
  ContextLevelType,
  TenantConfigurationData,
} from '@common/services/bo-api-client';
import * as _ from 'lodash';
import {
  PermissionContext,
  ContextLevel,
} from '@common/shared/models/permission-context';

export class ContextWrapper {
  get name(): string {
    return this.item.name;
  }
  get id(): string {
    return this.item.id;
  }
  get type(): string {
    return this.item.type;
  }
  pathName: string;
  items: ContextWrapper[] = [];
  item: ContextLevelDto;
  tenantInd?: string;
  isExpanded?: boolean;
  isSelected?: boolean;
  hasSelectedChild?: boolean;
  isDisabled?: boolean;
  parent: ContextWrapper;
  allowSendWelcomeEmails: boolean;
  useDefaultInStudioProgram: boolean;
  tenantConfig: TenantConfigurationData;

  public recalc(selectedContexts: ContextWrapper[]): void {
    if (!_.isUndefined(selectedContexts)) {
      this.isSelected = !_.isUndefined(
        _.find(selectedContexts, (sc) => sc.id === this.id),
      );
    }
    _.forEach(this.items, (i) => {
      i.recalc(selectedContexts);
    });
    this.hasSelectedChild = !_.isUndefined(
      _.find(this.items, (i) => i.isSelected),
    );
  }
  public getContextLevel(): ContextLevel {
    const c = new ContextLevel();
    c.tenantInd = this.tenantInd;
    c.context = new PermissionContext();
    c.context.contextItemId = this.item.id;
    if (this.item.type !== ContextLevelType.Root) {
      c.context.contextType = this.item.type.toString();
    } else {
      c.context.contextType = null;
    }
    return c;
  }
  public static findChild(
    items: ContextWrapper[],
    id: string,
    type: string,
    tenantInd: string,
  ): ContextWrapper {
    let item: ContextWrapper;
    _.forEach(items, (i) => {
      if (i.id === id && i.type === type && i.tenantInd == tenantInd) {
        item = i;
        return false;
      }
      const res = ContextWrapper.findChild(i.items, id, type, tenantInd);
      if (res) {
        item = res;
        return false;
      }
    });
    return item;
  }

  public static findTenantChild(
    items: ContextWrapper[],
    tenantInd: string,
  ): ContextWrapper {
    let item: ContextWrapper;
    _.forEach(items, (i) => {
      if (
        i.tenantInd === tenantInd &&
        i.item.type === ContextLevelType.Tenant
      ) {
        item = i;
        return false;
      }
    });
    return item;
  }
}
