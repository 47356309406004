import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthorizeService } from '@common/co/auth/services/authorize.service';
import { APPLICATIONS_PATHS, IApplicationPathsType, QueryParameterNames } from '@common/co/core/app.constants';

@Injectable({
  providedIn: 'root',
})
export class AuthorizeGuard  {
  constructor(
    @Inject(APPLICATIONS_PATHS) private applicationPaths: IApplicationPathsType,
    private authorize: AuthorizeService, private router: Router) {}
  canActivate(
    _next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.authorize
      .isAuthorizationExpired()
      .then(async (isTokenExpired) => {
        await this.handleAuthorization(!isTokenExpired, state);
        return Promise.resolve(!isTokenExpired);
      })
      .catch((e) => {
        console.log(`Failed to obtain authentication state. Error: ${e}`);
        return Promise.resolve(false);
      });
  }

  private async handleAuthorization(
    isAuthenticated: boolean,
    state: RouterStateSnapshot,
  ): Promise<void> {
    if (!isAuthenticated) {
      await this.authorize.logout();
      await this.router.navigate(this.applicationPaths.LoginPathComponents, {
        queryParams: {
          [QueryParameterNames.ReturnUrl]: state.url,
        },
      });
    }
    return Promise.resolve();
  }
}
