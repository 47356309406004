import { Injectable } from '@angular/core';
import * as _ from 'lodash';

import { storeKeys } from 'app/core/app.constants';
import {
  ContextLevelChangeMode,
  TenantEntity,
} from 'app/auth/model/tenant-entity.model';
import { AppBusService } from 'app/core/services/app-bus.service';
import { StorageService } from '@common/services/storage.service';
import {
  ContextLevel,
  PermissionContext,
} from '@common/shared/models/permission-context';
import {
  ContextLevelsResponse,
  ContextLevelType,
  ContextsClient,
} from '@common/services/bo-api-client';
import { filter } from 'rxjs/operators';
import { ActivationEnd, Router } from '@angular/router';
import { ContextLevelService } from 'app/core/services/context-level.service';
import { ContextWrapper } from 'app/core/models/context-level-wrapper';
import { Error401Service } from '@common/shared/components/401/error-401.service';
import { NavigationTree } from 'app/navigation/navigation';
import { FullLocationInfoModel } from 'app/core/models/full-location-info.model';

@Injectable({ providedIn: 'root' })
export class TenantService {
  private STORAGE_SELECTED_CONTEXT = storeKeys.STORAGE_SELECTED_CONTEXT;

  private _selectedContext: ContextLevel;
  private _selectedContextPending: ContextLevel;
  private initialized: boolean;
  private initPromise: Promise<void>;
  private _contextTree: ContextLevelsResponse;
  private _contextTreePromise: Promise<ContextLevelsResponse>;

  public contextLevePendingMode = ContextLevelChangeMode.Manual;

  constructor(
    private appBusService: AppBusService,
    private storageService: StorageService,
    private contextsClient: ContextsClient,
    private router: Router,
    private contextLevelService: ContextLevelService,
    private error401Service: Error401Service,
  ) {
    this.router.events
      .pipe(filter((event) => event instanceof ActivationEnd))
      .subscribe(() => {
        if (this._selectedContextPending !== undefined) {
          this.setContextLevel(this._selectedContextPending);
          this._selectedContextPending = undefined;
          this.contextLevePendingMode = ContextLevelChangeMode.Manual;
        }
      });

    this.appBusService.logout$.subscribe(async () => {
      this._selectedContext = null;
      this.initPromise = undefined;
      this.setContextLevelPending(null);
      this._contextTree = undefined;
      this._contextTreePromise = undefined;
    });

    this.appBusService.login$.subscribe(async () => {
      await this.init();
    });

    this.error401Service.error401PageAction.subscribe(() => {
      this.getMappedContextData().then(async (data) => {
        const foundHomeLevel = data.flat.find((el) => {
          return el.type === ContextLevelType.Root;
        });
        await this.setContextLevel(foundHomeLevel.getContextLevel());
        this.router.navigateByUrl(
          NavigationTree.assessments.allAssessments.url,
        );
      });
    });
  }

  public async init(): Promise<void> {
    this.initialized = false;
    const serializedContext = await this.storageService.get({
      key: this.STORAGE_SELECTED_CONTEXT,
    });
    let selectedContext: ContextLevel = null;
    if (serializedContext && serializedContext.value) {
      try {
        selectedContext = JSON.parse(serializedContext.value);
      } catch {
        await this.storageService.remove({
          key: this.STORAGE_SELECTED_CONTEXT,
        });
      }
    }
    const data = await this.getMappedContextData();
    if (!data.tree?.length) return;
    let context: ContextWrapper;
    if (selectedContext) {
      context = _.find(
        data.flat,
        (c) => c.id === selectedContext.context.contextItemId,
      );
    }
    if (!context) {
      context = data.tree[0];
    }
    this.setContextLevel(context.getContextLevel());
    this.initialized = true;
  }

  public async getCurrentTenantInd(): Promise<string> {
    const level = await this.getCurrentContextLevel();
    return level?.tenantInd;
  }

  public async getRootTenantInd(): Promise<string> {
    const level = await this.getRootContextLevel();
    return level?.tenantInd;
  }

  private parseAvailableTenants(tenantsClaim: any): TenantEntity[] {
    let tenants;
    if (typeof tenantsClaim === 'string') {
      tenants = JSON.parse(tenantsClaim);
    } else if (
      Object.prototype.toString.call(tenantsClaim) === '[object Array]'
    ) {
      tenants = _.map(tenantsClaim, (t) => JSON.parse(t));
    }

    return _.map(tenants, (t) => new TenantEntity(t));
  }

  public async setContextLevel(context: ContextLevel): Promise<void> {
    this._selectedContextPending = undefined;
    this._selectedContext = context;
    this.appBusService.changeContextLevel(context);
    await this.storageService.set({
      key: this.STORAGE_SELECTED_CONTEXT,
      value: JSON.stringify(context),
    });
  }

  public async setContextLevelPending(
    context: ContextLevel,
    mode: ContextLevelChangeMode = ContextLevelChangeMode.Manual,
  ): Promise<void> {
    this._selectedContextPending = context;
    this.contextLevePendingMode = mode;
  }

  public getPermissionContextsFromContextLevel(
    context: ContextLevel,
  ): PermissionContext[] {
    if (!context || !context.context || !context.context.contextType) {
      return [];
    }
    return [context.context];
  }
  public async getCurrentContextLevelForInteceptor(): Promise<ContextLevel> {
    if (this.initialized) {
      return Promise.resolve(this._selectedContext);
    }
    return Promise.resolve(null);
  }

  public async getRootContextLevel(): Promise<ContextLevel> {
    const tree = await this.getMappedContextData();
    return tree?.tree[0]?.getContextLevel();
  }
  public async getCurrentContextLevel(): Promise<ContextLevel> {
    if (this.initialized) {
      return Promise.resolve(this._selectedContext);
    }
    if (!this.initPromise) {
      this.initPromise = this.init();
    }
    try {
      await this.initPromise;
      return this._selectedContext;
    } catch (e) {
      this.initPromise = undefined;
      throw e;
    }
  }

  private async contextTree(force?: boolean): Promise<ContextLevelsResponse> {
    if (force || _.isNil(this._contextTreePromise)) {
      this._contextTreePromise = this.contextsClient
        .getContextsTree(null, null, false)
        .toPromise();
    }
    try {
      this._contextTree = await this._contextTreePromise;
      return Promise.resolve(this._contextTree);
    } catch (e) {
      this._contextTreePromise = undefined;
      throw e;
    }
  }

  public async checkAccessToTenants(): Promise<boolean> {
    const contextTree = await this.contextTree();
    if (!contextTree) return;
    if (contextTree.root?.items?.length === 0) {
      this.router.navigateByUrl(
        NavigationTree.errors.permissionsRestriction.url,
      );
      return false;
    }
  }

  public async getMappedContextData(
    force?: boolean,
  ): Promise<{ tree: ContextWrapper[]; flat: ContextWrapper[] }> {
    const response = await this.contextTree(force);
    if (!response) return { tree: null, flat: [] };
    const flatData = [];
    const tree = [
      this.contextLevelService.mapContexts(flatData, response.root),
    ];
    return { tree: tree, flat: flatData };
  }

  public async getContextLevelByContextItemId(
    contextItemId: string,
  ): Promise<ContextLevel | undefined> {
    const contextTreeResponse = await this.contextTree(false);
    const flatData = [];
    this.contextLevelService.mapContexts(flatData, contextTreeResponse.root);
    const foundContextWrapper = flatData.find((el) => {
      return el.id === contextItemId?.toUpperCase();
    });
    if (!foundContextWrapper) return undefined;
    const c = new ContextLevel();
    c.tenantInd = foundContextWrapper.tenantInd;
    c.context = new PermissionContext();
    c.context.contextItemId = foundContextWrapper.id;
    c.context.contextType = foundContextWrapper.type;
    return c;
  }

  public async getCurrentContextWrapper(): Promise<ContextWrapper> {
    const contextTreeResponse = await this.contextTree(false);
    const flatData = [];
    this.contextLevelService.mapContexts(flatData, contextTreeResponse.root);
    const currentContextItemId = (await this.getCurrentContextLevel())?.context
      ?.contextItemId;
    return flatData.find((el) => {
      return el.id?.toUpperCase() === currentContextItemId?.toUpperCase();
    });
  }

  public async currentContextLevelUseDefaultInStudioProgram(): Promise<boolean> {
    return (await this.getCurrentContextWrapper()).useDefaultInStudioProgram;
  }

  public async currentContextLevelAllowSendWelcomeEmails(): Promise<boolean> {
    return (await this.getCurrentContextWrapper()).allowSendWelcomeEmails;
  }

  public async getLocationByCurrentContextLevel(): Promise<
    FullLocationInfoModel[]
  > {
    const currentContextWrapper = await this.getCurrentContextWrapper();
    if (!currentContextWrapper) return [];
    const locations: FullLocationInfoModel[] = [];
    this.fillLocationsByContextWrappers([currentContextWrapper], locations);
    return locations;
  }

  private fillLocationsByContextWrappers(
    items: ContextWrapper[],
    locations: FullLocationInfoModel[],
  ): void {
    items.forEach((el) => {
      if (el.type === ContextLevelType.Location) {
        locations.push({
          tenantId: el.parent.id,
          locationId: el.id,
          tenantName: el.parent.name,
          locationName: el.name,
        });
      } else if (el.items?.length > 0) {
        this.fillLocationsByContextWrappers(el.items, locations);
      }
    });
  }
}
