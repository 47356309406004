import { Injectable, Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import {
  PermissionsClient,
  UserPermissionsListQuery,
  PermissionContextDto,
  PermissionTokenDto,
} from '@common/services/bo-api-client';
import { PermissionsServiceBase } from '@common/shared/services/permissions.service';
import { PermissionContext } from '@common/shared/models/permission-context';
import { PermissionToken } from '@common/shared/models/permission-token';
import { AuthorizeService } from 'app/auth/authorize.service';
import { AppBusService } from 'app/core/services/app-bus.service';
import { TenantService } from './tenant.service';

@Injectable({ providedIn: 'root' })
export class PermissionsService extends PermissionsServiceBase {
  private _appBusService: AppBusService;
  constructor(
    private permissionsClient: PermissionsClient,
    private authorizeService: AuthorizeService,
    private tenantService: TenantService,
    appBusService: AppBusService,
    route: ActivatedRoute,
    router: Router,
    injector: Injector,
  ) {
    super(route, router, injector, appBusService);
    this._appBusService = appBusService;
    this._appBusService.contextLevelChanged$.subscribe(async (data) => {
      const token = await this.authorizeService.getTokenAccessWithoutEnsure();
      if (!token) return;
      const permContexts =
        this.tenantService.getPermissionContextsFromContextLevel(data);
      this.reloadPermissions(data?.tenantInd, undefined, permContexts).then(
        (permissions: PermissionToken[]) =>
          appBusService.changePermissions(permissions),
      );
    });

    this._appBusService.logout$.subscribe(() => {
      this.clearPermissionsCache();
    });
  }
  public reloadCurrentPermissionsAndRiseEvent(): void {
    this.reloadPermissions().then((permissions: PermissionToken[]) =>
      this._appBusService.changePermissions(permissions),
    );
  }

  protected async getTokensWithContexts(
    tenantId: string,
    userId: string,
    contexts: PermissionContext[],
  ): Promise<PermissionTokenDto[]> {
    await this.authorizeService.getAccessToken();
    return this.permissionsClient
      .getUserPermissions(
        new UserPermissionsListQuery({
          userId,
          tenantInd: tenantId,
          contexts: _.map(
            contexts,
            (c) =>
              new PermissionContextDto({
                contextItemId: c.contextItemId,
                contextType: c.contextType,
              }),
          ),
        }),
      )
      .toPromise()
      .then((t) => {
        return _.map(t.tokens, (t) => t);
      });
  }

  protected async getTokens(
    tenantId: string,
    userId: string,
    contexts: PermissionContext[],
  ): Promise<PermissionToken[]> {
    await this.authorizeService.getAccessToken();
    return this.permissionsClient
      .getUserPermissions(
        new UserPermissionsListQuery({
          userId,
          tenantInd: tenantId,
          contexts: _.map(
            contexts,
            (c) =>
              new PermissionContextDto({
                contextItemId: c.contextItemId,
                contextType: c.contextType,
              }),
          ),
        }),
      )
      .toPromise()
      .then((t) => {
        if (t) {
          return _.map(t.tokens, (t) => t.id as PermissionToken);
        } else {
          return undefined;
        }
      });
  }
}
