import { Directive, ElementRef, EventEmitter, HostListener, Output, Self } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[disallowSpaces]',
})
export class DisallowSpacesDirective {
  @Output() ngModelChange: EventEmitter<any> = new EventEmitter();
  
  constructor(private ref: ElementRef,
              @Self() private ngControl: NgControl) {}
  @HostListener('input', ['$event'])
  public onInput(): void {
    const val = this.ref.nativeElement.value.replace(/ /g, '');
    this.ngControl.control.patchValue(val);
  }
  
}