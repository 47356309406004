import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IpRestrictionErrorPageComponent } from 'app/error-screens/ip-restriction-error-page/ip-restriction-error-page.component';
import { PermissionsRestrictionErrorPageComponent } from 'app/error-screens/permissions-restriction-error-page/permissions-restriction-error-page.component';
import { ServerIsUnavailableComponent } from 'app/error-screens/server-is-unavailable/server-is-unavailable.component';

const routes: Routes = [
  {
    path: 'errors/ip-restriction',
    component: IpRestrictionErrorPageComponent,
  },
  {
    path: 'errors/permissions-restriction',
    component: PermissionsRestrictionErrorPageComponent,
  },
  {
    path: 'errors/server-is-unavailable',
    component: ServerIsUnavailableComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ErrorScreensRoutes {}
