import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FuseConfigService } from '@fuse/services/config.service';
import { AuthorizeService } from 'app/auth/authorize.service';
import { TenantService } from 'app/auth/tenant.service';
import { ContextLevelType } from '@common/services/bo-api-client';
import { ActivatedRoute, Router } from '@angular/router';
import { AppBusService } from 'app/core/services/app-bus.service';

@Component({
  selector: 'permissions-restriction-error-page',
  templateUrl: './permissions-restriction-error-page.component.html',
  styleUrls: ['./permissions-restriction-error-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PermissionsRestrictionErrorPageComponent implements OnInit {
  public tenantName: string;
  public displayHomepageButton: boolean;

  constructor(
    private _fuseConfigService: FuseConfigService,
    private _authorize: AuthorizeService,
    private _tenantService: TenantService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _appBusService: AppBusService,
  ) {
    this._fuseConfigService.hideFuseLayout();

    this._route.queryParams.subscribe((params) => {
      this.tenantName = params['tenantName'];
    });
  }

  ngOnInit(): void {
    this.processScreenConfig();
  }

  private async processScreenConfig(): Promise<void> {
    const tree = (await this._tenantService.getMappedContextData(true))?.tree;
    this.displayHomepageButton =
      tree?.length > 0 &&
      ((tree[0].type === ContextLevelType.Root && tree[0].items?.length > 0) ||
        tree[0].type !== ContextLevelType.Root);

    if (this.displayHomepageButton) {
      this._tenantService.setContextLevel(tree[0].getContextLevel());
    }
  }

  public logout(): void {
    this._authorize.logout();
  }

  public goToHomepage(): void {
    this._router.navigateByUrl('');
  }
}
