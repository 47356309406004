import { Inject, Injectable } from '@angular/core';
import { Device, DeviceInfo } from '@capacitor/device';
import { APP_INFO, IAppInfo } from '../config/app.info';
import { OAuth2AuthenticateOptions } from '../models/OAuth2AuthenticateOptions';
import { Keyboard } from '@capacitor/keyboard';
import { SplashScreen } from '@capacitor/splash-screen';
import { APP_CONFIG, IAppConfig } from '@common/co/core/config/app.config';
import { Platform } from '@angular/cdk/platform';
import {
  IoidcConfigurationClientService,
  OIDC_CONFIGURATION_CLIENT,
} from '@common/services/ioidc-configuration-client-service';

@Injectable()
export class PlatformService {
  readonly authority_key: string = 'authority';
  readonly client_id_key: string = 'client_id';
  readonly redirect_uri_key: string = 'redirect_uri';
  readonly response_type_key: string = 'response_type';
  readonly scope_key: string = 'scope';

  private deviceInfo: Promise<DeviceInfo> = Device.getInfo();
  private _isMobile: boolean;

  constructor(
    @Inject(APP_INFO) private appInfo: IAppInfo,
    @Inject(APP_CONFIG) private appConfig: IAppConfig,
    @Inject(OIDC_CONFIGURATION_CLIENT)
    private _oidcConfigurationClient: IoidcConfigurationClientService,
    private _platform: Platform,
  ) {
    this.isIos().then((res: boolean) => {
      if (!res) {
        return;
      }
      Keyboard.setScroll({ isDisabled: true });
    });

    this._isMobile = this._platform.ANDROID || this._platform.IOS;
  }

  async os(): Promise<string> {
    return (await this.deviceInfo).osVersion;
  }
  async platform(): Promise<string> {
    return (await this.deviceInfo).platform;
  }
  async isWeb(): Promise<boolean> {
    return (await this.deviceInfo).platform == 'web';
  }

  async isAndroid(): Promise<boolean> {
    return (await this.deviceInfo).platform == 'android';
  }

  get isMobile(): boolean {
    return this._isMobile;
  }

  async isIos(): Promise<boolean> {
    return (await this.deviceInfo).platform == 'ios';
  }

  async getAppOidcAppId(): Promise<string> {
    if (await this.isAndroid()) {
      console.debug(
        `PlatformService => getAppOidcAppId => ${this.appInfo.IdentityServerAndroidAppName}`,
      );
      return this.appInfo.IdentityServerAndroidAppName;
    } else if (await this.isIos()) {
      console.debug(
        `PlatformService => getAppOidcAppId => ${this.appInfo.IdentityServerIOSAppName}`,
      );
      return this.appInfo.IdentityServerIOSAppName;
    } else if (await this.isWeb()) {
      console.debug(
        `PlatformService => getAppOidcAppId => ${this.appInfo.IdentityServerWebAppName}`,
      );
      return this.appInfo.IdentityServerWebAppName;
    }
    this.couldntObtainPlatform();
  }

  async getOidcConfiguration(): Promise<OAuth2AuthenticateOptions> {
    const oidcAppId: string = await this.getAppOidcAppId();
    try {
      const oidcParams = await this._oidcConfigurationClient
        .getOidcConfiguration(oidcAppId)
        .toPromise();
      console.debug(
        `PlatformService => getOidcConfiguration => ${JSON.stringify(
          oidcParams,
        )}`,
      );
      return await this.composeAuthOptions(oidcAppId, oidcParams);
    } catch {
      return undefined;
    }

    // "authority": "https://192.168.100.100:50051",
    // "client_id": "SPL.SPA",
    // "redirect_uri": "http://localhost:4200/authentication/login-callback",
    // "post_logout_redirect_uri": "http://localhost:4200/authentication/logout-callback",
    // "response_type": "authorization_code",
    // "scope": "openid profile Athletes.API"

    // let oidcParamsPromise = new Promise((resolve, reject) => {
    //     this._oidcConfigurationClient.getClientRequestParameters(oidcAppId).then((response) => {
    //         resolve(response);
    //     }, reject);

    // let oidcParamsPromise = new Promise<{ [key: string]: string }>((resolve, reject) => {
    //     this._oidcConfigurationClient.getClientRequestParameters(oidcAppId)
    //         .subscribe((response: any) => {
    //             resolve(response);
    //         }, reject);
    //     });
  }

  public hideNativeSplashScreen(): void {
    SplashScreen.hide();
  }
  // options: OAuth2AuthenticateOptions = {
  //     authorizationBaseUrl: 'https://10.0.2.2:5001/connect/authorize',
  //     accessTokenEndpoint: 'https://10.0.2.2:5001/connect/token',
  //     scope: 'openid profile Athletes.API',
  //     pkceEnabled: true,
  //     resourceUrl: 'https://10.0.2.2:5001/connect/userinfo',
  //     web: {
  //       appId: 'SPL.SPA',
  //       responseType: 'code',
  //       accessTokenEndpoint: 'https://localhost:500/connect/token',
  //       redirectUrl: 'http://localhost:4200/authentication/login-callback',
  //       windowOptions: 'height=600,left=0,top=0'
  //     },
  //     android: {
  //         appId: 'SPL.ANDROID',
  //         responseType: 'code',
  //         accessTokenEndpoint: 'https://10.0.2.2:5001/connect/token',
  //         redirectUrl: 'com.splzone.athletes://callback',
  //         pkceEnabled: true,
  //         handleResultOnNewIntent: true,
  //         handleResultOnActivityResult: true
  //     }
  //   };

  private async composeAuthOptions(
    appId: string,
    params: { [key: string]: string },
  ): Promise<OAuth2AuthenticateOptions> {
    const options: OAuth2AuthenticateOptions = {
      appId: appId,
      authorizationBaseUrl: `${this.appConfig.identityServerUrl}/connect/authorize`,
      accessTokenEndpoint: `${this.appConfig.identityServerUrl}/connect/token`,
      redirectUrl: params[this.redirect_uri_key],
      scope: params[this.scope_key],
      pkceEnabled: true,
      resourceUrl: `${this.appConfig.identityServerUrl}/connect/userinfo`,
      responseType: this.mapReponseType(params[this.response_type_key]),
    };

    // if (this.isWeb()) {
    //   options.web = this.composeWebOptions();
    // } else if (this.isAndroid()) {
    //   options.android = this.composeAndroidOptions();
    // } else if (this.isIos()) {
    //   options.ios = this.composeIosOptions();
    // } else {
    //   this.couldntObtainPlatform();
    // }
    console.debug(
      `PlatformService => composeAuthOptions => ${JSON.stringify(options)}`,
    );
    return options;
  }

  // private composeAndroidOptions(): AndroidOptions {
  //   return {
  //     handleResultOnNewIntent: true,
  //     handleResultOnActivityResult: true,
  //   };
  // }

  // private composeIosOptions(): IosOptions {
  //   throw new Error('Method not implemented.');
  // }

  // private composeWebOptions(): WebOption {
  //   return {
  //     windowOptions: 'height=600,left=0,top=0',
  //   };
  // }

  private mapReponseType(responseType: string): string {
    switch (responseType) {
      case 'authorization_code':
        return 'code';
      case 'implicit':
        return 'token';
      case 'password':
        return 'password';
    }
    throw new Error(
      `Method not implemented for response type: '${responseType}'.`,
    );
  }

  private couldntObtainPlatform(): void {
    throw new Error('Could not obtain target platform.');
  }
}
