export const ReturnUrlType = 'returnUrl';

export const QueryParameterNames = {
  ReturnUrl: ReturnUrlType,
  Message: 'message',
};

export const LogoutActions = {
  LogoutCallback: 'logout-callback',
  Logout: 'logout',
  LoggedOut: 'logged-out',
};

export const LoginActions = {
  Login: 'login',
  LoginCallback: 'login-callback',
  LoginFailed: 'login-failed',
  Profile: 'profile',
  Register: 'register',
};

let applicationPaths: IApplicationPathsType = {
  DefaultLoginRedirectPath: '/',
  DefaultRegisterRedirectPath: '/',
  Login: `${LoginActions.Login}`,
  LoginFailed: `${LoginActions.LoginFailed}`,
  LoginCallback: `${LoginActions.LoginCallback}`,
  Register: `${LoginActions.Register}`,
  Profile: `${LoginActions.Profile}`,
  LogOut: `${LogoutActions.Logout}`,
  LoggedOut: `${LogoutActions.LoggedOut}`,
  LogOutCallback: `${LogoutActions.LogoutCallback}`,
  LoginPathComponents: [],
  LoginFailedPathComponents: [],
  LoginCallbackPathComponents: [],
  RegisterPathComponents: [],
  ProfilePathComponents: [],
  LogOutPathComponents: [],
  LoggedOutPathComponents: [],
  LogOutCallbackPathComponents: [],
  IdentityRegisterPath: '/Identity/Account/Register',
  IdentityManagePath: '/Identity/Account/Manage',
};

applicationPaths = {
  ...applicationPaths,
  LoginPathComponents: applicationPaths.Login.split('/'),
  LoginFailedPathComponents: applicationPaths.LoginFailed.split('/'),
  RegisterPathComponents: applicationPaths.Register.split('/'),
  ProfilePathComponents: applicationPaths.Profile.split('/'),
  LogOutPathComponents: applicationPaths.LogOut.split('/'),
  LoggedOutPathComponents: applicationPaths.LoggedOut.split('/'),
  LogOutCallbackPathComponents: applicationPaths.LogOutCallback.split('/'),
};

interface IApplicationPathsType {
  readonly DefaultLoginRedirectPath: string;
  readonly DefaultRegisterRedirectPath: string;
  readonly Login: string;
  readonly LoginFailed: string;
  readonly LoginCallback: string;
  readonly Register: string;
  readonly Profile: string;
  readonly LogOut: string;
  readonly LoggedOut: string;
  readonly LogOutCallback: string;
  readonly LoginPathComponents: string[];
  readonly LoginFailedPathComponents: string[];
  readonly LoginCallbackPathComponents: string[];
  readonly RegisterPathComponents: string[];
  readonly ProfilePathComponents: string[];
  readonly LogOutPathComponents: string[];
  readonly LoggedOutPathComponents: string[];
  readonly LogOutCallbackPathComponents: string[];
  readonly IdentityRegisterPath: string;
  readonly IdentityManagePath: string;
}

export const ApplicationPaths: IApplicationPathsType = applicationPaths;

interface IStoreKeys {
  STORAGE_SELECTED_CONTEXT: string;
  STORAGE_ACCESS_TOKEN: string;
  STORAGE_OIDC_CONFIG: string;
  STORAGE_USER_INFO: string;
  STORAGE_REFRESH_TOKEN: string;
  STORAGE_ID_TOKEN: string;
  STORAGE_TWO_FACTOR_AUTH_ID_TOKEN: string;
  STORAGE_TWO_FACTOR_TOKEN_WAS_SENT: string;
  STORAGE_EXPIRES_IN: string;
  STORAGE_ISSUED_AT: string;
}

export const storeKeys: IStoreKeys = {
  STORAGE_SELECTED_CONTEXT: 'storage_selected_context',
  STORAGE_ACCESS_TOKEN: 'oidc-access-token',
  STORAGE_OIDC_CONFIG: 'oidc-config',
  STORAGE_USER_INFO: 'user-info',
  STORAGE_REFRESH_TOKEN: 'oidc-refresh-token',
  STORAGE_ID_TOKEN: 'oidc-id-token',
  STORAGE_TWO_FACTOR_AUTH_ID_TOKEN: 'two-factor-auth-id-token',
  STORAGE_TWO_FACTOR_TOKEN_WAS_SENT: 'two-factor-token-was-sent',
  STORAGE_EXPIRES_IN: 'oidc-expires-in',
  STORAGE_ISSUED_AT: 'oidc-issued-at',
};

export interface IStringConstants {
  readonly RecordNotFound: string;
}

export const StringConstants: IStringConstants = {
  RecordNotFound: 'Requested record not found.',
};
