import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { OverlayLoaderService } from '@common/shared/components/overlay-loader/overlay-loader.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'spl-overlay-loader',
  templateUrl: './overlay-loader.component.html',
  styleUrls: ['./overlay-loader.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OverlayLoaderComponent {

  constructor(
    private overlayLoaderService: OverlayLoaderService
  ) {
  }

  public get title(): Observable<string> {
    return this.overlayLoaderService.title;
  }

  public get progress(): Observable<number> {
    return this.overlayLoaderService.progress;
  }
}
