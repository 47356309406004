import { NotificationType } from '@common/services/co-api-client';
export type Level =
  | 'error'
  | 'success'
  | 'warning'
  | 'info'
  | 'validation'
  | 'push';
export type Source = 'http' | 'ts' | 'unknown' | 'component' | 'push';
export type PushTypes = 'redirect' | undefined;

export class SplNotification {
  level: Level;
  message: string;
  id: string;
  createdDate: Date;
  duration?: number | '';
  relatedObject?: any;
  source?: Source;

  constructor(
    level: Level,
    message: string,
    id: string,
    createdDate: Date,
    duration?: number | '',
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    relatedObject?: any,
    source?: Source,
  ) {
    this.level = level;
    this.message = message;
    this.id = id;
    this.createdDate = createdDate;
    this.duration = duration || '';
    this.relatedObject = relatedObject || {};
    this.source = source || 'unknown';
  }
}

export class SplPushNotification {
  id: string;
  data: SplPushNotificationData;
  title?: string;
  body?: string;

  constructor(
    id: string,
    data: SplPushNotificationData,
    title?: string,
    body?: string,
  ) {
    this.id = id;
    this.title = title || '';
    this.body = body || '';
    this.data = data;
  }
}

export class SplPushNotificationData {
  notificationType: NotificationType;
  objectId?: string;
  athleteId?: string;
  actionType?: PushTypes;

  constructor(
    notificationType: NotificationType,
    objectId?: string,
    athleteId?: string,
    actionType?: PushTypes,
  ) {
    this.notificationType = notificationType;
    this.objectId = objectId;
    this.actionType = actionType;
    this.athleteId = athleteId;
  }
}
