import { NgModule } from '@angular/core';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { LabelModule } from '@progress/kendo-angular-label';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { ContextSelectorComponent } from './context-selector.component';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@common/co/core/material.module';

@NgModule({
  declarations: [ContextSelectorComponent],
  imports: [DropDownsModule, LabelModule, InputsModule, ButtonsModule, CommonModule, MaterialModule],
  exports: [ContextSelectorComponent],
})
export class ContextSelectorModule {}
