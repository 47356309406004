<!-- PROGRESS BAR -->
<fuse-progress-bar></fuse-progress-bar>
<!-- / PROGRESS BAR -->
<!-- SERVICE IS UNAVAILABLE -->
<service-is-unavailable-message></service-is-unavailable-message>
<!-- / SERVICE IS UNAVAILABLE -->


<!-- BO APP LAYOUT -->
<ng-container *ngIf="isInitialized">
  <bo-app-layout></bo-app-layout>
</ng-container>
<!-- / BO APP LAYOUT -->
