import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TreeItem } from '@progress/kendo-angular-treeview';

import { AppBusService } from 'app/core/services/app-bus.service';
import { ContextLevelService } from 'app/core/services/context-level.service';
import { ContextWrapper } from 'app/core/models/context-level-wrapper';
import { TenantService } from 'app/auth/tenant.service';
import * as _ from 'lodash';
import { unsubscribeAll } from '@common/shared/decorators/unsubscribe-all';
import { IPayloadItem } from 'app/navigation/navigation';
import {
  ContextLevelType,
  ContextsClient,
} from '@common/services/bo-api-client';
import { DropDownTreeComponent } from '@progress/kendo-angular-dropdowns';

@Component({
  selector: 'spl-context-selector',
  templateUrl: './context-selector.component.html',
  styleUrls: ['./context-selector.component.scss'],
})
export class ContextSelectorComponent implements OnInit, OnDestroy {
  @ViewChild('dropDownTree') dropDownTree: DropDownTreeComponent;
  public isLoading: boolean;
  public data: ContextWrapper[];
  public flatData: ContextWrapper[];
  public prevSelected: ContextWrapper;
  public selected: ContextWrapper;
  public disableSwitch;
  public disableRoot;
  public rootContextLevelType;

  constructor(
    private contextsClient: ContextsClient,
    private contextLevelService: ContextLevelService,
    private appBusService: AppBusService,
    private tenantService: TenantService,
  ) {}

  @unsubscribeAll()
  private subscriptions: any = {};

  async ngOnInit(): Promise<void> {
    await this.reloadContextTree();
    this.subscriptions.tenant =
      this.appBusService.contextLevelChanged$.subscribe((level) => {
        if (!level) {
          this.setSelectedValue(this.data[0]);
          return;
        }
        const selected = _.find(
          this.flatData,
          (w) =>
            w.tenantInd === level.tenantInd &&
            w.item.id === level.context.contextItemId,
        );
        this.setSelectedValue(selected);
        this.expandToRoot(this.selected);
      });

    this.subscriptions.routeInfoSubscription =
      this.appBusService.subscribeToRouteInfo((data: IPayloadItem) => {
        this.disableSwitch = data.canSwitchContextLevel === false;
        this.disableRoot = !data.allowCrossTenantAccess;
        this.setDisableRootLevel(this.disableRoot, this.data);
      });
    this.subscriptions.reloadContexts =
      this.appBusService.reloadContextTree$.subscribe(() => {
        this.reloadContextTree();
      });
  }

  ngOnDestroy(): void {
    //unsubscribeAll
  }

  private async reloadContextTree(): Promise<void> {
    const res = await this.tenantService.getMappedContextData(true);
    if (!res?.tree) return;
    this.flatData = res.flat;
    this.data = res.tree;
    this.data[0].isExpanded = true;
    this.rootContextLevelType = this.data[0].type;
    if (this.selected) {
      const exists = _.find(
        this.flatData,
        (w) =>
          w.tenantInd === this.selected.tenantInd &&
          w.item.id === this.selected.item.id,
      );
      // if no access for selected level select root
      if (!exists) {
        this.selectContext(null);
      }
    }
  }
  private setDisableRootLevel(disable: boolean, data: ContextWrapper[]): void {
    if (!data || data.length === 0) return;
    data.forEach((el) => {
      if (el.type === ContextLevelType.Root) {
        el.isDisabled = disable;
      }
    });
  }

  public valueChange(value: ContextWrapper): void {
    if (value.isDisabled) {
      this.setSelectedValue(this.prevSelected);
      return;
    }
    this.selectContext(value);
  }

  private setSelectedValue(value: ContextWrapper): void {
    this.selected = value;
    this.prevSelected = this.selected;
    this.dropDownTree.dataItem = value;
    this.dropDownTree.value = value;
  }

  async selectContext(context: ContextWrapper): Promise<void> {
    if (!context) {
      this.setSelectedValue(this.data[0]);
      context = this.selected;
    } else {
      this.setSelectedValue(context);
    }
    const c = context.getContextLevel();
    this.tenantService.setContextLevel(c);
  }

  public expandToRoot(w: ContextWrapper): void {
    if (w.parent) {
      w.parent.isExpanded = true;
      this.expandToRoot(w.parent);
    }
  }
  public isNodeExpanded = (node: ContextWrapper): boolean => {
    return node.isExpanded;
  };

  public hasChildren(node: ContextWrapper): boolean {
    return node.items && node.items.length > 0;
  }

  public handleCollapse(args: TreeItem): void {
    args.dataItem.isExpanded = false;
  }

  public handleExpand(args: TreeItem): void {
    args.dataItem.isExpanded = true;
  }
}
