import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivateFn } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { AuthorizeGuard } from '@common/co/auth/guards/authorize.guard';
import { PermissionsGuard } from '@common/co/auth/guards/permissions.guard';
import { CompletedRegistrationGuard } from '@common/co/auth/guards/completed-registration.guard';
import { SelectProfileGuard } from '@common/co/auth/components/login/components/select-profile/select-profile.guard';
import { InTakeGuard } from '@common/co/auth/guards/in-take.guard';

export const appGuards = [
  AuthorizeGuard,
  PermissionsGuard,
  CompletedRegistrationGuard,
  SelectProfileGuard,
  InTakeGuard
];

@Injectable({
  providedIn: 'root',
})
export class MasterGuard  {
  constructor(private injector: Injector) {
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    const guards = route.data?.guards || [];
    for (const guard of guards) {
      const instance: {
    canActivate: CanActivateFn;
} = this.injector.get(guard);
      let result  = instance.canActivate(route, state);

      //Depending on the route result, we may need to await upon it in different ways.
      if(result instanceof Promise) {
        result = await result;
      }

      if(result instanceof Observable) {
        result = await result.toPromise();
      }

      if (result === false || result instanceof UrlTree) {
        return result;
      }

      if (result !== true) {
        throw new Error('Not implemented guard result type')
      }
    }
    return true;
  }
}