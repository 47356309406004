
export class PermissionContext {
  contextType?: string;
  contextItemId?: string;
}

export class ContextLevel {
  context: PermissionContext;
  tenantInd?: string;
}
