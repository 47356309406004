import { Injectable } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { BehaviorSubject } from 'rxjs';
import { map, scan } from 'rxjs/operators';
import { ComponentPortal } from '@angular/cdk/portal';
import { OverlayLoaderComponent } from '@common/shared/components/overlay-loader/overlay-loader.component';

@Injectable({
  providedIn: 'root'
})
export class OverlayLoaderService {

  public isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public title: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  public progress: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);
  private spinnerTopRef: OverlayRef = this.cdkSpinnerCreate();

  constructor(
    private overlay: Overlay,
  ) {
    this.isLoading
      .asObservable()
      .pipe(
        map(val => val ? 1 : -1),
        scan((acc, one) => (acc + one) >= 0 ? acc + one : 0, 0),
      )
      .subscribe(
        (res) => {
          if (res === 1) {
            this.showSpinner();
          } else if (res == 0) {
            this.spinnerTopRef.hasAttached() ? this.stopSpinner() : null;
          }
        },
      );
  }

  public setTitle(value: string): void {
    this.title.next(value);
  }

  public setProgress(value: number): void {
    this.progress.next(value);
  }

  private cdkSpinnerCreate(): OverlayRef {
    return this.overlay.create({
      hasBackdrop: true,
      backdropClass: 'dark-backdrop',
      positionStrategy: this.overlay.position()
        .global()
        .centerHorizontally()
        .centerVertically(),
    });
  }

  private showSpinner(): void {
    this.spinnerTopRef.attach(new ComponentPortal(OverlayLoaderComponent));
  }

  private stopSpinner(): void {
    this.progress.next(null);
    this.title.next(null);
    this.spinnerTopRef.detach();
  }
}
