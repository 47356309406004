<div class="dialog-content-wrapper">
  <h4><span>To access this screen you should select one of the following tenants</span></h4>
  <div class="tenants">
    <select
      class="spl-select tenant-selector mr-20"
      [compareWith]="tenantCompareFn"
      [(ngModel)]="selectedTenant"
    >
      <option *ngFor="let tenant of tenants" [ngValue]="tenant">{{ tenant.name }}</option>
    </select>
  </div>
  <div class="buttons-wrapper">
    <button mat-button [mat-dialog-close]="false" class="center mt-12">Cancel</button>
    <button mat-button class="spl-button mt-12" (click)="proceed()" [disabled]="!selectedTenant">Proceed</button>
  </div>
</div>