import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  Renderer2,
  OnDestroy,
} from '@angular/core';
import { PermissionsInfo } from '@common/shared/models/permission-info';
import { PermissionToken } from '@common/shared/models/permission-token';
import { PermissionsService } from 'app/auth/permissions.service';
import { AppBusService } from 'app/core/services/app-bus.service';
import { FuseNavigationItem } from '@fuse/types';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/internal/operators';
import { TenantService } from 'app/auth/tenant.service';
import { ContextLevelType } from '@common/services/bo-api-client';

@Directive({
  selector: '[hideElementByPermissions]',
})
export class HideElementByPermissionsDirective
  implements AfterViewInit, OnDestroy
{
  @Input() hideElementByPermissions: PermissionToken[] | PermissionsInfo;
  @Input() menuItem: FuseNavigationItem;
  @Input() contextLevelType: ContextLevelType;

  private elementRef: ElementRef;
  private permissionsInfo: PermissionsInfo;
  private _unsubscribeAll: Subject<void>;
  constructor(
    elementRef: ElementRef,
    private _renderer: Renderer2,
    private appBusService: AppBusService,
    private permissionsService: PermissionsService,
    private tenantService: TenantService,
  ) {
    this._unsubscribeAll = new Subject();
    this.elementRef = elementRef;
    this.appBusService.changePermissions$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this.updatePermissions();
      });
  }

  async ngAfterViewInit(): Promise<void> {
    await this.updatePermissions();
  }
  private async updatePermissions(): Promise<void> {
    if (!this.hideElementByPermissions) return;
    if (Array.isArray(this.hideElementByPermissions)) {
      this.permissionsInfo = new PermissionsInfo(this.hideElementByPermissions);
    } else {
      this.permissionsInfo = this.hideElementByPermissions;
    }
    let access = await this.permissionsService.getPermissionAccess(
      this.permissionsInfo,
    );
    if (this.menuItem) {
      this.menuItem.hidden = !access;
    }
    if (access && this.contextLevelType) {
      const currentContextLevel =
        await this.tenantService.getCurrentContextLevel();
      access =
        currentContextLevel.context.contextType === this.contextLevelType;
    }
    if (!access) {
      this._renderer.addClass(this.elementRef.nativeElement, 'display-none');
    } else {
      this._renderer.removeClass(this.elementRef.nativeElement, 'display-none');
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
