import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';

import { Observable, from } from 'rxjs';
import { AuthorizeService } from './authorize.service';
import { TenantService } from 'app/auth/tenant.service';
import { AppInfo } from 'app/core/config/app.info';
import { ContextLevel } from '@common/shared/models/permission-context';
import { NavigationTree } from 'app/navigation/navigation';
import { Router } from '@angular/router';
import { QueryParameterNames } from 'app/core/app.constants';
import { SplExceptionModel } from '@common/models/spl-exception.model';
import { ServiceIsUnavailableService } from '@common/shared/services/service-is-unavailable.service';
import { UserStateConstants } from '@common/services/bo-api-client';
import { getSplNotification } from 'app/core/helpers/spl-notification';
import { NotificationService } from 'app/core/services/notification.service';
@Injectable({
  providedIn: 'root',
})
export class AuthorizeInterceptor implements HttpInterceptor {
  private readonly applicationInitRoutesNoToken: string[] = [
    '/connect/token',
    '/_configuration',
  ];
  private readonly applicationInitRoutes: string[] = [
    ...this.applicationInitRoutesNoToken,
    '/api/Contexts',
    '/user/permissions',
  ];
  constructor(
    private authorize: AuthorizeService,
    private tenantService: TenantService,
    private router: Router,
    private serviceIsUnavailableService: ServiceIsUnavailableService,
    private _notificationService: NotificationService,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const promise = this.tenantService
      .getCurrentContextLevelForInteceptor()
      .then((contextLevel: ContextLevel) => {
        if (this.isInitRouteNoToken(req.url)) {
          return this.processRequestNoToken(contextLevel, req, next)
            .toPromise()
            .catch(async (reason: HttpErrorResponse) => {
              return this.handleRequestError(reason, contextLevel, req, next);
            });
        } else {
          return this.authorize.getAccessToken().then((token) => {
            return this.processRequestWithToken(
              token.accessToken,
              contextLevel,
              req,
              next,
            )
              .toPromise()
              .then((res) => {
                this.serviceIsUnavailableService.displayMessage = false;
                return res;
              })
              .catch(async (reason: HttpErrorResponse) => {
                return this.handleRequestError(reason, contextLevel, req, next);
              });
          });
        }
      });
    return from(promise);
  }

  // Checks if there is an access_token available in the authorize service
  // and adds it to the request in case it's targeted at the same origin as the
  // single page application.
  private processRequestWithToken(
    token: string,
    contextLevel: ContextLevel,
    req: HttpRequest<any>,
    next: HttpHandler,
  ): any {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    if (contextLevel) {
      headers['X-App-Id'] = AppInfo.AppId;
      if (contextLevel.tenantInd) {
        headers['X-Tenant-Id'] = contextLevel.tenantInd;
      }
      if (contextLevel.context) {
        if (contextLevel.context.contextItemId) {
          headers['X-Context-Id'] = contextLevel.context.contextItemId;
        }
        if (contextLevel.context.contextType) {
          headers['X-Context-Type'] = contextLevel.context.contextType;
        }
      }
    }

    if (token) {
      req = req.clone({
        setHeaders: headers,
      });
    }
    return next.handle(req);
  }

  private processRequestNoToken(
    contextLevel: ContextLevel,
    req: HttpRequest<any>,
    next: HttpHandler,
  ): any {
    const headers = {};
    if (contextLevel) {
      headers['X-App-Id'] = AppInfo.AppId;
      if (contextLevel.tenantInd) {
        headers['X-Tenant-Id'] = contextLevel.tenantInd;
      }
      if (contextLevel.context) {
        if (contextLevel.context.contextItemId) {
          headers['X-Context-Id'] = contextLevel.context.contextItemId;
        }
        if (contextLevel.context.contextType) {
          headers['X-Context-Type'] = contextLevel.context.contextType;
        }
      }
    }

    req = req.clone({
      setHeaders: headers,
    });
    return next.handle(req);
  }

  private isInitRouteNoToken(url: string): boolean {
    return !!this.applicationInitRoutesNoToken.find(
      (el) => url.indexOf(el) > -1,
    );
  }

  private isInitRoute(url: string): boolean {
    return !!this.applicationInitRoutes.find((el) => url.indexOf(el) > -1);
  }

  private async handleRequestError(
    reason: HttpErrorResponse,
    contextLevel: ContextLevel,
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Promise<any> {
    if (
      this.serviceIsUnavailableService.displayMessage &&
      reason.status !== 503 &&
      reason.status !== 0
    ) {
      this.serviceIsUnavailableService.displayMessage = false;
    }
    if (reason.status === 503 || reason.status === 0) {
      if (!this.serviceIsUnavailableService.displayMessage) {
        await this.router.navigate(
          [NavigationTree.errors.serverIsUnavailable.url],
          {
            queryParams: {
              [QueryParameterNames.ReturnUrl]:
                window.location.pathname !==
                  NavigationTree.errors.serverIsUnavailable.url &&
                window.location.pathname !== '/'
                  ? window.location.pathname
                  : null,
            },
          },
        );
      } else {
        this.serviceIsUnavailableService.showMessage();
      }
      throw new SplExceptionModel(
        reason.statusText,
        reason.status,
        JSON.stringify(reason.error),
        reason.name,
        reason.headers,
        true,
      );
    } else if (reason.status === 403) {
      if (reason.error instanceof Blob) {
        reason.error.text().then(async (text) => {
          let detail = null;
          try {
            detail = JSON.parse(text)?.detail;
          } catch (e) {
            // incorrect json
            console.error(e);
          }
          if (detail === 'Permission') {
            const tenantName =
              await this.tenantService.getCurrentContextLevel();
            this.router.navigate(
              [NavigationTree.errors.permissionsRestriction.url],
              {
                queryParams: {
                  tenantName: tenantName.tenantInd,
                },
              },
            );
            throw new SplExceptionModel(
              reason.statusText,
              reason.status,
              JSON.stringify(reason.error),
              reason.name,
              reason.headers,
              true,
            );
          } else if (detail === 'IPAddress') {
            this.router.navigateByUrl(NavigationTree.errors.ipRestriction.url);
            throw new SplExceptionModel(
              reason.statusText,
              reason.status,
              JSON.stringify(reason.error),
              reason.name,
              reason.headers,
              true,
            );
          }
        });
      }
      throw reason;
    } else if (reason.status === 401) {
      if (reason.error instanceof Blob) {
        reason.error.text().then(async (text) => {
          let detail = null;
          try {
            detail = JSON.parse(text)?.detail;
          } catch (e) {
            // incorrect json
            console.error(e);
          }
          const userStateConstants = new UserStateConstants();
          if (detail === userStateConstants.useR_LOCKED) {
            this._notificationService.generateNotification(
              getSplNotification(
                'error',
                'The user is locked out. Please reach out to the system administrator.',
              ),
            );
          }
        });
      }
      // try refresh token and make call again
      const token = await this.authorize.refreshTokenForced();
      if (!token || token.status === 401) {
        this.authorize.logout();
        throw reason;
      }
      return this.processRequestWithToken(
        token.accessToken,
        contextLevel,
        req,
        next,
      )
        .toPromise()
        .catch((reason) => {
          if (reason.status === 401) {
            this.authorize.logout();
            throw reason;
          } else {
            throw reason;
          }
        });
    } else {
      throw reason;
    }
  }
}
