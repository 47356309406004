import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FuseSidebarModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';

import { ContentModule } from 'app/layout/bo-app-layout/components/content/content.module';
import { FooterModule } from 'app/layout/bo-app-layout/components/footer/footer.module';
import { NavbarModule } from 'app/layout/bo-app-layout/components/navbar/navbar.module';
import { ToolbarModule } from 'app/layout/bo-app-layout/components/toolbar/toolbar.module';

import { BoAppLayoutComponent } from './bo-app-layout.component';

@NgModule({
  declarations: [BoAppLayoutComponent],
  imports: [
    RouterModule,

    FuseSharedModule,
    FuseSidebarModule,

    ContentModule,
    FooterModule,
    NavbarModule,
    ToolbarModule,
  ],
  exports: [BoAppLayoutComponent],
})
export class BoAppLayoutModule {}
