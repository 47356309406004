import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { ERROR_401_CONFIG, IError401Config } from '@common/shared/components/401/error-401.interface';
import { Error401Service } from '@common/shared/components/401/error-401.service';

@Component({
  selector: 'error-401',
  templateUrl: './error-401.component.html',
  styleUrls: ['./error-401.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class Error401Component {

  constructor(@Inject(ERROR_401_CONFIG) public error401Config: IError401Config,
              private error401Service: Error401Service) {
  }

  public action(): void {
    this.error401Service.error401PageAction.next();
  }
}
