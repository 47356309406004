import { InjectionToken } from '@angular/core';

export const ReturnUrlType = 'returnUrl';

export const QueryParameterNames = {
  ReturnUrl: ReturnUrlType,
  Message: 'message',
};

export const MaterialModalConfig = {
  height: '600px',
  width: '850px',
};

export const RegExp = {
  email:
    '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
  phone: '^\\(?([0-9]{3})\\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$',
  uniqueId: '^(\\d{1,9})$',
};

export interface IApplicationPathsType {
  readonly DefaultLoginRedirectPath: string;
  readonly DefaultRegisterRedirectPath: string;
  readonly Login: string;
  readonly LoginFailed: string;
  readonly LoginCallback: string;
  readonly Register: string;
  readonly Profile: string;
  readonly LogOut: string;
  readonly LoggedOut: string;
  readonly LogOutCallback: string;
  readonly LoginPathComponents: string[];
  readonly LoginFailedPathComponents: string[];
  readonly LoginCallbackPathComponents: string[];
  readonly RegisterPathComponents: string[];
  readonly ProfilePathComponents: string[];
  readonly LogOutPathComponents: string[];
  readonly LoggedOutPathComponents: string[];
  readonly LogOutCallbackPathComponents: string[];
  readonly IdentityRegisterPath: string;
  readonly IdentityManagePath: string;
}

export interface IStringConstants {
  readonly InStudio: string;
  readonly OutOfStudio: string;
}

export interface IColorConstants {
  best: string;
  score: string;
  avg: string;
  radarChartColor: string;
  radarChartFontColor: string;
  graphColorHigh: string;
  graphColorAverages: string;
  graphColorLow: string;
}

export const APPLICATIONS_PATHS = new InjectionToken<IApplicationPathsType>(
  'application_paths',
);

export const COLORS = new InjectionToken<IColorConstants>('colors');

export const STRING_CONSTANTS = new InjectionToken<IStringConstants>(
  'string_constants',
);
