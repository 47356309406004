<div id="permissions-restriction" fxLayout="column" fxLayoutAlign="center center">
  <div class="content" fxLayout="column" fxLayoutAlign="center center">
    <div class="logo">
      <mat-icon>lock</mat-icon>
    </div>

    <div class="message">
      <span *ngIf="tenantName">You are not authorized to access data for {{ tenantName }}.</span>
      <span *ngIf="!tenantName">You are not authorized to access the application.</span>
    </div>
    
    <div class="buttons-wrapper">
      <button mat-button class="spl-button mt-28" (click)="logout()">Log out</button>
      <button *ngIf="displayHomepageButton" mat-button class="spl-button mt-28" (click)="goToHomepage()">Home page</button>
    </div>
  </div>
</div>
