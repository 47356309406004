import { InjectionToken } from '@angular/core';

export const APP_CONFIG = new InjectionToken<IAppConfig>('appConfig');

interface IAppConfig_AppInsights {
  instrumentationKey: string;
}

export interface IAppConfig {
  apiUrl: string;
  identityServerUrl: string;
  isProduction: boolean;
  isHmr: boolean;
  appInsights: IAppConfig_AppInsights;
  documentsSAConnectionString: string;
  googleClientId: string;
  facebookAppId: string;
  version: string;
  clientUrl: string;
  signalrHubUrl: string;
  bitmovinPlayerKey: string;
}
