import { Injectable } from '@angular/core';
import { TenantService } from 'app/auth/tenant.service';
import { ContextLevelChangeMode } from 'app/auth/model/tenant-entity.model';
import { Router } from '@angular/router';
import { PreviouslyContextLevelModel } from 'app/core/models/previously-context-level.model';
import { RouteState } from 'app/core/components/navigation/navigation.model';

@Injectable()
export class ComponentCacheService {
  public previouslyContextLevels: PreviouslyContextLevelModel[] = [];

  constructor(private tenantService: TenantService, private router: Router) {}

  public async navigateToUrlWithContextChange(
    contextItemId: string,
    url: string,
    state?: RouteState,
  ): Promise<void> {
    const prevContextLevel = await this.tenantService.getCurrentContextLevel();
    if (
      !prevContextLevel ||
      prevContextLevel?.context?.contextItemId === null
    ) {
      const contextLevel =
        await this.tenantService.getContextLevelByContextItemId(contextItemId);
      if (!contextLevel) throw new Error('Context Level not found');
      await this.tenantService.setContextLevelPending(
        contextLevel,
        ContextLevelChangeMode.Auto,
      );
      this.previouslyContextLevels.unshift(
        new PreviouslyContextLevelModel({
          contextLevel: await this.tenantService.getCurrentContextLevel(),
          url: url,
        }),
      );
    }
    this.router.navigate([url], { state });
  }

  public async revertContextLevel(): Promise<void> {
    if (
      this.previouslyContextLevels.length > 0 &&
      this.previouslyContextLevels[0].url === this.router.url
    ) {
      await this.tenantService.setContextLevelPending(
        this.previouslyContextLevels[0].contextLevel,
      );
      this.previouslyContextLevels.shift();
    }
  }
}
