import { Injectable, NgZone } from '@angular/core';
import { AppBusService } from 'app/core/services/app-bus.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { NotificationComponent } from 'app/core/components/notification/notification.component';
import { SplNotification } from 'app/core/models/splNotification.model';
import { take } from 'rxjs/operators';
import { timer } from 'rxjs';

@Injectable()
export class NotificationService {
  ongoingNotifications: SplNotification[] = [];

  constructor(
    private appBusService: AppBusService,
    private snackBar: MatSnackBar,
    private zone: NgZone,
  ) {
    this.appBusService.showNotificationSubject.subscribe(
      (notification: SplNotification) => {
        this.ongoingNotifications = [notification];
        if (this.ongoingNotifications.length == 1) {
          this.showNotification(this.ongoingNotifications[0]);
        }
      },
    );

    this.appBusService.hideNotificationSubject.subscribe((delay) => {
      if (this.snackBar._openedSnackBarRef) {
        timer(delay)
          .pipe(take(1))
          .subscribe(() => {
            this.dismiss();
          });
      }
    });
  }

  public generateNotification(notification: SplNotification): void {
    this.appBusService.processNotification(notification);
  }

  private showNotification(notification): void {
    this.zone.run(() => {
      const snackBarRef = this.snackBar.openFromComponent(
        NotificationComponent,
        {
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          duration: notification.duration,
          data: notification,
          panelClass: ['custom-snackbar', `${notification.level}`],
        },
      );

      snackBarRef.afterDismissed().subscribe(() => {
        const foundIndex = this.ongoingNotifications.findIndex((el) => {
          return el.id === notification.id;
        });
        this.ongoingNotifications.splice(foundIndex, 1);
        if (this.ongoingNotifications.length > 0) {
          this.showNotification(this.ongoingNotifications[0]);
        }
      });
    });
  }

  public dismiss(): void {
    this.snackBar.dismiss();
  }
}
