export type Level = 'error' | 'success' | 'warning' | 'info';
export type Source = 'http' | 'ts' | 'unknow' | 'component';

export class SplNotification {
  level: Level;
  message: string;
  id: string;
  createdDate: Date;
  duration?: number | '';
  relatedObject?: any;

  constructor(
    level: Level,
    message: string,
    id: string,
    createdDate: Date,
    duration?: number,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    relatedObject?: any
  ) {
    this.level = level;
    this.message = message;
    this.id = id;
    this.createdDate = createdDate;
    this.duration = duration || '';
    this.relatedObject = relatedObject || {};
  }
}
