import { UserTwoFactorAuthFlow } from '@common/services/bo-api-client';

export class UserEntity {
  email: string;
  email_verified: boolean;
  name: string;
  preferred_username: string;
  profiles: string;
  sub: string;
  tenants: string;
  isInTakePassed: boolean;
  dateOfBirth?: Date;
  firstName: string;
  lastName: string;
  phone: string;
  phoneConfirmed: boolean;
  currentProfileId: string;
  useTwoFactorAuth: boolean;
  twoFactorAuthFlow: UserTwoFactorAuthFlow;
  constructor() {
    this.isInTakePassed = false;
  }
}
