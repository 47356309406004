import {
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseNavigationItem } from '@fuse/types';
import { SplNavigationService } from 'app/core/components/navigation/navigation.service';
import { AppBusService } from 'app/core/services/app-bus.service';
import { FuseConfigService } from '@fuse/services/config.service';
import { Router } from '@angular/router';
import { AuthorizeService } from 'app/auth/authorize.service';
import { TenantService } from 'app/auth/tenant.service';

@Component({
  selector: 'spl-nav-vertical-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss'],
})
export class SplNavVerticalItemComponent implements OnInit, OnDestroy {
  @HostBinding('class')
  classes = 'nav-item';

  @Input()
  item: FuseNavigationItem;

  @Input()
  set hidden(value: boolean) {
    this.item.hidden = value;
  }

  // Private
  private _unsubscribeAll: Subject<void>;

  /**
   * Constructor
   */

  /**
   *
   * @param {ChangeDetectorRef} _changeDetectorRef
   * @param {SplNavigationService} _splNavigationService
   * @param {AppBusService} _appBusService
   * @param {FuseConfigService} _fuseConfigService
   * @param authorizeService
   * @param tenantService
   * @param {Router} _router
   */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _splNavigationService: SplNavigationService,
    private _appBusService: AppBusService,
    private _fuseConfigService: FuseConfigService,
    private authorizeService: AuthorizeService,
    private tenantService: TenantService,
    private _router: Router,
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to navigation item
    merge(
      this._splNavigationService.onNavigationItemAdded,
      this._splNavigationService.onNavigationItemUpdated,
      this._splNavigationService.onNavigationItemRemoved,
    )
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(async () => {
        await this.checkMenuItemEnables();
        // Mark for check
        this._changeDetectorRef.markForCheck();
      });
  }

  private async checkMenuItemEnables(): Promise<void> {
    const contextWrapper = await this.tenantService.getCurrentContextWrapper();
    if (!this.item.payload?.menuEnablerSettingName) return;
    this.item.hidden =
      !contextWrapper?.tenantConfig ||
      !contextWrapper.tenantConfig[this.item.payload.menuEnablerSettingName];
  }

  action(item: FuseNavigationItem): void {
    switch (item.id) {
      case 'sign_out':
        // this._appBusService
        this.authorizeService.logout();
        break;
      default:
        throw new Error(`Not implemented for item with id: ${item.id}`);
    }
  }

  menuClick(): void {
    this._appBusService.menuClick(this.item);
  }
  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
