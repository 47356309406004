import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthorizeService } from '@common/co/auth/services/authorize.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { QueryParameterNames } from '@common/co/core/app.constants';
import { LoggingService } from '@common/services/logging.service';

@Injectable({
  providedIn: 'root',
})
export class SelectProfileGuard {
  constructor(
    private _authorizeService: AuthorizeService,
    private _router: Router,
    private loggingService: LoggingService,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.handleProfile(state);
  }

  private handleProfile(state: RouterStateSnapshot): boolean {
    const hasCurrentProfile = !!this._authorizeService.currentProfile;
    const hasManyProfiles =
      (this._authorizeService.availableProfiles?.length || 0) > 1;
    this.loggingService.logTrace(`Handle Profile in SelectProfileGuard`, {
      hasCurrentProfile,
      hasManyProfiles,
      availableProfiles: JSON.stringify(
        this._authorizeService.availableProfiles,
      ),
      currentProfile: JSON.stringify(this._authorizeService.currentProfile),
    });
    if (!hasCurrentProfile) {
      this._router.navigate(['select-profile'], {
        queryParams: {
          [QueryParameterNames.ReturnUrl]: state.url,
        },
      });
    }
    return hasCurrentProfile || !hasManyProfiles;
  }
}
