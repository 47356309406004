import { InTakeFormFieldModel } from '@common/co/feature/in-take/models/in-take-form-field.model';
import { FormSectionDto } from '@common/services/co-api-client';

export class InTakeFormSectionModel {
  id: string;
  order: number;
  name: string;
  text: string;
  actionText: string;
  enabled: boolean;
  requiresNewPage: boolean;
  requiresAccept: boolean;
  firstUseOnly: boolean;
  fields: InTakeFormFieldModel[];
  active: boolean;
  canSkip: boolean;
  canPrevious: boolean;
  canBackToSignIn: boolean;

  /**
   * Constructor
   *
   * @param data
   */
  constructor(data: FormSectionDto) {
    this.id = data.id || '';
    this.order = data.order || 0;
    this.name = data.name || '';
    this.text = data.text || '';
    this.actionText = data.actionText || '';
    this.enabled = data.enabled || false;
    this.requiresNewPage = data.requiresNewPage || false;
    this.requiresAccept = data.requiresAccept || false;
    this.firstUseOnly = data.firstUseOnly || false;
    this.fields = (data.fields || []).map(
      (fieldDto) => new InTakeFormFieldModel(fieldDto),
    );
  }
}
