import { InjectionToken } from '@angular/core';

export const ERROR_401_CONFIG = new InjectionToken<IError401Config>(
  'error401Config',
  {
    providedIn: 'root',
    factory: (): IError401Config => {
      return {
        buttonTitle: 'Go to Dashboard',
      };
    },
  },
);

export interface IError401Config {
  buttonTitle: string;
}
