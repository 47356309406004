import { InjectionToken } from '@angular/core';

export const APP_INFO = new InjectionToken<any>('appInfo');

export interface IAppInfo {
  IdentityServerWebAppName: string;
  IdentityServerAndroidAppName: string;
  IdentityServerIOSAppName: string;
  AppId: string;
}
