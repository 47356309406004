import { PlatformService } from '@common/co/core/services/platform.service';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { MaterialModule } from './material.module';
import {
  HTTP_INTERCEPTORS,
  HttpClientModule,
  HttpClientXsrfModule,
} from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoggingService } from '@common/services/logging.service';
import { TimeZoneInterceptor } from '@common/interceptors/time-zone.interceptor';
import { ErrorHandlerService } from 'app/core/services/error-handler.service';
import { AppBusService } from 'app/core/services/app-bus.service';
import { NotificationService } from 'app/core/services/notification.service';
import { NotificationComponent } from 'app/core/components/notification/notification.component';
import { VersionsComponent } from 'app/core/components/versions/versions.component';
import { FuseShortcutsModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
import { RouteInterceptorService } from 'app/core/services/route-interceptor.service';
import { BreadcrumbsService } from 'app/core/services/breadcrumbs.service';
import { AccessPermissionsModule } from 'app/auth/directives/access-permissions.module';
import { SelectAvailableTenantModalComponent } from 'app/core/components/select-available-tenant-modal/select-available-tenant-modal.component';
import { ShowTextComponent } from 'app/core/components/text-modal/text-modal.component';
import { ComponentCacheService } from 'app/core/services/component-cache.service';

@NgModule({
  declarations: [
    NotificationComponent,
    VersionsComponent,
    SelectAvailableTenantModalComponent,
    ShowTextComponent,
  ],
  imports: [
    HttpClientModule,
    HttpClientXsrfModule,
    RouterModule,
    MaterialModule,
    FuseShortcutsModule,
    FuseSharedModule,
    AccessPermissionsModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    FuseShortcutsModule,
    FuseSharedModule,
  ],
  providers: [
    PlatformService,
    LoggingService,
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    AppBusService,
    NotificationService,
    RouteInterceptorService,
    {
      provide: APP_INITIALIZER,
      useFactory: initRouteInterceptor,
      deps: [RouteInterceptorService],
      multi: true,
    },
    BreadcrumbsService,
    ComponentCacheService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TimeZoneInterceptor,
      multi: true,
    },
  ],
})
export class SplCoreModule {}

export function initRouteInterceptor(
  routeInterceptorService: RouteInterceptorService,
) {
  return (): void => routeInterceptorService.initialize();
}
