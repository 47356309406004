import {
  FormFieldDto,
  FormFieldJsonData,
  FormFieldType,
} from '@common/services/co-api-client';
import { InTakeFormFieldAnswerModel } from '@common/co/feature/in-take/models/in-take-form-field-answer.model';
import * as _ from 'underscore';

export class InTakeFormFieldModel {
  id: string;
  order: number;
  caption: string;
  type: FormFieldType;
  regex: string;
  required: boolean;
  completed: boolean;
  firstDisplayAnswer?: string;
  answers: InTakeFormFieldAnswerModel[];
  extraData?: FormFieldJsonData;

  get answer(): InTakeFormFieldAnswerModel | undefined {
    const answersWithSameType = _.where(this.answers, { type: this.type });
    const orderedAnswers = _.sortBy(answersWithSameType, (a) => {
      const createdNum = a.lastModified.getTime();
      return a.locked ? createdNum * 10 : createdNum;
    });
    return _.last(orderedAnswers);
  }

  get readonly(): boolean {
    return this.answer?.locked || false;
  }

  get validateOnSave(): boolean {
    return true;
  }

  /**
   * Constructor
   *
   * @param data
   */
  constructor(data?: FormFieldDto) {
    this.id = data.id;
    this.order = data.order || 0;
    this.caption = data.caption || '';
    this.type = data.type || FormFieldType.None;
    this.regex = data.regex || '';
    this.required = data.required || false;
    this.extraData = data.extraData;
    this.answers = (data.answers || []).map(
      (answerDto) => new InTakeFormFieldAnswerModel(answerDto),
    );
    this.completed = !!this.answers;
  }
}
