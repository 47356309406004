import { Injectable, Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import {
  PermissionsClient,
  UserPermissionsListQuery,
  PermissionContextDto,
} from '@common/services/co-api-client';
import { PermissionsServiceBase } from '@common/shared/services/permissions.service';
import { PermissionContext } from '@common/shared/models/permission-context';
import { PermissionToken } from '@common/shared/models/permission-token';
import { AuthorizeService } from './authorize.service';
import { AppBusService } from '@common/co/core/services/app-bus.service';
import { PermissionTokenDto } from '@common/services/bo-api-client';

@Injectable({ providedIn: 'root' })
export class PermissionsService extends PermissionsServiceBase {
  constructor(
    private permissionsClient: PermissionsClient,
    private authorizeService: AuthorizeService,
    appBusService: AppBusService,
    route: ActivatedRoute,
    router: Router,
    injector: Injector,
  ) {
    super(route, router, injector, appBusService);
    appBusService.logout$.subscribe(() => {
      this.clearPermissionsCache();
    });
  }

  protected async getTokensWithContexts(
    tenantId: string,
    userId: string,
    contexts: PermissionContext[],
  ): Promise<PermissionTokenDto[]> {
    await this.authorizeService.getAccessToken();
    return this.permissionsClient
      .getUserPermissions(
        new UserPermissionsListQuery({
          contexts: _.map(
            contexts,
            (c) =>
              new PermissionContextDto({
                contextItemId: c.contextItemId,
                contextType: c.contextType,
              }),
          ),
        }),
      )
      .toPromise()
      .then((t) => {
        return _.map(t.tokens, (t) => t);
      });
  }

  protected async getTokens(
    tenantId: string,
    userId: string,
    contexts: PermissionContext[],
  ): Promise<PermissionToken[]> {
    await this.authorizeService.getAccessToken();
    return this.permissionsClient
      .getUserPermissions(
        new UserPermissionsListQuery({
          contexts: _.map(
            contexts,
            (c) =>
              new PermissionContextDto({
                contextItemId: c.contextItemId,
                contextType: c.contextType,
              }),
          ),
        }),
      )
      .toPromise()
      .then((t) => {
        return _.map(t.tokens, (t) => t.id as PermissionToken);
      });
  }
}
