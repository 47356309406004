import { Directive, HostListener, Input } from '@angular/core';
import { ServiceIsUnavailableService } from '@common/shared/services/service-is-unavailable.service';

@Directive({
  selector: '[displayServiceIsUnavailableMessageButton]',
})
export class DisplayServiceIsUnavailableMessageButtonDirective {
  @Input()
  public skip: boolean = false;

  constructor(
    private serviceIsUnavailableService: ServiceIsUnavailableService,
  ) {}

  @HostListener('click', ['$event']) onClick(): void {
    if (!this.skip) this.serviceIsUnavailableService.displayMessage = true;
  }
}
