import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppBusService } from 'app/core/services/app-bus.service';
import { IPayloadItem } from 'app/navigation/navigation';
import {
  RouterStateModel,
  RouterStateService,
} from '@common/shared/services/router-state.service';
import { SplNavigationService } from 'app/core/components/navigation/navigation.service';

@Injectable()
export class BreadcrumbsService {
  private _breadcrumb: string;
  private _currentUrl: string;
  private _breadcrumbTemplate: string;

  constructor(
    private _appBusService: AppBusService,
    private router: Router,
    private routerStateService: RouterStateService,
    private _splNavigationService: SplNavigationService,
  ) {
    this._appBusService.subscribeToRouteInfo((data: IPayloadItem) => {
      const newUrl = this._splNavigationService.urlForState;
      if (this._currentUrl === newUrl) return;
      this._currentUrl = newUrl;
      this.setBreadcrumbs(data);
    });
  }

  private async setBreadcrumbs(data): Promise<void> {
    const navigation = this.router.getCurrentNavigation();
    this._breadcrumb = null;
    if (data.breadcrumb) {
      this._breadcrumbTemplate = data.breadcrumb;
      if (this._breadcrumbTemplate.indexOf('<%') === -1) {
        this._breadcrumb = this._breadcrumbTemplate;
        return;
      }
      const routerState: RouterStateModel =
        await this.routerStateService.getRouterState(this._currentUrl);
      if (navigation?.extras?.state || routerState) {
        this.processState(
          this._breadcrumbTemplate,
          navigation?.extras?.state ? navigation.extras.state : routerState,
        );
      }
    }
  }

  private processState(breadcrumb, state): void {
    if (!breadcrumb || !state) {
      this._breadcrumb = null;
      return;
    }
    const keys = Object.keys(state);
    keys.forEach((key) => {
      breadcrumb = breadcrumb.replace(
        `<% ${key} %>`,
        state[key] ? state[key] : '',
      );
    });
    this._breadcrumb = breadcrumb;
  }

  public applyBreadcrumbData(state: object): void {
    if (this._breadcrumbTemplate && !this._breadcrumb) {
      this.processState(this._breadcrumbTemplate, state);
    }
  }

  get breadcrumb(): string {
    return this._breadcrumb;
  }
}
