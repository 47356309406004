import {
  ApplicationInsights,
  DistributedTracingModes,
} from '@microsoft/applicationinsights-web';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PlatformService } from '@common/co/core/services/platform.service';
import { ILoginInfo } from '@common/shared/models/login-info';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { environment } from '@common/co/environment';
import { APP_CONFIG, IAppConfig } from '@common/co/core/config/app.config';
import { APP_INFO, IAppInfo } from '@common/co/core/config/app.info';
import { LogSeverityLevel } from '@common/services/co-api-client';
import { applicationStorageFields } from '../co/core/app-storage.constants';
import _ from 'lodash';
import { StorageService } from '@common/services/storage.service';
import {
  APP_BUS_SERVICE,
  IAppBusService,
} from '@common/shared/services/iapp-bus-service';

@Injectable()
export class LoggingService {
  appInsights: ApplicationInsights;
  loginInfo: ILoginInfo;
  os: string;
  platform: string;

  private severityLevel: LogSeverityLevel = environment.verbosityLevel;
  private readonly unknownCallerName: string = 'unknown';

  setLogSeverityLevel(value: LogSeverityLevel): void {
    this.storageService.set({
      key: applicationStorageFields.STORAGE_USER_$ID$_LOG_SEVERITY_LEVEL(
        this.loginInfo.userId,
      ),
      value: _.toString(value),
    });
    this.severityLevel = value;
  }
  resetLogSeverityLevel(): void {
    this.storageService.remove({
      key: applicationStorageFields.STORAGE_USER_$ID$_LOG_SEVERITY_LEVEL(
        this.loginInfo.userId,
      ),
    });
    this.severityLevel = environment.verbosityLevel;
  }

  constructor(
    @Inject(APP_CONFIG) private appConfig: IAppConfig,
    @Inject(APP_INFO) private appInfo: IAppInfo,
    @Inject(APP_BUS_SERVICE) private appBusService: IAppBusService,
    private platformService: PlatformService,
    private storageService: StorageService,
    private router: Router,
  ) {
    const angularPlugin = new AngularPlugin();
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: this.appConfig.appInsights.instrumentationKey,
        enableAutoRouteTracking: true, // option to log all route changes
        enableCorsCorrelation: true,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
        distributedTracingMode: DistributedTracingModes.W3C,
        correlationHeaderExcludePatterns: [
          new RegExp('.*.streaming.media.azure.net'),
        ],
        extensions: [<any>angularPlugin],
        extensionConfig: {
          [angularPlugin.identifier]: { router: this.router },
        },
      },
    });
    this.appInsights.loadAppInsights();
    this.telemetryInitializer();
    this.appBusService.loginData$.subscribe((info: ILoginInfo) => {
      if (!info) {
        return;
      }
      this.loginInfo = info;
      this.appInsights.context.user.id = info.userName;
      this.appInsights.setAuthenticatedUserContext(
        info.userName,
        info.currentTenantId,
        false,
      );

      this.storageService
        .get({
          key: applicationStorageFields.STORAGE_USER_$ID$_LOG_SEVERITY_LEVEL(
            this.loginInfo.userId,
          ),
        })
        .then((logLevel) => {
          if (logLevel.value !== undefined && logLevel.value !== null) {
            this.severityLevel = _.toNumber(logLevel.value);
          }
        });
    });
  }

  public async telemetryInitializer(): Promise<void> {
    this.platform = await this.platformService.platform();
    this.os = await this.platformService.os();
    const telemetryInitializer = (envelope: any): boolean => {
      envelope.data.platform = this.platform;
      envelope.data.os = this.os;
      envelope.data.url = this.appConfig.apiUrl;
      envelope.data.app_version = this.appConfig.version;
      return true;
    };
    this.appInsights.addTelemetryInitializer(telemetryInitializer);
  }

  public createProperties(): { [key: string]: any } {
    const properties: any = {};
    properties.url = this.appConfig.apiUrl;
    properties.app_id = this.appInfo.AppId;
    properties.app_version = this.appConfig.version;
    properties.platform = this.platform;
    properties.os = this.os;
    if (!this.loginInfo) {
      return properties;
    }
    properties.userName = this.loginInfo.userName;
    properties.tenants = this.loginInfo.tenants;
    properties.profile = this.loginInfo.profile;
    return properties;
  }
  public getOperationId(): string {
    return this.appInsights.context.telemetryTrace.traceID;
  }

  logPageView(name?: string, url?: string): void {
    // option to call manually
    try {
      if (LogSeverityLevel.Metric >= this.severityLevel) {
        this.appInsights.trackPageView({
          name: name,
          uri: url,
        });
      }
    } catch (error) {
      console.log('appInsights logging error', error);
    }
  }

  logEvent(name: string, properties?: { [key: string]: any }): void {
    try {
      if (LogSeverityLevel.Information >= this.severityLevel) {
        this.appInsights.trackEvent(
          { name: name },
          Object.assign(properties || {}, this.createProperties()),
        );
      }
    } catch (error) {
      console.log('appInsights logging error', error);
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  logError(error: any): void {
    console.log(`Operation ID: ${this.getOperationId()}`);
    console.error(error);
  }

  logMetric(
    name: string,
    average: number,
    properties?: { [key: string]: any },
  ): void {
    try {
      if (LogSeverityLevel.Metric >= this.severityLevel) {
        this.appInsights.trackMetric(
          { name: name, average: average },
          Object.assign(properties || {}, this.createProperties()),
        );
      }
    } catch (error) {
      console.log('appInsights logging error', error);
    }
  }

  logException(exception: Error, severityLevel?: number): void {
    try {
      if (LogSeverityLevel.Error >= this.severityLevel) {
        this.appInsights.trackException({
          exception: exception,
          severityLevel: severityLevel,
        });
      }
      this.logError(exception);
    } catch (error) {
      console.log('appInsights logging error', error);
    }
  }

  logTrace(message: string, properties?: { [key: string]: any }): void {
    try {
      if (LogSeverityLevel.Trace >= this.severityLevel) {
        this.appInsights.trackTrace(
          { message: message },
          Object.assign(properties || {}, this.createProperties()),
        );
      }
    } catch (error) {
      console.log('appInsights logging error', error);
    }
  }

  logFunctionStart(properties?: { [key: string]: any }): void {
    try {
      const callingFunctionName = this.getCallerName();
      this.logEvent(`Function Start - ${callingFunctionName}`, properties);
    } catch (error) {
      console.log('appInsights logging error', error);
    }
  }

  logFunctionEnd(properties?: { [key: string]: any }): void {
    try {
      const callingFunctionName = this.getCallerName();
      this.logEvent(`Function End - ${callingFunctionName}`, properties);
    } catch (error) {
      console.log('appInsights logging error', error);
    }
  }

  private getCallerName(): string {
    try {
      const stackArray = new Error().stack.split('at ');
      if (stackArray.length < 4) {
        return this.unknownCallerName;
      }
      const callingPlace: string = stackArray[3].trim().split(' ')[0];
      if (callingPlace.split('.').length < 2) {
        return this.unknownCallerName;
      }
      if (callingPlace.split('.')[1] !== '<anonymous>') {
        return callingPlace;
      } else {
        for (let i = 4; i < stackArray.length; i++) {
          if (stackArray[i].indexOf(callingPlace.split('.')[0]) > -1) {
            return stackArray[i].trim().split(' ')[0];
          }
        }
      }
      return this.unknownCallerName;
    } catch (error) {
      console.log('appInsights logging error', error);
      return this.unknownCallerName;
    }
  }
}
