import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-password-input',
  templateUrl: './password-input.component.html',
  styleUrls: ['./password-input.component.scss'],
})
export class PasswordInputComponent {
  @Input() control: FormControl;
  @Input() label: string;
  @Input() labelRequired: string;
  @Input() passwordMustMatch: string;
  @Input() displayError: boolean;
  @Input() error: string | null | undefined;
  hidePassword: boolean = true;
}
