import {
  Directive,
  HostListener,
  Output,
  EventEmitter,
  Self,
} from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[timerMaskValidation]',
})
export class TimerMaskValidationDirective {
  @Output() ngModelChange: EventEmitter<any> = new EventEmitter();

  constructor(@Self() private ngControl: NgControl) {}

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  @HostListener('input', ['$event']) onInputChange(event: any): void {
    const value = event.target.value.split(':').map((part) => parseInt(part));
    const minutes = value[0];
    let seconds = value[1];

    if (!isNaN(seconds) && (seconds < 0 || seconds > 59)) {
      seconds = Math.min(Math.max(0, seconds), 59);

      const newValue = `${minutes.toString()}:${seconds.toString()}`;
      this.ngControl.control.patchValue(newValue);
    }
  }
}
