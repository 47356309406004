<mat-form-field appearance="outline">
  <mat-label>{{ label | translate }}</mat-label>
  <input matInput [type]="hidePassword ? 'password' : 'text'" [formControl]="control" />
  <mat-icon matSuffix class="secondary-text cursor-pointer" (click)="hidePassword = !hidePassword">{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
  <mat-error *ngIf="control.hasError('required')">
    {{ labelRequired | translate }} 
  </mat-error>
  <mat-error *ngIf="!control.hasError('required') && control.hasError('password')">
    {{ control.getError('password') | translate}}
  </mat-error>
  <mat-error *ngIf="
            !control.hasError('required') &&
            control.hasError('passwordsNotMatching')
          ">
    {{ passwordMustMatch | translate }}
  </mat-error>
  <mat-error *ngIf="
            !control.hasError('required') &&
            !control.hasError('passwordsNotMatching') &&
            control.hasError('backend')
          ">
    {{ control.getError('backend') }}
  </mat-error>
  <mat-error *ngIf="displayError && error">
    {{ error | translate }}
  </mat-error>
</mat-form-field>