import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { isRouteInfo } from '@common/shared/models/route-info';

export abstract class PermissionsGuardBase  {
  constructor(protected router: Router) {}
  canActivate(
    _next: ActivatedRouteSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (!_next.data || !isRouteInfo(_next.data)) {
      return true;
    }
    return this.handlePermission(_next);
  }

  protected abstract handlePermission(
    _next: ActivatedRouteSnapshot,
  ): Promise<boolean>;
}
