import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FuseConfigService } from '@fuse/services/config.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthorizeService } from 'app/auth/authorize.service';
import { NavigationTree } from 'app/navigation/navigation';
@Component({
  selector: 'server-is-unavailable',
  templateUrl: './server-is-unavailable.component.html',
  styleUrls: ['./server-is-unavailable.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ServerIsUnavailableComponent implements OnInit {
  private returnUrl: string;
  public displayGoToLogin: boolean;

  constructor(
    private _fuseConfigService: FuseConfigService,
    private route: ActivatedRoute,
    private router: Router,
    private authorize: AuthorizeService,
  ) {
    this._fuseConfigService.hideFuseLayout();
  }

  async ngOnInit(): Promise<void> {
    this.route.queryParams.subscribe((params) => {
      this.returnUrl =
        params.returnUrl && params.returnUrl !== this.router.url?.split('?')[0]
          ? params.returnUrl
          : '/';
      this.displayGoToLogin = this.returnUrl !== NavigationTree.login.url;
    });
  }

  public action(): void {
    window.location.href = this.returnUrl;
  }

  public logout(): void {
    this.authorize.logout();
  }
}
