import { Directive, ElementRef, HostListener, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import _ from 'lodash';

@Directive({
  selector: '[ipv4]',
})
export class Ipv4Directive {
  constructor(@Self() private ngControl: NgControl, private _el: ElementRef) {}

  @HostListener('input', ['$event'])
  public onInput(): void {
    this.ngControl.control.patchValue(
      this.ngControl.value.replace(/(^\.+)|([^.0-9]*)/g, ''),
    );

    this.ngControl.control.patchValue(
      this.ngControl.value.replace(/\.+/g, '.'),
    );

    let ipArray = this.ngControl.value.split('.');

    if (ipArray.length > 4) {
      ipArray = ipArray.slice(0, 4);
    }

    const ip = ipArray.reduce(
      function (value, currentValue, index): string {
        if (Number(currentValue) > 255 || currentValue.length > 3) {
          return `${value}${index > 0 ? '.' : ''}${255}`;
        }
        return `${value}${index > 0 ? '.' : ''}${this.getCorrectIPv4Value(
          currentValue,
        )}`;
      }.bind(this),
      '',
    );
    this.ngControl.control.patchValue(ip);
  }

  private getCorrectIPv4Value(currentValue: string): string {
    if (_.isEmpty(currentValue)) return '';
    if (currentValue.startsWith('0', 0)) {
      return Number(currentValue).toString();
    }
    return currentValue;
  }
}
