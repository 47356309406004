type ApplicationStorageFieldsType = {
  readonly STORAGE_INSTALLATION_ID: string;
  readonly STORAGE_OIDC_CONFIG: string;
  readonly STORAGE_USER_INFO: string;
  readonly STORAGE_AVAILABLE_PROFILES: string;
  readonly STORAGE_USER_NOT_COMPLETED: string;
  readonly STORAGE_ACCESS_TOKEN: string;
  readonly STORAGE_REFRESH_TOKEN: string;
  readonly STORAGE_ID_TOKEN: string;
  readonly STORAGE_EXPIRES_IN: string;
  readonly STORAGE_ISSUED_AT: string;
  readonly STORAGE_LOGGED_BY_PROVIDER: string;
  readonly STORAGE_NONCE: string;
  readonly STORAGE_PKCE_CHALLENGE: string;
  readonly STORAGE_PKCE_METHOD: string;
  readonly STORAGE_PKCE_VERIFIER: string;
  readonly STORAGE_USER_$ID$_LOG_SEVERITY_LEVEL: (userId: string) => string;
};

export const applicationStorageFields: ApplicationStorageFieldsType = {
  STORAGE_INSTALLATION_ID: 'storage-installation-id',
  STORAGE_OIDC_CONFIG: 'oidc-config',
  STORAGE_USER_INFO: 'user-info',
  STORAGE_AVAILABLE_PROFILES: 'available-profiles',
  STORAGE_USER_NOT_COMPLETED: 'user-not-completed',
  STORAGE_ACCESS_TOKEN: 'oidc-access-token',
  STORAGE_REFRESH_TOKEN: 'oidc-refresh-token',
  STORAGE_ID_TOKEN: 'oidc-id-token',
  STORAGE_EXPIRES_IN: 'oidc-expires-in',
  STORAGE_ISSUED_AT: 'oidc-issued-at',
  STORAGE_LOGGED_BY_PROVIDER: 'oidc-logged-by-provider',
  STORAGE_NONCE: 'oidc-nonce',
  STORAGE_PKCE_CHALLENGE: 'oidc-pkce-challenge',
  STORAGE_PKCE_METHOD: 'oidc-pkce-method',
  STORAGE_PKCE_VERIFIER: 'oidc-pkce-verifier',
  STORAGE_USER_$ID$_LOG_SEVERITY_LEVEL: (userId: string) =>
    `storage-user-${userId}-log-severity-level`,
};
