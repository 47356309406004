import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const TECHNICAL_CLIENT = new InjectionToken<ITechnicalClientService>(
  'TECHNICAL_CLIENT',
);

export interface ITechnicalClientService {
  index(): Observable<string>;
}
