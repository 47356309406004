import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthorizeService } from '@common/co/auth/services/authorize.service';
import { APPLICATIONS_PATHS, IApplicationPathsType } from '@common/co/core/app.constants';

@Injectable({
  providedIn: 'root',
})
export class CompletedRegistrationGuard  {
  constructor(@Inject(APPLICATIONS_PATHS) private applicationPaths: IApplicationPathsType,
              private authorize: AuthorizeService, private router: Router) { }
  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authorize.IsUserRegistrationNotCompleted) {
      this.router.navigate(this.applicationPaths.RegisterPathComponents);
    }
    return Promise.resolve(!this.authorize.IsUserRegistrationNotCompleted);
  }
}
