import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  Renderer2,
} from '@angular/core';
import { PermissionsInfo } from '@common/shared/models/permission-info';
import { PermissionToken } from '@common/shared/models/permission-token';
import { PermissionsService } from 'app/auth/permissions.service';
import { ContextLevelType } from '@common/services/bo-api-client';
import { TenantService } from 'app/auth/tenant.service';

@Directive({
  selector: '[readonlyChildrenElementsByPermissions]',
})
export class ReadonlyChildrenElementsByPermissionsDirective
  implements AfterViewInit
{
  @Input() readonlyChildrenElementsByPermissions: PermissionToken[];
  @Input() contextLevelType: ContextLevelType;

  private elementRef: ElementRef;
  private permissionsInfo: PermissionsInfo;

  constructor(
    elementRef: ElementRef,
    private _renderer: Renderer2,
    private permissionsService: PermissionsService,
    private tenantService: TenantService,
  ) {
    this.elementRef = elementRef;
  }

  async ngAfterViewInit(): Promise<void> {
    if (!this.readonlyChildrenElementsByPermissions) return;
    if (Array.isArray(this.readonlyChildrenElementsByPermissions)) {
      this.permissionsInfo = new PermissionsInfo(
        this.readonlyChildrenElementsByPermissions,
      );
    } else {
      this.permissionsInfo = this.readonlyChildrenElementsByPermissions;
    }
    let access = await this.permissionsService.getPermissionAccess(
      this.permissionsInfo,
    );
    if (access && this.contextLevelType) {
      const currentContextLevel =
        await this.tenantService.getCurrentContextLevel();
      access =
        currentContextLevel.context.contextType === this.contextLevelType;
    }
    if (!access) {
      const elements = this.elementRef.nativeElement.querySelectorAll(
        '[permission-target]',
      );
      [...elements].forEach((el) => {
        this._renderer.addClass(el, 'custom-readonly');
      });
    }
  }
}
