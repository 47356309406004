import { Component, OnInit } from '@angular/core';
import { ServiceIsUnavailableService } from '@common/shared/services/service-is-unavailable.service';
import { AuthorizeService } from 'app/auth/authorize.service';
import { Router } from '@angular/router';
import { NavigationTree } from 'app/navigation/navigation';

@Component({
  selector: 'service-is-unavailable-message',
  templateUrl: './service-is-unavailable-message.component.html',
  styleUrls: ['./service-is-unavailable-message.component.scss'],
})
export class ServiceIsUnavailableMessageComponent implements OnInit {
  public displayGoToLoginButton: boolean = false;

  constructor(
    public serviceIsUnavailableService: ServiceIsUnavailableService,
    private authService: AuthorizeService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    if (this.serviceIsUnavailableService.messageIsDisplayed) {
      this.displayGoToLoginButton =
        this.router.url !== NavigationTree.login.url;
    }
  }

  public logout(): void {
    this.authService.logout();
    this.serviceIsUnavailableService.hideMessage(false);
  }
}
