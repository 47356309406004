import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { FuseSharedModule } from '@fuse/shared.module';
import { IpRestrictionErrorPageComponent } from 'app/error-screens/ip-restriction-error-page/ip-restriction-error-page.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { PermissionsRestrictionErrorPageComponent } from 'app/error-screens/permissions-restriction-error-page/permissions-restriction-error-page.component';
import { ServerIsUnavailableComponent } from 'app/error-screens/server-is-unavailable/server-is-unavailable.component';
import { ErrorScreensRoutes } from 'app/error-screens/error-screens.routes';

@NgModule({
  declarations: [
    IpRestrictionErrorPageComponent,
    PermissionsRestrictionErrorPageComponent,
    ServerIsUnavailableComponent,
  ],
  imports: [
    ErrorScreensRoutes,
    MatIconModule,
    FuseSharedModule,
    MatButtonModule,
  ],
})
export class ErrorScreensModule {}
