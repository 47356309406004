<mat-toolbar class="p-0">
  <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">
    <div fxLayout="row" fxLayoutAlign="start center">
      <button mat-icon-button class="quick-panel-toggle-button" (click)="toggleSidebarOpen('navbar')"
              aria-label="Toggle quick panel">
        <mat-icon>menu</mat-icon>
      </button>
    </div>

    <div fxFlex="1 1 auto" class="breadcrumbs">
      <div class="back-button" (click)="splNavigationService.goBack()" *ngIf="splNavigationService.availableBackButton">
        <mat-icon>keyboard_arrow_left</mat-icon> Back
      </div>
      <span *ngIf="breadcrumbService.breadcrumb" class="current-breadcrumb">{{ breadcrumbService.breadcrumb }}</span>
    </div>

    <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">

      <div
        class="toolbar-separator"
        *ngIf="!hiddenNavbar && !rightNavbar"
        fxHide.gt-md
      ></div>

      <div fxLayout="row" fxLayoutAlign="start center" *ngIf="horizontalNavbar">
        <div class="logo ml-16">
          <img class="logo-icon" src="assets/images/logos/fuse.svg" alt="fuse-logo" />
        </div>
      </div>
    </div>

    <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">
      <spl-context-selector class="context-selector"></spl-context-selector>
    </div>
  </div>
</mat-toolbar>
