import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StandardHeaders } from '@common/services/co-api-client';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TimeZoneInterceptor implements HttpInterceptor {
  private readonly standardHeaders = new StandardHeaders();
  private offset: number = new Date().getTimezoneOffset();

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    req = req.clone({
      headers: this.modifyHeaders(req),
    });
    return next.handle(req);
  }

  private modifyHeaders(req: HttpRequest<any>): HttpHeaders {
    return req.headers.append(
      this.standardHeaders.timeZoneOffsetInMinutes,
      this.offset.toString(),
    );
  }
}
