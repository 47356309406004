import { Requestor } from '@openid/appauth';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

export interface XhrSettings {
  url: string;
  dataType: string;
  method: 'GET' | 'POST';
  data: any;
  headers: any; // {key : string, value: any}
}

@Injectable()
export class AngularRequestor extends Requestor {
  constructor(private httpClient: HttpClient) {
    super();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public async xhr<T>(settings: any): Promise<T> {
    // JQueryAjaxSettings causes reference issues
    console.debug('AngularRequestor => xhr', settings);

    const { url, dataType, method, data } = settings;

    let response: Promise<T>;

    const options = {
      responseType: dataType as any,
      headers: settings.headers,
    };

    if (method === 'PUT') {
      response = this.httpClient.put<T>(url, data, options).toPromise();
    } else if (method === 'POST') {
      response = this.httpClient.post<T>(url, data, options).toPromise();
    } else {
      response = this.httpClient.get<T>(url, options).toPromise();
    }

    return response
      .then((resp) => {
        console.debug('AngularRequestor => xhr => result', resp);
        return resp;
      })
      .catch((err) => {
        console.error('AngularRequestor => xhr => error', err);
        return {} as T;
      });
  }
}
