import { Injectable } from '@angular/core';
import {
  Subject,
  BehaviorSubject,
  ReplaySubject,
  Subscription,
  Observable,
  combineLatest,
} from 'rxjs';
import { IPayloadItem } from '@common/co/navigation/navigation';
import { ILoginInfo } from '@common/shared/models/login-info';
import { IAppBusService } from '@common/shared/services/iapp-bus-service';
import { SplNotification } from '@common/co/core/models/splNotification.model';
import { TrainingState } from '@common/co/shared/models/training-state.model';
import {
  AppSettingsResponse,
  AthleteDto,
} from '@common/services/co-api-client';
import { FuseNavigationItem } from '@fuse/types';
import { ContextLevel } from '@common/shared/models/permission-context';
import { first } from 'rxjs/operators';

@Injectable()
export class AppBusService implements IAppBusService {
  public notificationSubject = new Subject();
  public validationNotificationSubject = new Subject();
  public clearValidationMessageSubject = new Subject();
  public loginData$ = new ReplaySubject<ILoginInfo>(1);
  public login$ = new ReplaySubject<void>(1);
  public logout$: Subject<void> = new Subject();
  public menuClick$ = new Subject<FuseNavigationItem>();
  public logoutRequested$ = new Subject();
  private routeInfoChanged$ = new ReplaySubject(1);
  public inTakePassed$ = new BehaviorSubject<boolean>(null);
  public isActive = new BehaviorSubject<boolean>(true);
  public scheduleChanged$ = new BehaviorSubject<boolean>(false);
  public setProfile$ = new ReplaySubject<AthleteDto>();
  public trainingStateChanged$ = new Subject<TrainingState>();
  public appSettingsChanged$ = new ReplaySubject<AppSettingsResponse | null>(1);
  public dateOfLastTest$ = new BehaviorSubject<Date | null>(null);
  public displaySkeleton: Subject<{ state: boolean }> = new Subject<{
    state: boolean;
  }>();
  public contextLevelChanged$ = new ReplaySubject<ContextLevel>(1);
  public checkedPermissions$: Subject<boolean> = new Subject<boolean>();
  public actualizeProfileInfo$: Subject<void> = new Subject();

  public unregisteredPush$: Subject<void> = new Subject();
  public logoutCompleted$(): Observable<[void]> {
    return combineLatest([this.unregisteredPush$.asObservable()]).pipe(first());
  }

  public loginData(info: ILoginInfo): void {
    this.loginData$.next(info);
  }
  public login(): void {
    this.login$.next();
  }

  public logout(): void {
    this.logout$.next();
    this.setAppSettings(null);
  }
  public logoutRequested(): void {
    this.logoutRequested$.next();
  }

  public inTakePassed(value: boolean): void {
    console.log('in take passed', value);
    this.inTakePassed$.next(value);
  }

  public processNotification(notification: SplNotification): void {
    console.log(notification);
    this.notificationSubject.next(notification);
  }

  public processValidationMessage(notification: SplNotification): void {
    this.validationNotificationSubject.next(notification);
  }

  public clearValidationMessage(): void {
    this.clearValidationMessageSubject.next();
  }

  public changeRouteInfo(data: IPayloadItem): void {
    this.routeInfoChanged$.next(data);
  }

  public subscribeToRouteInfo(
    callback: (data: IPayloadItem) => void,
  ): Subscription {
    return this.routeInfoChanged$.subscribe(callback);
  }

  public setIsActive(value: boolean): void {
    this.isActive.next(value);
  }

  public eventScheduleChanged(): void {
    this.scheduleChanged$.next(true);
  }

  public setProfile(profile: AthleteDto): void {
    this.setProfile$.next(profile);
  }

  public setTrainingState(value: TrainingState): void {
    this.trainingStateChanged$.next(value);
  }

  public setAppSettings(value: AppSettingsResponse): void {
    this.appSettingsChanged$.next(value);
  }

  public setDateOfLastTest(value: Date): void {
    this.dateOfLastTest$.next(value);
  }

  public checkedPermissions(value: boolean): void {
    this.checkedPermissions$.next(value);
  }

  public unregisteredPush(): void {
    this.unregisteredPush$.next();
  }
}
