<div id="ip-restriction" fxLayout="column" fxLayoutAlign="center center">
  <div class="content" fxLayout="column" fxLayoutAlign="center center">
    <div class="logo">
      <mat-icon>vpn_lock</mat-icon>
    </div>

    <div class="message" *ngIf="ipAddress">
      Your IP address {{ ipAddress }} is outside of the range of acceptable addresses. 
      Please contact your system administrator.
    </div>
    
    <div class="buttons-wrapper">
      <button mat-button class="spl-button mt-28" (click)="logout()">Log out</button>
      <button *ngIf="displayHomepageButton" mat-button class="spl-button mt-28" (click)="goToHomepage()">Home page</button>
    </div>
  </div>
</div>
