import { InjectionToken } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { ILoginInfo } from '@common/shared/models/login-info';
import { FuseNavigationItem } from '@fuse/types';
import { ContextLevel } from '@common/shared/models/permission-context';

export const APP_BUS_SERVICE = new InjectionToken<IAppBusService>(
  'APP_BUS_SERVICE',
);

export interface IAppBusService {
  loginData$: ReplaySubject<ILoginInfo>;
  menuClick$: Subject<FuseNavigationItem>;
  contextLevelChanged$: ReplaySubject<ContextLevel>;
  logout$: Subject<void>;
  checkedPermissions?(value: boolean): void;
}
