<div
  class="navbar-top"
  [ngClass]="fuseConfig.layout.navbar.secondaryBackground"
>
<!--  <div class="logo">-->
<!--    <img class="logo-icon" src="assets/images/logos/spl.png" />-->
<!--    <span class="logo-text secondary-text">Sports Performance Lab</span>-->
<!--  </div>-->
  
  <div class="buttons" fxFlexFill fxLayoutAlign="end center">
    <button
      mat-icon-button
      class="toggle-sidebar-opened"
      (click)="toggleSidebarOpened()"
    >
      <mat-icon class="spl-red-color">close</mat-icon>
    </button>
  </div>
</div>

<div
  class="navbar-scroll-container"
  [ngClass]="fuseConfig.layout.navbar.primaryBackground"
  fusePerfectScrollbar
  [fusePerfectScrollbarOptions]="{ suppressScrollX: true }"
>
  <div class="navbar-content">
    <spl-navigation class="material2" layout="vertical"></spl-navigation>
  </div>
</div>
