import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OverlayLoaderComponent } from './overlay-loader.component';
import { MaterialModule } from '@common/co/core/material.module';
import { OverlayLoaderService } from '@common/shared/components/overlay-loader/overlay-loader.service';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';

@NgModule({
  imports: [CommonModule, MaterialModule, OverlayModule, MatProgressBarModule],
  declarations: [OverlayLoaderComponent],
  exports: [OverlayLoaderComponent],
  providers: [OverlayLoaderService]
})
export class OverlayLoaderModule {}
