import { Injectable } from '@angular/core';

import { Preferences } from '@capacitor/preferences';

@Injectable({ providedIn: 'root' })
export class StorageService {

  public async set(options: { key: string, value: string }): Promise<{ value: boolean }> {
    try {
      await Preferences.set(options);
      return { value: true };
    } catch {
      return { value: undefined };
    }
  }

  public async get(options: { key: string }): Promise<{ value: string }> {
    try {
      return await Preferences.get(options);
    } catch {
      return { value: undefined };
    }
  }

  public async remove(options: { key: string }): Promise<{ value: boolean }> {
    try {
      await Preferences.remove(options);
      return { value: true };
    } catch {
      return { value: false };
    }
  }

  public async clear(): Promise<{ value: boolean }> {
    try {
      await Preferences.clear();
      return { value: true };
    } catch {
      return { value: false };
    }
  }
}
