import { InjectionToken } from '@angular/core';

export const APPLICATION_DICTIONARY_SERVICE =
  new InjectionToken<IApplicationDictionaryService>(
    'APPLICATION_DICTIONARY_SERVICE',
  );

export interface IApplicationDictionaryService {
  Training: string;
  getTextByTemplate(template: string, token: string, value: string): string;
}
