import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { appConfig } from 'app/core/config/app.config';
import { TechnicalClient } from '@common/services/bo-api-client';
import { getSplNotification } from 'app/core/helpers/spl-notification';
import { NotificationService } from 'app/core/services/notification.service';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'versions',
  templateUrl: './versions.component.html',
  styleUrls: ['./versions.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class VersionsComponent implements OnInit {
  APIVersion: string;

  constructor(
    private _technicalClient: TechnicalClient,
    private _notificationService: NotificationService,
    private clipboard: Clipboard,
  ) {}
  appConfig = appConfig;

  ngOnInit(): void {
    this.APIVersion = undefined;
    this._technicalClient.getVersion().subscribe((res) => {
      this.APIVersion = res.version;
    });
  }

  public copyVersionsToClipboard(): void {
    this.clipboard.copy(
      `app-bo ${this.appConfig.version}; api-bo ${this.APIVersion};`,
    );
    this._notificationService.generateNotification(
      getSplNotification('info', 'Copied!', 2000),
    );
  }
}
