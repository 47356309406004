import { Injectable } from '@angular/core';
import { OidcConfigurationClient } from '@common/services/bo-api-client';
import { Observable } from 'rxjs';
import { IoidcConfigurationClientService } from '@common/services/ioidc-configuration-client-service';

@Injectable({
  providedIn: 'root',
})
export class BoOidcConfigurationClientService
  implements IoidcConfigurationClientService
{
  constructor(private _oidcConfigurationClient: OidcConfigurationClient) {}

  public getOidcConfiguration(
    oidcAppId: string,
  ): Observable<{ [key: string]: string }> {
    return this._oidcConfigurationClient.getClientRequestParameters(oidcAppId);
  }
}
