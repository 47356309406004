import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { Action, MetaReducer, StoreModule } from '@ngrx/store';
import { NgModule } from '@angular/core';
import { storeFreeze } from 'ngrx-store-freeze';
import {
  RouterReducerState,
  RouterStateSerializer,
  StoreRouterConnectingModule,
} from '@ngrx/router-store';

import { reducers, effects, CustomSerializer, RouterStateUrl } from 'app/store';
import { appConfig } from 'app/core/config/app.config';

export const metaReducers: MetaReducer<{ routerReducer: RouterReducerState<RouterStateUrl>; }, Action>[] = !appConfig.isProduction
  ? [storeFreeze]
  : [];

@NgModule({
  imports: [
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot(effects),
    !appConfig.isProduction ? StoreDevtoolsModule.instrument() : [],
    StoreRouterConnectingModule.forRoot(),
  ],
  providers: [
    {
      provide: RouterStateSerializer,
      useClass: CustomSerializer,
    },
  ],
})
export class AppStoreModule {}
