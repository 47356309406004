import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input, OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SplNavigationService } from 'app/core/components/navigation/navigation.service';
import { navigation } from 'app/navigation/navigation';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { AppBusService } from 'app/core/services/app-bus.service';
import _ from 'lodash';
import { FuseNavigation } from '@fuse/types';
import { isAnyChildMenuItem } from '@fuse/types';

@Component({
  selector: 'spl-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SplNavigationComponent implements OnInit, OnDestroy {
  @Input()
  layout = 'vertical';

  @Input()
  navigation: FuseNavigation[];

  info: string;
  isAnyChildMenuItem = isAnyChildMenuItem;

  // Private
  private _unsubscribeAll: Subject<void>;

  /**
   *
   * @param {ChangeDetectorRef} _changeDetectorRef
   * @param {SplNavigationService} _splNavigationService
   * @param {AuthorizeService} _authorizeService
   * @param {FuseProgressBarService} _fuseProgressBar
   * @param {AppBusService} _appBusService
   */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _splNavigationService: SplNavigationService,
    private _fuseProgressBar: FuseProgressBarService,
    private _appBusService: AppBusService,
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this._appBusService.contextLevelChanged$.subscribe(() => {
      this.navigation = null;
      this._changeDetectorRef.markForCheck();
    });
    this._appBusService.changePermissions$.subscribe(() => {
      this.navigation = _.cloneDeep(navigation);
      this._changeDetectorRef.markForCheck();
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Load the navigation either from the input or from the service

    // Subscribe to the current navigation changes
    this._splNavigationService.onNavigationChanged
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        // Load the navigation

        // Mark for check
        this._changeDetectorRef.markForCheck();
      });

    // Subscribe to navigation item
    merge(
      this._splNavigationService.onNavigationItemAdded,
      this._splNavigationService.onNavigationItemUpdated,
      this._splNavigationService.onNavigationItemRemoved,
    )
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        // Mark for check
        this._changeDetectorRef.markForCheck();
      });

    this._appBusService.logout$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this._fuseProgressBar.hide();
      });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
