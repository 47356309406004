<div class="spl-context-selector">
  <kendo-dropdowntree
    #dropDownTree
    kendoDropDownTreeExpandable
    [kendoDropDownTreeHierarchyBinding]="data"
    childrenField="items"
    textField="name"
    valueField="id"
    [listHeight]="400"
    [clearButton]="false"
    [disabled]="disableSwitch"
    [value]="selected"
    (valueChange)="valueChange($event)"
    (nodeExpand)="handleExpand($event)"
    (nodeCollapse)="handleCollapse($event)"
    [isNodeExpanded]="isNodeExpanded"
    [filterable]="true" >
    <ng-template kendoDropDownTreeValueTemplate let-dataItem>
      {{ dataItem?.pathName }}
    </ng-template>

    <ng-template kendoDropDownTreeNodeTemplate let-dataItem>
      <span class="node-template-wrapper">
        <span [ngClass]="{'kendo-dropdowntree-item-disable': dataItem?.isDisabled}">{{ dataItem?.name }}</span>
        <mat-icon *ngIf="dataItem?.isDisabled" class="s-14" matTooltip="Not available for current screen">error</mat-icon>
      </span>
    </ng-template>
  </kendo-dropdowntree>
</div>
<!-- [hasChildren]="hasChildren"-->
