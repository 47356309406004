import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import { interval, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import moment from 'moment/moment';
import { UserTwoFactorAuthFlow } from '@common/services/bo-api-client';

@Component({
  selector: 'code-entry',
  templateUrl: './code-entry.component.html',
  styleUrls: ['./code-entry.component.scss'],
})
export class CodeEntryComponent {
  @Input()
  public sentTo: string;
  @Input()
  public isSendAvailable: boolean;
  @Input()
  public isConfirmDisable: boolean;
  @Input()
  public lastSendAttemptAt: Date;
  @Input()
  public set nextSendAvailableAt(value: Date) {
    this.setCountdown(value);
  }
  @Input()
  public set commonError(value: string) {
    this.codeControl.setErrors({
      commonError: value,
    });
    this.codeValidChanged.emit(this.codeControl.valid);
  }
  @Input()
  public set validationError(value: string) {
    this.codeControl.setErrors({
      wrongCode: value,
    });
    this.codeValidChanged.emit(this.codeControl.valid);
  }
  @Input()
  public process: boolean = false;
  @Input()
  public minCodeLength: number = 1;
  @Input()
  public maxCodeLength: number = 6;

  @Output()
  public codeValidChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  public codeChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  public countdownFinished: EventEmitter<any> = new EventEmitter();
  @Output()
  public codeRequested: EventEmitter<any> = new EventEmitter();

  public readonly UserTwoFactorAuthFlow = UserTwoFactorAuthFlow;
  public displayKeyboard: boolean = false;
  public codeControl: FormControl<string> = new FormControl<string>(
    '',
    Validators.required,
  );
  public countdownTime: { minutes: number; seconds: number };
  public countDownIntervalSubject: Subject<any>;
  private countDownInterval = undefined;

  private setCountdown(nextSendAvailableAt: Date): void {
    if (!nextSendAvailableAt || this.isSendAvailable) return;

    const currDate = moment();
    const eventDate = moment(nextSendAvailableAt);
    let diff = eventDate.diff(currDate, 'seconds') + 1;

    this.countdownTime = this._secondsToRemaining(diff);

    // Create a subscribable interval
    if (this.countDownIntervalSubject) {
      this.countDownInterval = undefined;
      this.countDownIntervalSubject.unsubscribe();
    }
    this.countDownInterval = interval(1000).pipe(
      map(() => {
        return (diff = diff - 1);
      }),
      map((value) => {
        return this._secondsToRemaining(value);
      }),
    );

    // Subscribe to the countdown interval
    this.countDownIntervalSubject = this.countDownInterval.subscribe(
      (value) => {
        this.countdownTime = value;

        if (!this.countdownTime) {
          this.stopCountdown();
        }
      },
    );
  }

  private stopCountdown(): void {
    this.countDownInterval = undefined;
    if (this.countDownIntervalSubject) {
      this.countDownIntervalSubject.unsubscribe();
    }
    this.countdownFinished.emit();
  }

  private _secondsToRemaining(seconds): { minutes: number; seconds: number } {
    const timeLeft = moment.duration(seconds, 'seconds');

    if (seconds > 0) {
      return {
        minutes: Math.floor(timeLeft.minutes()),
        seconds: Math.floor(timeLeft.seconds()),
      };
    } else {
      return undefined;
    }
  }

  public enter(number: number): void {
    if (this.codeControl.value.length < this.maxCodeLength) {
      this.setCode(`${this.codeControl.value}${number}`);
    }
  }

  public clear(): void {
    this.setCode('');
  }

  public delete(): void {
    if (this.codeControl.value.length > 0) {
      this.setCode(this.codeControl.value.slice(0, -1));
    }
  }

  private setCode(value: string): void {
    this.codeControl.setValue(value);
    this.codeChanged.emit(value);
    this.codeValidChanged.emit(this.codeControl.valid);
  }
}
