<ng-container *ngIf="!item.hidden">
  <div class="group-title" [ngClass]="item.classes">
    <span class="hint-text" [translate]="item.translate">{{
      (item.translate | translate) || item.title
    }}</span>
  </div>

  <div class="group-items">
    <ng-container *ngFor="let item of item.children">
      <spl-nav-vertical-group
        *ngIf="item.type == 'group'"
        [item]="item"
      ></spl-nav-vertical-group>
      <spl-nav-vertical-collapsable
        *ngIf="item.type == 'collapsable'"
        [item]="item"
      ></spl-nav-vertical-collapsable>
      <spl-nav-vertical-item
        *ngIf="item.type == 'item'"
        [item]="item"
      ></spl-nav-vertical-item>
    </ng-container>
  </div>
</ng-container>
