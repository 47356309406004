import { PermissionToken } from './permission-token';

export interface IRouteInfo {
  tokens?: PermissionToken[];
  componentId?: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function isRouteInfo(source: any): source is IRouteInfo {
  return !!source.tokens;
}
