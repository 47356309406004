import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import {
  AuthClient,
  ChangeUserPhoneNumberCommand,
  ProcessResult,
  ProcessResultType,
  SendPhoneNumberConfirmationTokenCommand,
  StatePhoneNumberConfirmationResponse,
} from '@common/services/bo-api-client';

@Component({
  selector: 'phone-number-confirmation-dialog',
  templateUrl: './phone-number-confirmation-dialog.component.html',
  styleUrls: ['./phone-number-confirmation-dialog.component.scss'],
})
export class PhoneConfirmationDialogComponent implements OnInit {
  public code: string;
  public isCodeValid: boolean;
  public commonError: string;
  public validationError: string;

  public processing: boolean = false;
  public state: StatePhoneNumberConfirmationResponse;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: { phoneNumber: string },
    public dialogRef: MatDialogRef<PhoneConfirmationDialogComponent>,
    public authClient: AuthClient,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.getState(true);
  }

  private async getState(initialSendCode: boolean = false): Promise<void> {
    this.state = await this.authClient
      .getStatePhoneNumberConfirmation()
      .toPromise();

    if (initialSendCode && this.state.isSendAvailable) {
      await this.requestNewCode();
    }
  }

  public async countdownFinished(): Promise<void> {
    this.processing = true;
    await this.getState();
    this.processing = false;
  }

  public async requestNewCode(): Promise<void> {
    this.resetErrors();

    const res = await this.authClient
      .sendPhoneNumberConfirmationToken(
        new SendPhoneNumberConfirmationTokenCommand(),
      )
      .toPromise();

    if (!res.result.success) {
      this.setErrors(res.result);
      return;
    }

    await this.getState();
  }

  public async confirmPhoneNumber(): Promise<void> {
    this.processing = true;
    this.resetErrors();
    const res = await this.authClient
      .changeUserPhoneNumber(
        new ChangeUserPhoneNumberCommand({
          phoneNumber: this.dialogData.phoneNumber,
          phoneNumberConfirmationToken: this.code,
        }),
      )
      .toPromise();

    this.processing = false;
    if (!res.result.success) {
      this.setErrors(res.result);
      return;
    }
    this.dialogRef.close();
  }

  private setErrors(processResult: ProcessResult): void {
    if (processResult.success) return;
    if (processResult.type === ProcessResultType.ValidationError) {
      this.validationError = processResult.message;
    } else {
      this.commonError = processResult.message;
    }
  }

  private resetErrors(): void {
    this.validationError = undefined;
    this.commonError = undefined;
  }
}
