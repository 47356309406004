import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';

import { FuseNavigationModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';

import { NavbarVerticalStyle1Component } from 'app/layout/bo-app-layout/components/navbar/vertical/style-1/style-1.component';
import { NavigationModule } from 'app/core/components/navigation/navigation.module';

@NgModule({
  declarations: [NavbarVerticalStyle1Component],
  imports: [
    MatButtonModule,
    MatIconModule,

    FuseSharedModule,
    FuseNavigationModule,
    NavigationModule,
  ],
  exports: [NavbarVerticalStyle1Component],
})
export class NavbarVerticalStyle1Module {}
