import { Inject, Injectable } from '@angular/core';
import { environment } from '@common/co/environment';
import { nameofFactory } from '@common/helpers/nameof-helper';
import {
  ICOHubBEMethods,
  ICOHubFEMethods,
  LogSeverityLevel,
} from '@common/services/co-api-client';
import * as signalr from '@microsoft/signalr';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, pairwise, startWith } from 'rxjs/internal/operators';
import { APP_CONFIG, IAppConfig } from '../co/core/config/app.config';
import { LoggingService } from './logging.service';
import {
  APP_BUS_SERVICE,
  IAppBusService,
} from '@common/shared/services/iapp-bus-service';
import { AUTH_SERVICE, IAuthService } from '@common/services/iauth-service';

@Injectable({
  providedIn: 'root',
})
export class SignalrService {
  private hubUrl: string;
  private userId: string = null;
  private accessToken: string = null;
  private hubConnection: signalr.HubConnection;

  private nameof = nameofFactory<ICOHubFEMethods>();

  private get isConnected(): boolean {
    return (
      this.hubConnection &&
      this.hubConnection.state === signalr.HubConnectionState.Connected
    );
  }

  private connectedSub: BehaviorSubject<boolean>;
  private connected: Observable<{ prev: boolean; curr: boolean }>;

  constructor(
    @Inject(APP_CONFIG) private appConfig: IAppConfig,
    @Inject(APP_BUS_SERVICE) private appBusService: IAppBusService,
    @Inject(AUTH_SERVICE) private authService: IAuthService,
    private loggingService: LoggingService,
  ) {
    this.hubUrl = `${environment.apiUrl}/${appConfig.signalrHubUrl}`;
    this.connectedSub = new BehaviorSubject<boolean>(false);
    this.connected = this.connectedSub.pipe(
      startWith(false),
      pairwise(),
      map(([prev, curr]) => ({ prev, curr })),
    );
    this.connected.subscribe((isConnected) => {
      if (isConnected.prev === false && isConnected.curr === true) {
        for (const listener of this.defaultListeners) {
          listener();
        }
      }
    });
  }

  public initialize(): void {
    console.log('Initialize SignalR service...');
    this.appBusService.loginData$.subscribe((loginInfo) => {
      if (this.userId && this.userId === loginInfo.userId) {
        return;
      } else {
        this.userId = loginInfo?.userId;
      }

      this.authService.getAccessToken().then((token) => {
        this.accessToken = token?.accessToken;
        this.startConnection();
      });
    });
    this.appBusService.logout$.subscribe(() => {
      this.stopConnection();
      this.accessToken = null;
      this.userId = null;
    });
  }

  public defineListener(
    methodName: string,
    method: (...args: any[]) => void,
  ): void {
    this.connected.subscribe((isConnected) => {
      if (isConnected.prev === false && isConnected.curr === true) {
        this.hubConnection.on(methodName, method);
      } else {
        this.hubConnection.off(methodName);
      }
    });
  }

  public undefineListener(methodName: string): void {
    this.hubConnection.off(methodName);
  }

  public async tryInvoke(
    methodName: keyof ICOHubBEMethods,
    ...args: any[]
  ): Promise<boolean> {
    if (!this.isConnected) return false;
    try {
      await this.hubConnection.invoke(methodName, args);
    } catch (error) {
      console.error(error);
      return false;
    }
    return true;
  }

  private startConnection(): void {
    if (!this.userId || !this.accessToken) {
      console.error(
        `Can't start SignalR connection when user is not authorized`,
      );
      return;
    }

    this.hubConnection = new signalr.HubConnectionBuilder()
      .withUrl(this.hubUrl, {
        accessTokenFactory: () => this.accessToken,
        withCredentials: false,
      })
      .build();
    this.hubConnection
      .start()
      .then(() => {
        this.connectedSub.next(true);
        console.log('SignalR connection started');
      })
      .catch((err) => console.error('Error while starting connection: ' + err));
  }

  private stopConnection(): void {
    if (!this.hubConnection) {
      return;
    }

    this.hubConnection
      .stop()
      .then(() => {
        this.connectedSub.next(false);
        console.log('SignalR connection stopped');
      })
      .catch((err) => console.error('Error while stopping connection: ' + err));
  }

  private addSetLogSeverityLevelListener = (): void => {
    this.hubConnection.on(
      this.nameof('setLogSeverityLevel'),
      (logSeverityLevel: LogSeverityLevel) => {
        this.loggingService.setLogSeverityLevel(logSeverityLevel);
        console.log(
          `Set '${LogSeverityLevel[logSeverityLevel]}' log severity level`,
        );
      },
    );
  };

  private addResetLogSeverityLevelListener = (): void => {
    this.hubConnection.on(this.nameof('resetLogSeverityLevel'), () => {
      this.loggingService.resetLogSeverityLevel();
      console.log('Reset log severity level to default');
    });
  };

  private defaultListeners: (() => void)[] = [
    this.addSetLogSeverityLevelListener,
    this.addResetLogSeverityLevelListener,
  ];
}
