export class SplExceptionModel extends Error {
  message: string;
  status: number;
  error: any;
  errorDescription: string;
  headers: { [key: string]: any };
  suppressUserAlert: boolean;

  constructor(
    message: string, // status text
    status: number, // status
    error: string, // error
    errorDescription: string, // name
    headers: { [key: string]: any },
    suppressUserAlert: boolean = false,
  ) {
    super();

    this.message = message;
    this.status = status;
    this.error = error;
    this.errorDescription = errorDescription;
    this.headers = headers;
    this.suppressUserAlert = suppressUserAlert;
  }

  protected isSplException = true;

  static isSplException(obj: SplExceptionModel): obj is SplExceptionModel {
    return obj.isSplException === true;
  }
}
