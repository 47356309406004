<mat-card>
  <mat-card-header>
    <mat-card-title>
      {{ (title | async) ? (title | async) : "Loading..." }}
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mat-progress-bar [mode]="(progress | async) ? 'determinate' : 'indeterminate'" [value]="progress | async"></mat-progress-bar>
  </mat-card-content>
</mat-card>
