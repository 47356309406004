import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ConfirmChangesDialogModel } from '@common/shared/components/confirm-changes-dialog/confirm-changes-dialog.model';

@Component({
  selector: 'spl-confirm-changes-dialog',
  templateUrl: './confirm-changes-dialog.component.html',
  styleUrls: ['./confirm-changes-dialog.component.scss'],
})
export class ConfirmChangesDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConfirmChangesDialogModel,
    public dialogRef: MatDialogRef<ConfirmChangesDialogComponent>,
  ) {}
}
