import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TenantService } from 'app/auth/tenant.service';
import { ContextWrapper } from 'app/core/models/context-level-wrapper';
import { ContextLevelType } from '@common/services/bo-api-client';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import * as _ from 'lodash';
import { TenantEntity } from 'app/auth/model/tenant-entity.model';
@Component({
  selector: 'select-available-tenant-modal',
  templateUrl: './select-available-tenant-modal.component.html',
  styleUrls: ['./select-available-tenant-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SelectAvailableTenantModalComponent implements OnInit {
  tenants: ContextWrapper[];
  selectedTenant: ContextWrapper;
  public data: ContextWrapper[];
  tenantCompareFn = (t1: TenantEntity, t2: TenantEntity): boolean => {
    return t1 && t2 && t1.TenantId === t2.TenantId;
  };

  constructor(
    private tenantService: TenantService,
    private dialogRef: MatDialogRef<SelectAvailableTenantModalComponent>,
  ) {}

  async ngOnInit(): Promise<void> {
    const response = await this.tenantService.getMappedContextData();
    this.tenants = _.filter(
      response.flat,
      (c) => c.type === ContextLevelType.Tenant,
    );
    if (this.tenants.length > 0) {
      this.selectedTenant = this.tenants[0];
    }
  }

  public async proceed(): Promise<void> {
    await this.setSelectedTenant(this.selectedTenant);
    this.dialogRef.close(true);
  }

  private async setSelectedTenant(tenant: ContextWrapper): Promise<void> {
    const c = tenant.getContextLevel();
    await this.tenantService.setContextLevelPending(c);
  }
}
