import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { StorageService } from '@common/services/storage.service';
import {
  ApplicationPaths,
  QueryParameterNames,
  storeKeys,
} from 'app/core/app.constants';

@Injectable({
  providedIn: 'root',
})
export class AuthorizeGuard  {
  constructor(private _storage: StorageService, private _router: Router) {}
  async canActivate(
    _next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> {
    const accessToken = await this._storage.get({
      key: storeKeys.STORAGE_ACCESS_TOKEN,
    });
    if (!accessToken?.value) return this.redirectToLogin(state);
    try {
      JSON.parse(atob(accessToken.value.split('.')[1]));
      return true;
    } catch (e) {
      return this.redirectToLogin(state);
    }
  }

  private redirectToLogin(state: RouterStateSnapshot): boolean {
    this._router.navigate(ApplicationPaths.LoginPathComponents, {
      queryParams: {
        [QueryParameterNames.ReturnUrl]: state.url,
      },
    });
    return false;
  }
}
