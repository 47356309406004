import { Injectable } from '@angular/core';
import { StorageService } from '@common/services/storage.service';

export class RouterStateModel {
  [k: string]: any;
}

@Injectable({ providedIn: 'root' })
export class RouterStateService {
  private readonly routerStateKey: string = 'router-state';

  constructor(private storageService: StorageService) {}

  async getRouterState(url: string): Promise<RouterStateModel> {
    const state: string = (
      await this.storageService.get({ key: this.routerStateKey })
    )?.value;
    if (!state) return undefined;
    const stateObject: RouterStateModel = JSON.parse(state);
    return stateObject[url] ? stateObject[url] : undefined;
  }

  async setRouterState(url: string, state: RouterStateModel): Promise<void> {
    const routerState: string = (
      await this.storageService.get({ key: this.routerStateKey })
    )?.value;
    const stateObject: RouterStateModel = !routerState
      ? {}
      : JSON.parse(routerState);
    stateObject[url] = state;
    this.storageService.set({
      key: this.routerStateKey,
      value: JSON.stringify(stateObject),
    });
  }
}
