<div class="dialog-wrapper">
    <code-entry 
        sentTo="phone"
        [isConfirmDisable]="false"
        [isSendAvailable]="state?.isSendAvailable"
        [lastSendAttemptAt]="state?.lastSendAttemptAt"
        [nextSendAvailableAt]="state?.nextSendAvailableAt"
        [process]="processing"
        [minCodeLength]="6"
        [commonError]="commonError"
        [validationError]="validationError"
        (codeValidChanged)="isCodeValid = $event"
        (codeChanged)="code = $event"
        (codeRequested)="requestNewCode()"
        (countdownFinished)="countdownFinished()"
    />

    <div class="buttons-wrapper">
        <button mat-button (click)="dialogRef.close()">Cancel</button>
        <button mat-button class="spl-button" [disabled]="!isCodeValid || processing" (click)="confirmPhoneNumber()">Confirm</button>
    </div>
</div>