import { Injectable } from '@angular/core';
import { parseISO, formatISO } from 'date-fns';
import * as _ from 'lodash';
import * as dateFns from 'date-fns';

@Injectable({ providedIn: 'root' })
export class DateTimeService {
  public convertFromDateTimeDTO(dto: string): Date {
    if (!dto) {
      return null;
    }
    const res = parseISO(dto);
    return res;
  }

  public convertFromUtcDateTimeDTO(dto: string): Date {
    if (!dto) {
      return null;
    }
    const l = _.last(dto);
    if (l !== 'Z') {
      dto = dto + 'Z';
    }
    const date = parseISO(dto);
    // const res = addMinutes(date, -date.getTimezoneOffset());
    return date;
  }

  public convertToDtoDateTimeString(date: Date): string {
    if (!date) {
      return null;
    }
    return formatISO(date);
  }

  public convertToDtoDateString(date: Date): string {
    if (!date) {
      return null;
    }
    return formatISO(date);
  }

  public getDateWithoutTimeZone(date: Date): Date {
    const timeZone = date.getTimezoneOffset();
    return dateFns.addMinutes(date, -timeZone);
  }

  public getDateWithAddedTimeZone(date: Date): Date {
    const timeZone = date.getTimezoneOffset();
    return dateFns.addMinutes(date, timeZone);
  }
}
