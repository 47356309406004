import { NgModule, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  BrowserModule,
  HAMMER_GESTURE_CONFIG,
  HammerModule,
} from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import {
  FuseProgressBarModule,
  FuseSidebarModule,
  FuseThemeOptionsModule,
} from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';
import {
  API_BASE_URL,
  OidcConfigurationClient,
} from '@common/services/bo-api-client';

import { AppComponent } from 'app/app.component';
import { AppStoreModule } from 'app/store/store.module';
import { Error401Module } from '@common/shared/components/401/error-401.module';
import { LayoutModule } from 'app/layout/layout.module';

import { AuthModule } from 'app/auth/auth.module';
import { appConfig } from './core/config/app.config';
import { MaterialModule } from './core/material.module';
import { SplCoreModule } from './core/core.module';
import { NgxMaskModule } from 'ngx-mask';
import { SplHammerConfig } from 'app/core/config/hammer.config';
import { AuthorizeService } from './auth/authorize.service';
import { AppBusService } from './core/services/app-bus.service';
import { AppRoutes } from './app.routes';
import { ErrorStateMatcher } from '@angular/material/core';
import { SplErrorStateMatcher } from '@common/helpers/spl-error-state-matcher';
import { OverlayLoaderModule } from '@common/shared/components/overlay-loader';
import { APP_BUS_SERVICE } from '@common/shared/services/iapp-bus-service';
import { ConfirmChangesModule } from '@common/shared/components/confirm-changes-dialog/confirm-changes.module';
import { BreadcrumbsService } from '@common/co/core/services/breadcrumbs.service';
import { APP_CONFIG } from '@common/co/core/config/app.config';
import { ERROR_401_CONFIG } from '@common/shared/components/401/error-401.interface';
import { error401Config } from 'app/core/config/error-401-page.config';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { ErrorScreensModule } from 'app/error-screens/error-screens.module';
import { SignalrService } from '@common/services/signalr.service';
import { AUTH_SERVICE } from '@common/services/iauth-service';
import { APP_INFO } from '@common/co/core/config/app.info';
import { AppInfo } from 'app/core/config/app.info';
import { OIDC_CONFIGURATION_CLIENT } from '@common/services/ioidc-configuration-client-service';
import { BoOidcConfigurationClientService } from '@common/services/bo-oidc-configuration-client.service';
import { ServiceIsUnavailableMessageModule } from 'app/features/service-is-unavailable-message/service-is-unavailable-message.module';
import { TECHNICAL_CLIENT } from '@common/shared/services/itechnical-client-service';
import { BoTechnicalClientService } from '@common/shared/services/bo-technical-client.service';

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutes,

    TranslateModule.forRoot(),
    // Fuse modules
    FuseModule.forRoot(fuseConfig),
    FuseProgressBarModule,
    FuseSharedModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,

    // App modules
    LayoutModule,
    AppStoreModule,
    OverlayLoaderModule,
    ServiceIsUnavailableMessageModule,
    // InTakeModule,
    AuthModule,
    SplCoreModule,
    MaterialModule,
    HammerModule,

    Error401Module,
    ErrorScreensModule,
    ConfirmChangesModule,
    NgxMaskModule.forRoot(),
  ],
  bootstrap: [AppComponent],
  providers: [
    { provide: API_BASE_URL, useFactory: getBaseUrl },
    OidcConfigurationClient,
    { provide: HAMMER_GESTURE_CONFIG, useClass: SplHammerConfig },
    { provide: ErrorStateMatcher, useClass: SplErrorStateMatcher },
    { provide: APP_BUS_SERVICE, useExisting: AppBusService },
    { provide: AUTH_SERVICE, useExisting: AuthorizeService },
    {
      provide: OIDC_CONFIGURATION_CLIENT,
      useExisting: BoOidcConfigurationClientService,
    },
    { provide: APP_INFO, useValue: AppInfo },
    { provide: APP_CONFIG, useValue: appConfig },
    {
      provide: TECHNICAL_CLIENT,
      useExisting: BoTechnicalClientService,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initFunction,
      deps: [AuthorizeService],
      multi: true,
    },
    { provide: ERROR_401_CONFIG, useValue: error401Config },
    BreadcrumbsService,
    {
      provide: APP_INITIALIZER,
      useFactory: initSplashScreenService,
      deps: [FuseSplashScreenService],
      multi: true,
    },
    SignalrService,
    {
      provide: APP_INITIALIZER,
      useFactory: initSignalrService,
      deps: [SignalrService],
      multi: true,
    },
  ],
})
export class AppModule {}

export function getBaseUrl(): string {
  return appConfig.apiUrl;
}

export function initFunction(authServices: AuthorizeService) {
  return (): Promise<boolean> => authServices.initialize();
}

export function initSplashScreenService(
  fuseSplashScreenService: FuseSplashScreenService,
) {
  return (): void => fuseSplashScreenService._init();
}

export function initSignalrService(signalrService: SignalrService) {
  return (): void => signalrService.initialize();
}
