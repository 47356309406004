import { Injectable } from '@angular/core';
import {
  ContextLevelDto,
  ContextLevelType,
} from '@common/services/bo-api-client';
import { ContextWrapper } from 'app/core/models/context-level-wrapper';
import * as _ from 'lodash';

@Injectable({ providedIn: 'root' })
export class ContextLevelService {
  mapContexts(
    flatData: ContextWrapper[],
    context: ContextLevelDto,
    parent?: ContextWrapper,
  ): ContextWrapper {
    const w: ContextWrapper = new ContextWrapper();
    flatData.push(w);
    w.parent = parent;
    w.item = context;
    w.tenantInd = context.tenantInd;
    w.allowSendWelcomeEmails = context.tenantConfig?.allowSendWelcomeEmails;
    w.useDefaultInStudioProgram =
      context.tenantConfig?.useDefaultInStudioProgram;
    w.tenantConfig = context.tenantConfig;
    if (parent && parent.item.type !== ContextLevelType.Root) {
      w.pathName = `${parent.item.name} - ${context.name}`;
    } else {
      w.pathName = context.name;
    }
    _.forEach(context.items, (c) => {
      const child = this.mapContexts(flatData, c, w);
      w.items.push(child);
    });
    w.recalc(undefined);
    return w;
  }
}
