import { Injectable } from '@angular/core';
import {
  TechnicalClient,
} from '@common/services/bo-api-client';
import { Observable } from 'rxjs';
import { ITechnicalClientService } from '@common/shared/services/itechnical-client-service';

@Injectable({
  providedIn: 'root',
})
export class BoTechnicalClientService implements ITechnicalClientService {
  constructor(private _technicalClient: TechnicalClient) {}

  public index(): Observable<string> {
    return this._technicalClient.index();
  }
}
