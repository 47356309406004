export enum StatisticsActivities {
  ACTION_APP_START = 'Application start',
  ACTION_TRAINING_START = 'Start training',
  ACTION_TRAINING_STOP = 'Stop training',
  ACTION_RESULTS_ENTER = 'Enter Results',
  ACTION_VIDEO_START = 'Start View Video',
  ACTION_PROGRAM_COMPLETED = 'Program Completed',
  PAGE_DASHBOARD = 'Dashboard',
  PAGE_TESTING = 'Testing',
  PAGE_TRAINING = 'Training',
  PAGE_EDIT_PROFILE = 'Edit profile',
  PAGE_PREFERENCIES = 'Preferences',
  PAGE_CHANGE_PASSWORD = 'Change password',
  PAGE_SWITCH_PROFILE = 'Switch profile',
  PAGE_SLEEP_TRACKER = 'Sleep Tracker',
  PAGE_ABOUT = 'About QFNA',
  PAGE_HYDRATION_TRACKER = 'Hydration Tracker',
  ALL_ACTIVITIES = 'All',
}
