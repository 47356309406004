import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';

import { TranslateModule } from '@ngx-translate/core';

import { SplNavigationComponent } from './navigation.component';
import { SplNavVerticalItemComponent } from './vertical/item/item.component';
import { SplNavVerticalCollapsableComponent } from './vertical/collapsable/collapsable.component';
import { SplNavVerticalGroupComponent } from './vertical/group/group.component';
import { AccessPermissionsModule } from 'app/auth/directives/access-permissions.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,

    MatIconModule,
    MatRippleModule,
    AccessPermissionsModule,

    TranslateModule.forChild(),
  ],
  exports: [SplNavigationComponent],
  declarations: [
    SplNavigationComponent,
    SplNavVerticalItemComponent,
    SplNavVerticalCollapsableComponent,
    SplNavVerticalGroupComponent,
  ],
})
export class NavigationModule {}
