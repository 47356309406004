import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, BehaviorSubject } from 'rxjs';
import {
  BatchCreateFormFieldAnswerCommand,
  BatchUpdateFormFieldAnswerCommand,
  CreateFormFieldAnswerCommand,
  FormFieldAnswerClient,
  FormFieldType,
  FormSectionClient,
  FormSectionDto,
  LockFormFieldAnswerCommand,
  UpdateFormFieldAnswerCommand,
  AthleteDto,
  GenderType,
  GenderTypeDescription,
} from '@common/services/co-api-client';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { InTakeFormSectionModel } from '@common/co/feature/in-take/models/in-take-form-section.model';
import { InTakeFormFieldModel } from '@common/co/feature/in-take/models/in-take-form-field.model';
import { InTakeFormFieldAnswerModel } from '@common/co/feature/in-take/models/in-take-form-field-answer.model';
import * as _ from 'underscore';
import { AuthorizeService } from '@common/co/auth/services/authorize.service';
import { AppBusService } from '@common/co/core/services/app-bus.service';
import moment from 'moment';
import {
  commonNavigationConfig,
  INavigationConfig,
  NAVIGATION_CONFIG,
} from '@common/co/navigation/navigation';
import { UserEntity } from '../model/user-entity.model';
import { EnumDescriptionSet } from '@common/helpers/enum-descriptions';
import { FusePerfectScrollbarService } from '@fuse/services/fuse-perfect-scrollbar.service';

@Injectable()
export class InTakeService {
  private readonly USAPhoneNumberCode: string = '+1';
  private genders = new EnumDescriptionSet(GenderType, GenderTypeDescription);
  private _sections: FormSectionDto[];
  private _userInfo: UserEntity;
  private _currentProfileId: string = undefined;
  private _isEdit: boolean = false;

  get userInfo(): UserEntity {
    return this._userInfo;
  }

  set isEdit(isEdit: boolean) {
    this._isEdit = isEdit;
  }

  get isEdit(): boolean {
    return this._isEdit;
  }

  constructor(
    @Inject(NAVIGATION_CONFIG) private navigationConfig: INavigationConfig,
    private _formSectionClient: FormSectionClient,
    private _formFieldAnswerClient: FormFieldAnswerClient,
    private _router: Router,
    private _authorizeService: AuthorizeService,
    private _appBusService: AppBusService,
    private _fusePerfectScrollbarService: FusePerfectScrollbarService,
  ) {
    this.initialize();
  }

  public isInTakePassed(): Observable<boolean> {
    return this._appBusService.inTakePassed$;
  }

  public navigateToIntake(): Promise<boolean> {
    return this._router.navigateByUrl(commonNavigationConfig.in_take.url, {
      replaceUrl: true,
    });
  }

  public sectionsVM: InTakeFormSectionModel[] = undefined;
  public dataLoaded$: BehaviorSubject<InTakeFormSectionModel[]> =
    new BehaviorSubject<InTakeFormSectionModel[]>(null);

  public initialize(): void {
    this._appBusService.logout$.subscribe(() => {
      this._currentProfileId = undefined;
    });
    this._authorizeService.user$.subscribe((u) => {
      if (u) {
        console.log(u);
        this._userInfo = u;
        if (
          !!u.currentProfileId &&
          this._currentProfileId != u.currentProfileId
        ) {
          if (
            _.isUndefined(u.isInTakePassed) ||
            _.isNull(u.isInTakePassed) ||
            !u.isInTakePassed
          ) {
            this._currentProfileId = u.currentProfileId;
            this.refreshInTake().then((inTakePassed) => {
              this.setInTakePassed(inTakePassed);
            });
          } else {
            this.setInTakePassed(u.isInTakePassed);
          }
        }
      }
    });
  }

  refreshInTake(): Promise<boolean> {
    if (this._userInfo.isInTakePassed === undefined) {
      this._sections = [];
      this.sectionsVM = [];
    }
    return new Promise((resolve, reject) => {
      this._formSectionClient.getInTake().subscribe((response: any) => {
        this._sections = response;

        this.sectionsVM = this.getSectionsVM();
        this.dataLoaded$.next(this.sectionsVM);

        resolve(this.sectionsVM.length === 0 || this.isEdit);
      }, reject);
    });
  }

  setInTakePassed(value: boolean): void {
    this._appBusService.inTakePassed(value);
  }

  private getSectionsVM(): InTakeFormSectionModel[] {
    const filtered = this._sections.filter((s) => {
      if (s.enabled === true) {
        if (
          _.any(
            this._authorizeService.currentProfile.inTakes,
            (x) => x.sectionId == s.id,
          )
        )
          return false;

        if (s.fields.filter((f) => f.answers.length === 0).length > 0) {
          // has no answers for any fields
          return true;
        } else {
          // has answers for all fields
          return false; //s.firstUseOnly !== true; // can be modified
        }
      } else {
        return false;
      }
    });
    const mapped = filtered.map((s) => new InTakeFormSectionModel(s));
    for (const sectionVM of mapped) {
      for (const fieldVM of sectionVM.fields) {
        if (!fieldVM.answer) {
          if (fieldVM.extraData?.firstDisplayClaim) {
            if (this._authorizeService.availableProfiles.length === 1) {
              const firstProfile = _.first(
                this._authorizeService.availableProfiles,
              );
              console.log(
                fieldVM.extraData.firstDisplayClaim,
                this._userInfo[fieldVM.extraData.firstDisplayClaim],
              );

              const firstDisplayAnswer: any = this.handleFirstDisplayFieldValue(
                fieldVM.extraData.firstDisplayClaim,
                firstProfile,
                this._userInfo,
              );

              if (firstDisplayAnswer) {
                fieldVM.firstDisplayAnswer = firstDisplayAnswer;
              }
            } else if (
              _.any(
                this._authorizeService.availableProfiles,
                (e) => e.id === this._currentProfileId,
              )
            ) {
              const findProfile = this._authorizeService.availableProfiles.find(
                (e) => e.id === this._currentProfileId,
              );

              const firstDisplayAnswer: any = this.handleFirstDisplayFieldValue(
                fieldVM.extraData.firstDisplayClaim,
                findProfile,
              );

              if (firstDisplayAnswer) {
                fieldVM.firstDisplayAnswer = firstDisplayAnswer;
              }
            }
          }
        } else {
          this.parseResponse(fieldVM.answer, fieldVM.answer.value);
        }
      }
      sectionVM.fields = _.sortBy(sectionVM.fields, (field) => field.order);
    }
    const sorted = _.sortBy(mapped, (section) => section.order);
    if (sorted.length > 0) {
      sorted[0].canBackToSignIn = true;
    }
    return sorted;
  }

  private handleFirstDisplayFieldValue(
    clainName: string,
    profile: AthleteDto,
    user?: UserEntity,
  ): any {
    if (profile[clainName] === '') profile[clainName] = undefined;
    const claimValue =
      profile[clainName] ?? (user ? user[clainName] : undefined);
    if (claimValue) {
      if (clainName.toLowerCase() == 'gender') {
        return this.genders.getDescription(claimValue);
      }
      if (clainName == 'mobilePhone' && _.isString(claimValue)) {
        return claimValue.replace(this.USAPhoneNumberCode, '');
      }
    }
    return claimValue;
  }

  private getEditSectionsVM(): InTakeFormSectionModel[] {
    const mapped = this._sections.map((s) => new InTakeFormSectionModel(s));
    for (const sectionVM of mapped) {
      for (const fieldVM of sectionVM.fields) {
        if (!fieldVM.answer) {
          if (this._authorizeService.availableProfiles.length === 1) {
            if (fieldVM.extraData?.firstDisplayClaim) {
              console.log(
                fieldVM.extraData.firstDisplayClaim,
                this._userInfo[fieldVM.extraData.firstDisplayClaim],
              );
              fieldVM.firstDisplayAnswer =
                this._userInfo[fieldVM.extraData.firstDisplayClaim];
            }
          }
        } else {
          this.parseResponse(fieldVM.answer, fieldVM.answer.value);
        }
      }
      sectionVM.fields = _.sortBy(sectionVM.fields, (field) => field.order);
    }
    let sorted = _.sortBy(mapped, (section) => section.order);
    if (sorted.length > 0) {
      sorted[0].canBackToSignIn = true;
    }
    sorted = sorted.map((s) => {
      s.active = false;
      s.canBackToSignIn = false;
      return s;
    });
    if (sorted.length > 0) {
      sorted[0].active = true;
      sorted[0].canPrevious = false;
    }
    return sorted;
  }

  public setEditSectionsVM(): void {
    this.sectionsVM = this.getEditSectionsVM();
  }

  prepareAnswer(
    fieldVM: InTakeFormFieldModel,
    value: string,
  ): CreateFormFieldAnswerCommand | UpdateFormFieldAnswerCommand {
    value = this.prepareRequest(fieldVM, value);
    for (const section of this._sections) {
      for (const field of section.fields) {
        if (field.id === fieldVM.id) {
          const fieldModel = new InTakeFormFieldModel(field);
          if (!fieldModel.answer) {
            return new CreateFormFieldAnswerCommand({
              formFieldId: field.id,
              value: value,
            });
          } else {
            return new UpdateFormFieldAnswerCommand({
              id: fieldModel.answer.id,
              value: value,
            });
          }
        }
      }
    }
  }

  async createAnswers(createAnswers: any[]): Promise<any> {
    try {
      const commands = createAnswers.map((el) => {
        return el.answer;
      });
      const body = new BatchCreateFormFieldAnswerCommand({ commands });
      const createResponse = await this._formFieldAnswerClient
        .batchCreate(body)
        .toPromise();
      createAnswers.forEach((answer) => {
        const changedField = createResponse.find((el) => {
          return el.fieldId === answer.answer.formFieldId;
        });
        if (changedField) {
          this.parseResponse(changedField, changedField.value);
          answer.fieldVM.answers.push(
            new InTakeFormFieldAnswerModel(changedField),
          );
        }
        answer.fieldVM.completed = true;
        for (const section of this._sections) {
          for (const field of section.fields) {
            if (field.id === answer.fieldVM.id) {
              field.answers.push(changedField);
            }
          }
        }
      });
    } finally {
      //TODO: Add some code or remove this section
    }
    return Promise.resolve();
  }

  async updateAnswers(updateAnswers: any[]): Promise<any> {
    try {
      const commands = updateAnswers.map((el) => {
        return el.answer;
      });
      const body = new BatchUpdateFormFieldAnswerCommand({ commands });
      const updateResponse = await this._formFieldAnswerClient
        .batchUpdate(body)
        .toPromise();
      updateAnswers.forEach((answer) => {
        const changedField = updateResponse.find((el) => {
          return el.id === answer.answer.id;
        });
        if (changedField) {
          for (const answerVM of answer.fieldVM.answers) {
            if (answerVM.id === changedField.id) {
              const responseVM = new InTakeFormFieldAnswerModel(changedField);
              answerVM.type = responseVM.type;
              answerVM.value = responseVM.value;
              answerVM.lastModified = responseVM.lastModified;
              answerVM.locked = responseVM.locked;
              this.parseResponse(answerVM, responseVM.value);
            }
          }
        }
        answer.fieldVM.completed = true;
      });
    } finally {
      //TODO: Add some code or remove this section
    }
    return Promise.resolve();
  }

  private parseResponse(fieldVM, value): void {
    if (fieldVM.type === FormFieldType.Date) {
      fieldVM.value = moment(value);
    }
  }

  private prepareRequest(fieldVM: InTakeFormFieldModel, value: string): string {
    if (fieldVM.type === FormFieldType.Date) {
      return moment(value).format('MM/DD/yyyy');
    } else {
      return value;
    }
  }

  async lockFirstUseOnly(): Promise<any> {
    console.log('lock FirstUseOnly');
    for (const sectionVM of this.sectionsVM) {
      for (const fieldVM of sectionVM.fields) {
        if (sectionVM.firstUseOnly) fieldVM.completed = false;
      }
    }
    try {
      const lockResponse = await this._formFieldAnswerClient
        .lock(new LockFormFieldAnswerCommand({ firstUseOnly: true }))
        .toPromise();
      if (lockResponse?.length > 0) {
        const map = {};
        for (const dto of lockResponse) {
          map[dto.id] = dto;
        }
        for (const sectionVM of this.sectionsVM) {
          for (const fieldVM of sectionVM.fields) {
            for (const answerVM of fieldVM.answers) {
              if (map[answerVM.id]) {
                const responseVM = new InTakeFormFieldAnswerModel(
                  map[answerVM.id],
                );
                answerVM.type = responseVM.type;
                answerVM.value = responseVM.value;
                answerVM.lastModified = responseVM.lastModified;
                answerVM.locked = responseVM.locked;
              }
            }
          }
        }
      }
      for (const sectionVM of this.sectionsVM) {
        for (const fieldVM of sectionVM.fields) {
          if (sectionVM.firstUseOnly) fieldVM.completed = true;
        }
      }
    } finally {
      //TODO: Add some code or remove this section
    }
    return Promise.resolve();
  }

  async next(sectionVM: InTakeFormSectionModel): Promise<void> {
    const isLastSection =
      this.sectionsVM[this.sectionsVM.length - 1].id === sectionVM.id;
    if (isLastSection) {
      await this.lockFirstUseOnly();
      this.updateActiveState(sectionVM);

      if (!this.isEdit) {
        await this._router.navigateByUrl('in-take-complete', {
          replaceUrl: true,
        });
      } else {
        await this._router.navigateByUrl(commonNavigationConfig.dashboard.url, {
          replaceUrl: true,
        });
      }
    } else {
      this.updateActiveState(sectionVM);
      this._fusePerfectScrollbarService.scrollToTop$.next();
    }
  }

  private updateActiveState(sectionVM: InTakeFormSectionModel): void {
    let active = false;
    for (let i = 0; i < this.sectionsVM.length; ++i) {
      this.sectionsVM[i].active = active;
      active = this.sectionsVM[i].id === sectionVM.id;
      this.sectionsVM[i].canPrevious = i > 0;
    }
  }

  previous(sectionVM: InTakeFormSectionModel): void {
    let active = false;
    for (let i = this.sectionsVM.length - 1; i >= 0; --i) {
      this.sectionsVM[i].active = active;
      active = this.sectionsVM[i].id === sectionVM.id;
      this.sectionsVM[i].canPrevious = i > 0;
    }
  }
}

export function regexValidator(
  fieldName: string,
  pattern: string,
  regexValidationError?: string,
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const r = new RegExp(pattern);
    const m = r.test(String(control.value));
    return !m
      ? { regex: { value: regexValidationError || control.value } }
      : null;
  };
}
