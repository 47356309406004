interface ITenantEntity {
  TenantId: string;
  TenantName: string;
}

export class TenantEntity {
  TenantId: string;
  TenantName: string;

  /**
   * Constructor
   *
   * @param data
   */
  constructor(data?: ITenantEntity) {
    if (!data || !data.TenantId || !data.TenantName) {
      throw new Error(
        'Tenant properties{TenantId,TenantName} can not be empty.',
      );
    }

    this.TenantId = data.TenantId;
    this.TenantName = data.TenantName;
  }
}

export enum ContextLevelChangeMode {
  Auto,
  Manual
}

