<div class="two-factor-code-wrapper">
    <code-entry 
        [sentTo]="state?.userTwoFactorAuthFlow === UserTwoFactorAuthFlow.ByEmail ? 'email' : 'phone'"
        [isSendAvailable]="state?.isSendAvailable" 
        [isConfirmDisable]="!state?.isLoginAvailable" 
        [lastSendAttemptAt]="state?.lastSendAttemptAt"
        [nextSendAvailableAt]="state?.nextSendAvailableAt" 
        [validationError]="validationError" 
        [commonError]="commonError"
        [process]="processLogin" 
        (codeChanged)="code = $event"
        (codeValidChanged)="isCodeValid = $event" 
        (countdownFinished)="countdownFinished()"
        (codeRequested)="requestNewCode()"
    />

    <div class="button-wrapper">
        <button mat-button (click)="login()" class="submit-button pulse-animation" aria-label="Sign In"
            [disabled]="!isCodeValid || processLogin || !state?.isLoginAvailable"
            [ngClass]="{'button-in-progress': processLogin}">
            {{ processLogin ? 'Signing In...' : 'Sign In' }}
        </button>
        <span class="error-text mt-8" *ngIf="state?.isLoginAvailable === false">
            Number of sign-in attempts exceeded. Please request a new code.
        </span>
        <div class="back-action mt-16" (click)="goBack()" *ngIf="!processLogin">
            <mat-icon>keyboard_arrow_left</mat-icon> Back to login
        </div>
    </div>
</div>