import {
  FormFieldAnswerDto,
  FormFieldType,
} from '@common/services/co-api-client';
import { Moment } from 'moment';

export class InTakeFormFieldAnswerModel {
  id: string;
  value: string | undefined | Moment;
  type: FormFieldType;
  lastModified: Date;
  locked: boolean;

  constructor(data?: FormFieldAnswerDto) {
    this.id = data.id || '';
    this.type = data.type || FormFieldType.None;

    switch (this.type) {
      case FormFieldType.None:
      case FormFieldType.Text:
      case FormFieldType.Date:
      case FormFieldType.DropDown:
        this.value = data.value;
        break;
      case FormFieldType.Checkbox:
        this.value = data.value?.toLowerCase() == 'true' ? 'true' : undefined;
        break;
    }
    this.lastModified = data.lastModified || new Date();
    this.locked = data.locked || false;
  }
}
