import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AuthClient,
  ProcessResultType,
  StateTwoFactorAuthResponse,
  TwoFactorConstants,
  UserTwoFactorAuthFlow,
} from '@common/services/bo-api-client';
import { fuseAnimations } from '@fuse/animations';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { AuthorizeService } from 'app/auth/authorize.service';
import { ApplicationPaths, ReturnUrlType } from 'app/core/app.constants';
import { getSplNotification } from 'app/core/helpers/spl-notification';
import { AppBusService } from 'app/core/services/app-bus.service';
import { NotificationService } from 'app/core/services/notification.service';
import { NavigationTree } from 'app/navigation/navigation';
import { FuseConfigService } from '@fuse/services/config.service';

interface INavigationState {
  [ReturnUrlType]: string;
}

@Component({
  selector: 'two-factor-code',
  templateUrl: 'two-factor-code.component.html',
  styleUrls: ['two-factor-code.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class TwoFactorCodeComponent implements OnInit {
  private idToken: string;
  private twoFactorAuthTokenWasSent: boolean;

  public code: string;
  public isCodeValid: boolean;
  public commonError: string;
  public validationError: string;

  public processLogin: boolean = false;
  public state: StateTwoFactorAuthResponse;
  public readonly UserTwoFactorAuthFlow = UserTwoFactorAuthFlow;

  constructor(
    private fuseProgressBar: FuseProgressBarService,
    private authService: AuthorizeService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private appBusService: AppBusService,
    private authClient: AuthClient,
    private _notificationService: NotificationService,
    private _fuseConfigService: FuseConfigService,
  ) {
    this._fuseConfigService.hideFuseLayout();
  }

  async ngOnInit(): Promise<void> {
    this.idToken = await this.authService.getTwoFactorIdToken();
    await this.getState(this.idToken, true);
  }

  private async getState(
    idToken: string,
    sendTwoFactorAuthToken: boolean = false,
  ): Promise<void> {
    if (!idToken) return;

    this.state = await this.authClient
      .getStateTwoFactorAuth(idToken)
      .toPromise();

    this.twoFactorAuthTokenWasSent =
      await this.authService.twoFactorAuthTokenWasSent();

    if (
      this.state.isSendAvailable &&
      !this.twoFactorAuthTokenWasSent &&
      sendTwoFactorAuthToken
    ) {
      this.sendTwoFactorAuthToken(this.idToken);
      return;
    }
  }

  public countdownFinished(): void {
    this.getState(this.idToken);
  }

  public requestNewCode(): void {
    this.sendTwoFactorAuthToken(this.idToken);
  }

  private sendTwoFactorAuthToken(idToken: string): void {
    if (!idToken) return;
    this.commonError = undefined;
    this.authClient.sendTwoFactorAuthToken(idToken).subscribe(async (res) => {
      if (res.result?.success) {
        await this.authService.setTwoFactorAuthTokenAsSent(true);
        this.getState(idToken);
      } else {
        if (res.result.type === ProcessResultType.ValidationError) {
          this.commonError = res.result.message;
        } else {
          this._notificationService.generateNotification(
            getSplNotification('error', res.result.message),
          );
        }
      }
    });
  }

  public async login(): Promise<void> {
    const returnUrl = this.getReturnUrl();
    this.fuseProgressBar.show();
    this.validationError = undefined;
    this.processLogin = true;
    try {
      await this.authService.twoFactorAuthenticateUser(this.code, this.idToken);
      console.debug('successfully logged in');
      this.appBusService.login();
      await this.navigateToReturnUrl(returnUrl);
    } catch (e) {
      const twoFactorConstants = new TwoFactorConstants();
      this.getState(this.idToken);
      if (e.error === twoFactorConstants.tokenValidationError) {
        this.validationError = 'Entered code is invalid';
        return;
      }
      throw e;
    } finally {
      this.processLogin = false;
      this.fuseProgressBar.hide();
    }
  }

  private getReturnUrl(state?: INavigationState): string {
    const fromQuery = (
      this.activatedRoute.snapshot.queryParams as INavigationState
    ).returnUrl;
    // If the url is comming from the query string, check that is either
    // a relative url or an absolute url
    if (
      fromQuery &&
      !(
        fromQuery.startsWith(`${window.location.origin}/`) ||
        // eslint-disable-next-line no-useless-escape
        /\/[^\/].*/.test(fromQuery)
      )
    ) {
      // This is an extra check to prevent open redirects.
      throw new Error(
        'Invalid return url. The return url needs to have the same origin as the current page.',
      );
    }
    return (
      (state && state.returnUrl) ||
      fromQuery ||
      ApplicationPaths.DefaultLoginRedirectPath
    );
  }

  private async navigateToReturnUrl(returnUrl: string): Promise<boolean> {
    // It's important that we do a replace here so that we remove the callback uri with the
    // fragment containing the tokens from the browser history.
    return await this.router.navigateByUrl(returnUrl, {
      replaceUrl: true,
    });
  }

  public goBack(): void {
    this.router.navigateByUrl(NavigationTree.login.url);
  }
}
