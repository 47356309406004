import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FuseConfigService } from '@fuse/services/config.service';
import { AuthorizeService } from 'app/auth/authorize.service';
import {
  ContextLevelType,
  TechnicalClient,
} from '@common/services/bo-api-client';
import { TenantService } from 'app/auth/tenant.service';
import { Router } from '@angular/router';
import { AppBusService } from 'app/core/services/app-bus.service';

@Component({
  selector: 'ip-restriction-error-page',
  templateUrl: './ip-restriction-error-page.component.html',
  styleUrls: ['./ip-restriction-error-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class IpRestrictionErrorPageComponent implements OnInit {
  public displayHomepageButton: boolean;
  ipAddress: string;

  constructor(
    private _fuseConfigService: FuseConfigService,
    private _authorize: AuthorizeService,
    private _tenantService: TenantService,
    private _router: Router,
    private _appBusService: AppBusService,
    private _technicalClient: TechnicalClient,
  ) {
    this._fuseConfigService.hideFuseLayout();
  }

  ngOnInit(): void {
    this.getIp();
    this.processScreenConfig();
  }

  private async getIp(): Promise<void> {
    const res = await this._technicalClient.clientInfo().toPromise();
    if (!res.ipAddress) return;
    this.ipAddress = res.ipAddress;
  }

  private async processScreenConfig(): Promise<void> {
    const tree = (await this._tenantService.getMappedContextData(true))?.tree;
    this.displayHomepageButton =
      tree?.length > 0 &&
      ((tree[0].type === ContextLevelType.Root && tree[0].items?.length > 0) ||
        tree[0].type !== ContextLevelType.Root);

    if (this.displayHomepageButton) {
      this._tenantService.setContextLevel(tree[0].getContextLevel());
    }
  }

  public logout(): void {
    this._authorize.logout();
  }

  public goToHomepage(): void {
    this._router.navigateByUrl('');
  }
}
