import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const OIDC_CONFIGURATION_CLIENT =
  new InjectionToken<IoidcConfigurationClientService>(
    'OIDC_CONFIGURATION_CLIENT',
  );

export interface IoidcConfigurationClientService {
  getOidcConfiguration(
    oidcAppId: string,
  ): Observable<{ [key: string]: string }>;
}
