import { PermissionToken } from '@common/shared/models/permission-token';

export enum PermissionsInfoMode {
  All,
  Any
}

export class PermissionsInfo {
  public accessPermissions: PermissionToken[];
  public mode: PermissionsInfoMode;
  constructor(accessPermissions: PermissionToken[], mode?: PermissionsInfoMode) {
    this.accessPermissions = accessPermissions;
    this.mode = mode || PermissionsInfoMode.All;
  }
}