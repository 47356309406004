import {
  Directive,
  Input,
  ElementRef,
  HostListener,
  Output,
  EventEmitter,
} from '@angular/core';

@Directive({
  selector: '[minMax]',
})
export class MinMaxDirective {
  @Input()
  public min: number;

  @Input()
  public max: number;

  @Output() ngModelChange: EventEmitter<any> = new EventEmitter();

  constructor(private ref: ElementRef) {}

  @HostListener('input', ['$event'])
  public onInput(): void {
    const val = parseInt(this.ref.nativeElement.value);
    if (this.max !== null && this.max !== undefined && val >= this.max) {
      this.ref.nativeElement.value = this.max.toString();
      this.ngModelChange.emit(this.max.toString());
    } else if (this.min !== null && this.min !== undefined && val <= this.min) {
      this.ref.nativeElement.value = this.min.toString();
      this.ngModelChange.emit(this.min.toString());
    }
  }
}
