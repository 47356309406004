import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { PermissionsGuardBase } from '@common/shared/services/permission.guard';
import { PermissionsService } from '@common/co/auth/services/permissions.service';
import { commonNavigationConfig } from '@common/co/navigation/navigation';

@Injectable({
  providedIn: 'root',
})
export class PermissionsGuard extends PermissionsGuardBase  {
  constructor(private permissionsService: PermissionsService, router: Router) {
    super(router)
  }

  protected async handlePermission(_next: ActivatedRouteSnapshot): Promise<boolean> {
    const tokens = _next.data.tokens;
    const res = await this.permissionsService.checkUserPermissions(tokens);
    if (!res) {
      await this.router.navigateByUrl(commonNavigationConfig.notAuthorized.url, {
        replaceUrl: true,
      });
    }
    return res;
  }
}
