import { PermissionsInfo } from '../../../../common/src/shared/models/permission-info';
import _ from 'lodash';
import { IPayloadItem } from '../../../../../apps/web/app-bo/src/app/navigation/navigation';

export interface FuseNavigationItem {
  id: string;
  title: string;
  type: 'item' | 'group' | 'collapsable';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  externalUrl?: boolean;
  openInNewTab?: boolean;
  function?: any;
  badge?: {
    title?: string;
    translate?: string;
    bg?: string;
    fg?: string;
  };
  children?: FuseNavigationItem[];
  isComing?: boolean;
  isActionable?: boolean;
  isShowInfo?: boolean;
  groupType?: string;
  permissions?: PermissionsInfo;
  payload?: IPayloadItem;
}

export function isAnyChildMenuItem(item: FuseNavigationItem): boolean {
  return _.some(item.children, (item: FuseNavigationItem) => !item.hidden);
}

export interface FuseNavigation extends FuseNavigationItem {
  children?: FuseNavigationItem[];
}
