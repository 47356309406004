import { Injector } from '@angular/core';
import * as _ from 'lodash';
import { ActivatedRoute, Router, RouterStateSnapshot } from '@angular/router';
import { PermissionToken } from '../models/permission-token';
import { PermissionContext } from '../models/permission-context';
import { PermissionsGuardBase } from './permission.guard';
import {
  PermissionsInfo,
  PermissionsInfoMode,
} from '@common/shared/models/permission-info';
import { PermissionTokenDto } from '@common/services/co-api-client';
import { IAppBusService } from '@common/shared/services/iapp-bus-service';

export abstract class PermissionsServiceBase {
  protected tenantId: string;
  protected userId: string;
  protected contexts: PermissionContext[];
  protected permissionsPromise: Promise<PermissionToken[]>;
  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected injector: Injector,
    protected appBusService: IAppBusService,
  ) {}

  public clearPermissionsCache(): void {
    this.permissionsPromise = null;
    this.tenantId = undefined;
    this.userId = undefined;
    this.contexts = undefined;
  }
  public async reloadPermissionsForCurrentContext(): Promise<
    PermissionToken[]
  > {
    return await this.reloadPermissions(
      this.tenantId,
      this.userId,
      this.contexts,
    );
  }
  public async reloadPermissions(
    tenantId?: string,
    userId?: string,
    contexts?: PermissionContext[],
  ): Promise<PermissionToken[]> {
    this.permissionsPromise = undefined;
    const res = await this.getUserPermissions(tenantId, userId, contexts);
    this.forceRunGuard();

    return res;
  }

  public async getUserPermissionsWithContexts(
    tenantId?: string,
    userId?: string,
    contexts?: PermissionContext[],
  ): Promise<PermissionTokenDto[]> {
    if (tenantId) {
      this.tenantId = tenantId;
    }
    if (userId) {
      this.userId = userId;
    }
    if (contexts) {
      this.contexts = contexts;
    }
    return this.getTokensWithContexts(tenantId, userId, contexts);
  }

  public async getUserPermissions(
    tenantId?: string,
    userId?: string,
    contexts?: PermissionContext[],
  ): Promise<PermissionToken[]> {
    if (tenantId) {
      this.tenantId = tenantId;
    }
    if (userId) {
      this.userId = userId;
    }
    if (contexts) {
      this.contexts = contexts;
    }
    if (!this.permissionsPromise) {
      this.permissionsPromise = this.getTokens(tenantId, userId, contexts);
    }
    try {
      return await this.permissionsPromise;
    } catch (e) {
      this.permissionsPromise = null;
      throw e;
    }
  }

  public async checkUserPermissions(
    tokens: PermissionToken[],
    tenantId?: string,
    userId?: string,
    contexts?: PermissionContext[],
  ): Promise<boolean> {
    const permissions = await this.getUserPermissions(
      tenantId,
      userId,
      contexts,
    );
    let res = true;
    _.forEach(tokens, (t) => {
      const perm = permissions ? permissions.find((p) => p === t) : undefined;
      if (!perm) {
        res = false;
        return false;
      }
    });
    this.appBusService.checkedPermissions(!!res);
    return res;
  }

  protected abstract getTokens(
    tenantId: string,
    userId: string,
    contexts: PermissionContext[],
  ): Promise<PermissionToken[]>;

  protected abstract getTokensWithContexts(
    tenantId: string,
    userId: string,
    contexts: PermissionContext[],
  ): Promise<PermissionTokenDto[]>;

  private forceRunGuard(): void {
    this.checkRoute(this.route.root);
  }
  private checkRoute(route: ActivatedRoute): void {
    if (route.children.length) {
      // gets current route
      const curr_route = route.children['0'];
      // gets first guard class
      const PermissionsGuard = _.find(
        curr_route.snapshot.routeConfig.canActivate,
        (g) => {
          const res =
            g.prototype && g.prototype instanceof PermissionsGuardBase;
          return res;
        },
      );
      if (!PermissionsGuard) {
        return;
      }
      // injects guard
      const permissionsGuard = this.injector.get(PermissionsGuard);
      // makes custom RouterStateSnapshot object
      const routerStateSnapshot: RouterStateSnapshot = Object.assign(
        {},
        curr_route.snapshot,
        { url: this.router.url },
      );
      // runs canActivate
      permissionsGuard.canActivate(curr_route.snapshot, routerStateSnapshot);
      this.checkRoute(curr_route);
    }
  }

  public async getPermissionAccess(
    permissionsInfo: PermissionsInfo,
  ): Promise<boolean> {
    if (this.permissionsPromise) {
      const permissions = await this.permissionsPromise;
      if (!permissions) return Promise.resolve(false);
      switch (permissionsInfo.mode) {
        case PermissionsInfoMode.All:
          return (
            permissionsInfo.accessPermissions.filter((permission) => {
              return permissions.indexOf(permission) !== -1;
            }).length === permissionsInfo.accessPermissions.length
          );
        default:
          return (
            permissionsInfo.accessPermissions.filter((permission) => {
              return permissions.indexOf(permission) !== -1;
            }).length > 0
          );
      }
    }
    return Promise.resolve(false);
  }
}
