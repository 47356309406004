import { ContextLevel } from '@common/shared/models/permission-context';

interface IPreviouslyContextLevel {
  contextLevel: ContextLevel;
  url: string;
}

export class PreviouslyContextLevelModel {
  contextLevel: ContextLevel;
  url: string;

  constructor(data?: IPreviouslyContextLevel) {
    if (data) {
      this.contextLevel = data.contextLevel;
      this.url = data.url;
    }
  }
}