import { NgModule } from '@angular/core';
import { HideElementByPermissionsDirective } from './hide-element-by-permissions.directive';
import { HideKendoColumnByPermissionsDirective } from './hide-kendo-column-by-permissions.directive';
import { ReadonlyChildrenElementsByPermissionsDirective } from './readonly-children-elements-by-permissions.directive';

@NgModule({
  declarations: [
    HideElementByPermissionsDirective,
    HideKendoColumnByPermissionsDirective,
    ReadonlyChildrenElementsByPermissionsDirective,
  ],
  exports: [
    HideElementByPermissionsDirective,
    HideKendoColumnByPermissionsDirective,
    ReadonlyChildrenElementsByPermissionsDirective,
  ],
})
export class AccessPermissionsModule {}
