import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router,
} from '@angular/router';
import { filter } from 'rxjs/operators';
import * as _ from 'lodash';
import { AppBusService } from 'app/core/services/app-bus.service';
import { SplNavigationService } from 'app/core/components/navigation/navigation.service';
import {
  RouterStateModel,
  RouterStateService,
} from '@common/shared/services/router-state.service';
import { ComponentCacheService } from 'app/core/services/component-cache.service';

@Injectable()
export class RouteInterceptorService {
  private hideNotificationDelay: number = 5000;
  private iterationLimit: number = 20;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private _appBusService: AppBusService,
    private splNavigationService: SplNavigationService,
    private routerStateService: RouterStateService,
    private componentCacheService: ComponentCacheService,
  ) {}

  public initialize(): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        const currentRoute = this.getCurrentRoute();
        this.splNavigationService.setURLs(event, currentRoute);
        this.updateRouteInfo();

        const navigation = this.router.getCurrentNavigation();
        if (navigation && navigation.extras?.state) {
          const state: RouterStateModel = navigation.extras.state;
          this.routerStateService.setRouterState(
            this.splNavigationService.urlForState,
            state,
          );
        }

        this._appBusService.hideNotification(this.hideNotificationDelay);
      });

    this.router.events
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe((event: NavigationStart) => {
        if (
          event.navigationTrigger === 'popstate' &&
          this.componentCacheService.previouslyContextLevels.length > 0
        ) {
          this.componentCacheService.revertContextLevel();
        }
      });
  }

  private updateRouteInfo(): void {
    let currentRoute: ActivatedRoute = this.activatedRoute;
    while (currentRoute.firstChild) currentRoute = currentRoute.firstChild;
    if (_.get(currentRoute, 'outlet') === 'primary') {
      const data: any = _.get(currentRoute, 'snapshot.data') || null;
      this._appBusService.changeRouteInfo(data);
    }
  }

  private getCurrentRoute(): ActivatedRoute {
    let currentRoute: ActivatedRoute = this.activatedRoute;
    let whileCount = 0;
    while (currentRoute.firstChild) {
      if (whileCount < this.iterationLimit) {
        currentRoute = currentRoute.firstChild;
        whileCount++;
      } else {
        console.error('While getCurrentRoute exceeded the iteration limit');
        break;
      }
    }
    return currentRoute;
  }
}
