<div id="unavailable-wrapper" fxLayout="column" fxLayoutAlign="center center">
  <div class="content" fxLayout="column" fxLayoutAlign="center center">

    <div class="message">
      Service is unavailable at the moment. Please try again later.
    </div>

    <a class="back-link mt-16" (click)="action()">Try Again</a>

    <button mat-button class="spl-button mt-28" *ngIf="displayGoToLogin" (click)="logout()">Go to Login</button>
  </div>
</div>
