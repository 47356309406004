import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { MaterialModule } from '@common/co/core/material.module';
import { DirectivesModule } from '@common/shared/directives/directives.module';
import { ServiceIsUnavailableMessageComponent } from 'app/features/service-is-unavailable-message/service-is-unavailable-message.component';

@NgModule({
  declarations: [ServiceIsUnavailableMessageComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    InputsModule,
    ButtonsModule,
    MaterialModule,
    DirectivesModule,
  ],
  exports: [ServiceIsUnavailableMessageComponent],
})
export class ServiceIsUnavailableMessageModule {}
