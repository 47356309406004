import { Component, Inject, ViewEncapsulation } from '@angular/core';
import {
  MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA,
} from '@angular/material/legacy-snack-bar';
import { SplNotification } from 'app/core/models/splNotification.model';
import { NotificationService } from 'app/core/services/notification.service';

@Component({
  selector: 'notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NotificationComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: SplNotification,
    private notificationService: NotificationService,
  ) {}

  close(): void {
    this.notificationService.dismiss();
  }
}
