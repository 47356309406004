import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { QueryParameterNames } from '@common/co/core/app.constants';
import { InTakeService } from '@common/co/auth/services/in-take.service';
import { filter } from 'rxjs/operators';
import * as _ from 'lodash';
import {
  commonNavigationConfig,
  INavigationConfig,
  NAVIGATION_CONFIG,
} from '@common/co/navigation/navigation';

@Injectable({
  providedIn: 'root',
})
export class InTakeGuard {
  constructor(
    @Inject(NAVIGATION_CONFIG) private navigationConfig: INavigationConfig,
    private inTakeService: InTakeService,
    private router: Router,
  ) {}
  canActivate(
    _next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean | UrlTree> | boolean {
    this.isIntakePassed$ = this.inTakeService.isInTakePassed();
    return this.handleInTakePassed(this.isIntakePassed$, state);
  }

  private isIntakePassed$: Observable<boolean>;

  private async handleInTakePassed(
    isIntakePassed: Observable<boolean>,
    state: RouterStateSnapshot,
  ): Promise<boolean | UrlTree> {
    return new Promise((resolve) => {
      isIntakePassed
        .pipe(filter((v) => !_.isNull(v) && !_.isUndefined(v)))
        .subscribe((value) => {
          if (value) {
            resolve(true);
          } else {
            resolve(
              this.router.navigate([commonNavigationConfig.in_take.url], {
                queryParams: {
                  [QueryParameterNames.ReturnUrl]: state.url,
                },
              }),
            );
          }
        });
    });
  }
}
