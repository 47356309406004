import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject, ReplaySubject, Subscription } from 'rxjs';
import { IPayloadItem } from 'app/navigation/navigation';
import { ContextLevel } from '@common/shared/models/permission-context';
import { ILoginInfo } from '@common/shared/models/login-info';
import { IAppBusService } from '@common/shared/services/iapp-bus-service';

import { PermissionToken } from '@common/shared/models/permission-token';
import { FuseNavigationItem } from '@fuse/types';
import { SplNotification } from 'app/core/models/splNotification.model';
import { AppSettingsResponse } from '@common/services/bo-api-client';

@Injectable({ providedIn: 'root' })
export class AppBusService implements IAppBusService {
  public showNotificationSubject = new Subject();
  public hideNotificationSubject: Subject<number> = new Subject();
  public loginData$ = new ReplaySubject<ILoginInfo>(1);
  public logout$: Subject<void> = new Subject();
  public login$ = new Subject();
  public menuClick$ = new Subject<FuseNavigationItem>();
  private routeInfoChanged$ = new ReplaySubject(1);
  public inTakePassed$ = new BehaviorSubject<boolean>(null);
  public contextLevelChanged$ = new ReplaySubject<ContextLevel>(1);
  public reloadContextTree$ = new ReplaySubject<void>(1);
  public changePermissions$ = new ReplaySubject<PermissionToken[]>(1);
  public checkedPermissions$: Subject<boolean> = new Subject<boolean>();
  public appSettingsChanged$ = new ReplaySubject<AppSettingsResponse | null>(1);

  public preventContextChange: boolean = false;

  public loginData(info: ILoginInfo): void {
    this.loginData$.next(info);
  }
  public logout(): void {
    this.logout$.next();
  }
  public menuClick(item: FuseNavigationItem): void {
    this.menuClick$.next(item);
  }

  public login(): void {
    this.login$.next();
  }
  public inTakePassed(value: boolean): void {
    this.inTakePassed$.next(value);
  }

  public processNotification(notification: SplNotification): void {
    this.showNotificationSubject.next(notification);
  }

  public hideNotification(delay: number): void {
    this.hideNotificationSubject.next(delay);
  }

  public changeRouteInfo(data: IPayloadItem): void {
    this.routeInfoChanged$.next(data);
  }

  public subscribeToRouteInfo(
    callback: (data: IPayloadItem) => void,
  ): Subscription {
    return this.routeInfoChanged$.subscribe(callback);
  }

  public changeContextLevel(data: ContextLevel): void {
    console.log('changeContextLevel', this.preventContextChange);
    if (this.preventContextChange) return;
    this.contextLevelChanged$.next(data);
  }

  public changePermissions(data: PermissionToken[]): void {
    this.changePermissions$.next(data);
  }

  public checkedPermissions(value: boolean): void {
    this.checkedPermissions$.next(value);
  }

  public reloadContextTree(): void {
    this.reloadContextTree$.next();
  }

  public setAppSettings(value: AppSettingsResponse): void {
    this.appSettingsChanged$.next(value);
  }
}
